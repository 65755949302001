import React, {useEffect, useState} from "react";
import { Col, Container, Image, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/FooterLogo.png";
import playStore from "../../assets/images/play_store.png";
import appStore from "../../assets/images/app_store.png";
import dotIcon from "../../assets/images/dot_icon.png";

import Constant, { userInfo } from "../../config/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLocation, faMessage, faMobile } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../Helper/ApiHelper";
import LangTrans from "../Helper/LangHelper";
import moment from "moment";

import { useDispatch } from "react-redux";
import { userLoginModel } from "../../features/userSlice";

const Footer = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [footerData, setFooterData] = useState({})

    const getInformativeData = () => {
        apiCall({method:'get', url:Constant?.apis?.settingsFetch}).then((res) => {
            if(res?.status) setFooterData({...res?.data})
        })
    }

    useEffect(() => {
        getInformativeData();
    }, [])


    const [activeLink, setActiveLink] = useState(window.location.pathname);

    useEffect(() => {
        // Update active link whenever pathname changes
        const handleLocationChange = () => {
            setActiveLink(window.location.pathname);
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const handleNavLinkClick = (path) => {
        setActiveLink(path);
        navigate(path);
    };

    return (
        <>
            <div className="footer py-5">
                <Container>
                    <Row>
                        <Col lg='4' md='12' sm='12' xs='12'>
                            <div className="about-section">
                                <div className="d-flex flex-column gap-2 text-start">
                                    <div className="footer-logo cursor">
                                        <Image
                                            src={Logo}
                                            className="h-100"
                                        />
                                    </div>
                                    <div className="footer-heading">
                                        <label className="fs-5 ff-playfair fw-500" style={{color: 'white'}}>
                                            BRYD ASTROGUIDE PRIVATE LIMITED
                                        </label>
                                        <hr className="wd-50"/>
                                    </div>
                                    <div>
                                        <p className="ff-manrope fs-14 text-initial lh-2">Astroguide is the best astrology website for online Astrology predictions. Talk to Astrologer on call and get answers to all your worries by seeing the future life through Astrology Kundli Predictions from the best Astrologers from India. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.</p>
                                    </div>
                                    <div>
                                        <div className="d-flex gap-3 h-100 align-items-center social">
                                        {
                                            footerData?.youtube && (
                                                <a className="p-2" href={footerData?.youtube} target="_blank">
                                                    <FontAwesomeIcon icon={faYoutube} size={'lg'} />
                                                </a>
                                            )
                                        }
                                        {
                                            footerData?.facebook && (
                                                <a className="p-2" href={footerData?.facebook} target="_blank">
                                                    <FontAwesomeIcon icon={faFacebookF} size={'lg'} />
                                                </a>
                                            )
                                        }
                                        {
                                            footerData?.linkedin && (
                                                <a className="p-2" href={footerData?.linkedin} target="_blank">
                                                    <FontAwesomeIcon icon={faLinkedinIn} size={'lg'} />
                                                </a>
                                            )
                                        }
                                        {
                                            footerData?.instagram && (
                                                <a className="p-2" href={footerData?.instagram} target="_blank">
                                                    <FontAwesomeIcon icon={faInstagram} size={'lg'} />
                                                </a>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg='8' md='12' sm='12' xs='12'>
                            <Row className="justify-content-between text-white">
                                <Col lg='4' sm='4' xs='12'>
                                    <div className="d-flex flex-column gap-2 text-start px-2">
                                        <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="Online Advice" />
                                            </label>
                                            <hr className="wd-50" />
                                        </div>
                                        <div className="text-capitalize">
                                            <ul>
                                                {/* <li>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">astrologers</label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">Chat with Astrologer</label>
                                                    </a>
                                                </li> */}
                                                <li   className={activeLink === '/astrologers' ? 'active' : ''} onClick={() => handleNavLinkClick('/astrologers')}>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3" >
                                                            <LangTrans  val="Talk with Astrologer" />
                                                        </label>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">How to read kundli</label>
                                                    </a>
                                                </li> */}
                                                <li className={activeLink === '/free-kundli' ? 'active' : ''}  onClick={() => handleNavLinkClick('/free-kundli')} >
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3" >
                                                            <LangTrans val="Free Kundali" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li className={activeLink === '/home' ? 'active' : ''}  onClick={() => handleNavLinkClick('/home')}>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Match Kundali" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li className={activeLink === '/daily-horoscope' ? 'active' : ''} onClick={() => handleNavLinkClick('/daily-horoscope')} >
                                                    <a className="fs-14 lh-3" >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Horoscope" /> {moment()?.format('YYYY')}
                                                        </label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' sm='4' xs='12'>
                                    <div className="d-flex flex-column gap-2 text-start px-2">
                                        <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="Quick Links" />
                                            </label>
                                            <hr className="wd-50" />
                                        </div>
                                        <div className="text-capitalize">
                                            <ul>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/aboutus')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="About Us" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/contactus')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Contact Us" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/terms-condition')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Terms and Condition" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/privacy-policy')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Privacy Policy" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/refund-policy')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Refund & Cancellation Policy" />
                                                        </label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => navigate('/faq')}
                                                        className="fs-14 lh-3"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="FAQs" />
                                                        </label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 text-start px-2 mt-2">
                                        <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="Astrologer" />
                                            </label>
                                            <hr className="wd-50" />
                                        </div>
                                        <div className="text-capitalize">
                                            <ul>
                                                {/* <li>
                                                    <a className="fs-14 lh-3">
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">Astrologer Login</label>
                                                    </a>
                                                </li> */}
                                                <li>
                                                    <a
                                                        className="fs-14 lh-3 cursor"
                                                        target="_blank"
                                                        href="https://play.google.com/store/apps/details?id=com.ss.astro_guide_astro"
                                                    >
                                                        <img src={dotIcon} width={12}/>
                                                        <label className="pl-3">
                                                            <LangTrans val="Astrologer Registration" />
                                                        </label>
                                                    </a>
                                                </li>
                                            {
                                                userInfo?.name?.length <= 0 && (
                                                    <li>
                                                        <a
                                                            className="fs-14 lh-3 cursor"
                                                            href="javascript:void(0);"
                                                            onClick={() => dispatch(userLoginModel(true))}
                                                        >
                                                            <img src={dotIcon} width={12}/>
                                                            <label className="pl-3">
                                                                <LangTrans val="Sign In" />
                                                            </label>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='4' sm='4' xs='12'>
                                    <div className="d-flex flex-column gap-2 text-start px-2">
                                        <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="Reach Us" />
                                            </label>
                                            <hr className="wd-50" />
                                        </div>
                                        <div className="text-capitalize">
                                            <ul>
                                                <li>
                                                    <a className="fs-14 d-flex mb-3 cursor" href={`mailto:${footerData?.email}`}>
                                                        <div>
                                                            <FontAwesomeIcon className="icon fs-14" icon={faMessage} />
                                                        </div>
                                                        <label className="pl-3 text-lowercase cursor">{footerData?.email}</label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="fs-14 d-flex mb-3 cursor" href={`tel:${footerData?.mobile}`}>
                                                        <div>
                                                            <FontAwesomeIcon className="icon fs-14" icon={faMobile} />
                                                        </div>
                                                        <label className="pl-3 cursor">{footerData?.mobile}</label>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="fs-14 d-flex">
                                                        <div>
                                                            <FontAwesomeIcon className="icon fs-14" icon={faLocation}  />
                                                        </div>
                                                        <label className="pl-3">{footerData?.address}</label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 text-start px-2 mt-3">
                                        <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="Install App" />
                                            </label>
                                            <hr className="wd-50" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex gap-1">
                                                <div className="w-50">
                                                    <a
                                                        className="w-100"
                                                        href="https://play.google.com/store/apps/details?id=com.ss.astro_guide&pli=1"
                                                        target="_blank"
                                                    >
                                                        <Image
                                                            src={playStore}
                                                            className="w-100"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="w-50">
                                                    <a href="javascript:void(0);">
                                                        <Image
                                                            src={appStore}
                                                            className="w-100"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer