import { React, useEffect, useState } from "react";
import { Button, Card, Col, Collapse, Container, Form, Image, InputGroup, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faAngleUp, faCheck, faExchange, faHome, faSearch, faSort, faTimesSquare, faWallet } from "@fortawesome/free-solid-svg-icons";
import Constant, { userInfo } from "../../config/Constant";

import AstroDetailBox from "../../Components/Content/AstroDetailBox";
import WhyAstro from "../../Components/Content/WhyAstro";
import { useNavigate, useLocation } from "react-router-dom";

import noDataImg from "../../assets/images/no_data.png";

import { apiCall } from "../../Components/Helper/ApiHelper";
import LangTrans from "../../Components/Helper/LangHelper";

// REDUX FILES
import { useDispatch, useSelector } from "react-redux";

const Astrologers = () => {
    
    const dispatch = useDispatch()
    const { astrologersData, loading } = useSelector(state => state.astrologersReducer)

    const navigate = useNavigate()
    const location = useLocation();
    const paramData = location?.state?? {};
    const from = location?.state?.from ?? '';
    const [showFilter, setShowFilter] = useState(false)
    const [specData, setSpecData] = useState([
        {
            id:-1,
            spec:'All'
        }
    ])
    const [filterData, setFilterData] = useState([
        {
            label:'sort by',
            status:true,
            value:'order_by',
            type:'radio',
            data:[
                {
                    label:'Popularity',
                    value:'followers DESC',
                    checked:false
                },
                {
                    label:'Experience: High to Low',
                    value:'experience DESC',
                    checked:false
                },
                {
                    label:'Experience: Low to High',
                    value:'experience ASC',
                    checked:false
                },
                {
                    label:'Sessions: High to Low',
                    value:'sessions DESC',
                    checked:false
                },
                {
                    label:'Sessions: Low to High',
                    value:'sessions ASC',
                    checked:false
                },
                {
                    label:'Chat Price: High to Low',
                    value:'p_chat DESC',
                    checked:false
                },
                {
                    label:'Chat Price: Low to High',
                    value:'p_chat ASC',
                    checked:false
                },
                {
                    label:'Call Price: High to Low',
                    value:'p_call DESC',
                    checked:false
                },
                {
                    label:'Call Price: Low to High',
                    value:'p_call ASC',
                    checked:false
                }
            ]
        },
        {
            label:'gender',
            status:true,
            value:'gender',
            type:'checkbox',
            data:[
                {
                    label:'FEMALE',
                    value:'female',
                    checked:false
                },
                {
                    label:'MALE',
                    value:'male',
                    checked:false
                },
                {
                    label:'OTHER',
                    value:'other',
                    checked:false
                }
            ]
        },
        {
            label:'expertise',
            status:true,
            value:'type',
            type:'checkbox',
            data:[]
        },
        {
            label:'country',
            status:true,
            value:'country',
            type:'checkbox',
            data:[]
        },
        {
            label:'language',
            status:true,
            value:'language',
            type:'checkbox',
            data:[]
        }
    ])
    const [appliedFilterData, setAppliedFilterData] = useState({
        offset:'0',
        search:' WHERE a.status=1 ',
        order_by:from == 'new' ? ` ORDER BY created_at DESC` : '',
        spec:'-1',
        types:'',
    })
    const [astrologerData, setAstrologerData] = useState([])

    const getFilterData = () => {
        apiCall({method:'get', url:Constant?.apis?.astrologerValues})?.then((res) => {
            if (res?.status) {                
                filterData[2].data = res?.types?.map(e => ({label:e?.type, value:e?.id, ...e, checked: paramData?.type == e?.id}))
                filterData[3].data = res?.countries?.map(e => ({label:e?.name, value:e?.id, ...e, checked: paramData?.country == e?.id}))
                filterData[4].data = res?.languages?.map(e => ({label:e?.lang, value:e?.id, ...e, checked: false}))
                setFilterData([...filterData])
                setSpecData([...specData, ...res?.specifications])
                if (paramData?.obj?.type?.length > 0) updateAppliedFilterData({...paramData?.obj})
            }
        })
    }
    
    const getAstroData = () => {
        setAstrologerData([])
        const url = appliedFilterData?.spec > 0 ? Constant?.apis?.astrologerSpec : Constant?.apis?.astrologerUserAll
        apiCall({method:'post', url, body:appliedFilterData})?.then((res) => {
            console.log('response :: ', res?.data)
            if (res?.status) setAstrologerData([...res?.data])
        })
    }
    
    const updateAppliedFilterData = ({ type = '', name = '', value = ''}) => {
        appliedFilterData[name] = value
        setAppliedFilterData({...appliedFilterData})

        if (type === 'radio') {
            appliedFilterData.order_by = `ORDER BY ${value[0]}`
        }

        const leftJoin = [''];
        appliedFilterData.search = ' WHERE a.status=1 ';
        
        if (from == 'live') {
            appliedFilterData.search = `${appliedFilterData?.search} AND sett.online = '1' `;
        }
        if (appliedFilterData?.spec > 0) {
            appliedFilterData.search = `${appliedFilterData?.search} AND asp.spec_id = '${appliedFilterData?.spec}' `;
        }
        if (appliedFilterData?.gender && appliedFilterData?.gender?.length > 0) {
            appliedFilterData.search = `${appliedFilterData?.search} AND a.gender IN ('${appliedFilterData?.gender?.join("',' ")}') `;
        }
        if (appliedFilterData?.country && appliedFilterData?.country?.length > 0) {
            appliedFilterData.search = `${appliedFilterData?.search} AND st.co_id IN ('${appliedFilterData?.country?.join("',' ")}') `;
        }
        if (appliedFilterData?.language && appliedFilterData?.language?.length > 0) {
            leftJoin[0] = `${leftJoin[0]} LEFT JOIN astro_lang al ON a.id = al.astro_id `;
            appliedFilterData.search = `${appliedFilterData?.search} AND al.lang_id IN ('${appliedFilterData?.language?.join("',' ")}') `;
        }
        if (appliedFilterData?.type && appliedFilterData?.type?.length > 0) {
            leftJoin[0] = `${leftJoin[0]} LEFT JOIN astro_type at ON a.id = at.astro_id`;
            appliedFilterData.search = `${appliedFilterData?.search} AND at.type_id IN ('${appliedFilterData?.type?.join("',' ")}') `;
        }
        if (appliedFilterData?.search_text && appliedFilterData?.search_text?.length > 0) {
            appliedFilterData.search = `${appliedFilterData?.search} AND UPPER(a.name) LIKE '%${appliedFilterData?.search_text?.toUpperCase()}%'`;
        }
        appliedFilterData.search = `${leftJoin[0]} ${appliedFilterData?.search}`
        setAppliedFilterData({...appliedFilterData})
        getAstroData()
    }

    useEffect(() => {
        getFilterData()
        getAstroData()
        document.title = 'Astrologers : Astroguide';
    }, [paramData?.obj?.value??''])
    return (
        <div className="astrologers-page text-capitalize">
            <div className="bg-white p-2 filter-section">
                <Container className="py-2">
                    <Row className="justify-content-between align-items-center mb-2">
                        <Col lg='3' sm='6' xs='6'>
                            <div className="d-flex gap-2 align-items-center p-1">
                                <FontAwesomeIcon  onClick={() => navigate('/home')} className="primary-color" icon={faHome}/>
                                <FontAwesomeIcon className="text-black" icon={faAngleRight}/>
                                <label className="pl-1 ff-manrope fs-13 fw-bold">
                                    <LangTrans val="Talk with Astrologer" />
                                </label>
                            </div>
                        </Col>
                        <Col lg='3' sm='6' xs='6'>
                            <div className="d-flex justify-content-center align-items-center p-1">
                                <label className="pl-1 ff-manrope fs-13 fw-bold"><LangTrans val="Available Balance" />: ₹ {userInfo?.amount?? '0'}</label>
                            </div>
                        </Col>
                        <Col lg='6' sm='12' xs='12'>
                            <Row className="filter-division">
                                <Col lg='9' sm='12' xs='12'>
                                    <Row className="justify-content-end">
                                        <Col lg='4' sm='4' xs='12' className="p-1">
                                            <Button
                                                className="recharge"
                                                onClick={() => navigate('/recharge')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faWallet}
                                                    className="pr-2"
                                                />
                                                <LangTrans val="Recharge" />
                                            </Button>
                                        </Col>
                                        <Col lg='4' sm='4' xs='12' className="p-1">
                                            <Button className="filter" onClick={() => setShowFilter(!showFilter)} >
                                                <FontAwesomeIcon icon={faExchange} className="pr-2 primary-color" />
                                                <LangTrans val="Sort & Filter" />
                                            </Button>
                                        </Col>
                                        {/* <Col lg='4' sm='4' xs='12' className="p-1">
                                            <Button className="sortby">
                                                <FontAwesomeIcon icon={faSort} className="pr-2 primary-color" />
                                                sort by
                                            </Button>
                                        </Col> */}
                                    </Row>
                                </Col>
                                <Col lg='3' sm='12' xs='12' className="p-1">
                                    <InputGroup className="search">
                                        <InputGroup.Text>
                                            <FontAwesomeIcon className="primary-color" icon={faSearch}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Search Astrologer"
                                            aria-label="search"
                                            onChange={(e) => {
                                                updateAppliedFilterData({type:'input', name:'search_text', value:e?.target?.value})
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <div className="d-flex w-100 gap-3 flex-xs-row filter-division d-none">
                                <div className="w-20">
                                    <Button className="recharge">
                                        <FontAwesomeIcon icon={faWallet} className="pr-2" />
                                        recharge
                                    </Button>
                                </div>
                                <div className="w-20">
                                    <Button className="filter">
                                        <FontAwesomeIcon icon={faExchange} className="pr-2 primary-color" />
                                        filter
                                    </Button>
                                </div>
                                <div className="w-20">
                                    <Button className="sortby">
                                        <FontAwesomeIcon icon={faSort} className="pr-2 primary-color" />
                                        sort by
                                    </Button>
                                </div>
                                <div className="w-40">
                                    <InputGroup className="search">
                                        <InputGroup.Text>
                                            <FontAwesomeIcon className="primary-color" icon={faSearch}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="search"
                                            aria-label="search"
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-between align-items-center">
                        <Col lg='12'>
                            <div className="mt-2 d-flex gap-2 overflow-auto py-2">
                            {
                                specData?.map((row, index) => {
                                    return (
                                        <div
                                            className={`rounded-5 px-3 py-1 ff-manrope fw-400 fs-15 ${appliedFilterData?.spec == row?.id ? 'border-green-500' : 'border'} cursor`}
                                            onClick={() => {
                                                const val = appliedFilterData.spec == row?.id ? 0 : row?.id
                                                updateAppliedFilterData({ type:'checkbox', name:'spec', value:val})
                                                // setAppliedFilterData({...appliedFilterData})
                                                // updateAppliedFilterData = ({ type = '', name = '', value = ''})
                                            }}
                                        >
                                            <div className={`d-flex gap-1 align-items-center ${appliedFilterData?.spec == row?.id ? 'text-green-500' : ''} spec-div`}>
                                                <span>{appliedFilterData?.spec == row?.id ? (<FontAwesomeIcon icon={faCheck}/>) : ''}</span>
                                                <Image className={index <= 0 && 'd-none'} src={row?.imageFullUrl} width={15} height={15} />
                                                <span className={`ff-manrope ${appliedFilterData?.spec == row?.id ? 'text-green-500' : 'text-grey-500'}`}>{row?.spec}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="astro-list-section py-3 pt-2">
                {
                    astrologerData?.length > 0 ? (
                        <Row>
                        {
                            astrologerData?.map((row) => {
                                return (
                                <Col lg='4' md='6' sm='12' xs='12' className="p-2">
                                    <AstroDetailBox item={row} />
                                </Col>
                                )
                            })
                        }
                        </Row>
                    ) : (
                        <Row>
                            <Col lg='12' className="text-center">
                                <div className="py-3">
                                    <Image
                                        src={noDataImg}
                                        style={{height:'250px'}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )
                }
                </Container>
            </div>
            <div>
                <WhyAstro />
            </div>
            <div className={`filter-data-section ${showFilter ? 'show' : 'hide'}`}>
                <div className="heading-section">
                    <div className="d-flex justify-content-between align-items-center p-2">
                        <div>
                            <label className="ff-manrope fs-16 text-grey-500 fw-600 text-capitalize">find your astro by filter</label>
                        </div>
                        <div>
                            <FontAwesomeIcon onClick={() => setShowFilter(!showFilter)} icon={faTimesSquare}/>
                        </div>
                    </div>
                </div>
                <div className="filter-data p-2 mb-3">
                {
                    filterData?.map((row, index) => {
                        return (
                            <Card className="mb-2">
                                <Card.Header
                                    className="primary-bg-color ff-manrope fw-500 d-flex justify-content-between"
                                    onClick={() => {}}
                                    aria-controls={`f-data-${index}`}
                                    aria-expanded={row?.status}
                                >
                                    <div>{row?.label}</div>
                                    <div
                                        onClick={() => {
                                            row.status = !row?.status
                                            setFilterData([...filterData])
                                        }}
                                    >
                                        <FontAwesomeIcon icon={row?.status ? faAngleUp : faAngleDown}/>
                                    </div>
                                </Card.Header>
                                <Card.Body className={!row?.status && 'p-0'}>
                                    <Collapse in={row?.status}>
                                        <div id={`f-data-${index}`}>
                                        {
                                            row?.data?.map((item, idx) => 
                                                <div key={`chk-${index}-${idx}`} className="py-1">
                                                    <Form.Check
                                                        type={row?.type}
                                                        id={`${row?.value}-chk-${idx}`}
                                                        label={<LangTrans val={item?.label} />}
                                                        value={item?.value}
                                                        checked={row?.type == 'radio' ? (appliedFilterData[row?.value] == `ORDER BY ${item?.value}`) : item?.checked}
                                                        onChange={(e) => {
                                                            row.data[idx].checked = !item?.checked
                                                            const checkData = []
                                                            row?.data?.filter((e) => {
                                                                e?.checked && checkData?.push(`${e?.value}`)
                                                            })
                                                            const data = row?.type === 'radio' ? [item?.value] : checkData
                                                            updateAppliedFilterData({type:row?.type, name:row?.value, value:data})
                                                        }}
                                                        className="ff-manrope fs-14 text-grey-500 cursor-pointer"
                                                    />
                                                </div>
                                            )
                                        }
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )    
}
export default Astrologers;