import React, { useEffect, useState } from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Col, Row } from 'react-bootstrap';
import { apiCall } from '../../Components/Helper/ApiHelper';
import Constant, { userInfo } from '../../config/Constant';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
    topSection:{
        display:'flex',
        alignItems:'center',
        marginTop:'25px',
        flexDirection:'column',
        gap:'1'
    },
    topHeading:{
        fontSize:'22px',
        color:'#000000',
        fontWeight:'bold',
        marginBottom:'5px'
    },
    topSubHeading:{
        marginTop:'2px',
        fontSize:'11px',
        color:'#000000',
        paddingHorizontal:'80px',
        textAlign:'center',
        lineHeight:'1.3px'
    },
    addressSection:{
        marginTop:'20px',
        minHeight:'150px',
        display:'flex',
        flexDirection:'row',
        gap:'2',
        justifyContent:'space-between',
        marginHorizontal:'20px'
    },
    customerSection:{
        display:'flex',
        justifyContent:'flex-start',
        textTransform:'capitalize',
        width:'50%'
    },
    billingSection:{
        display:'flex',
        justifyContent:'flex-start',
        textTransform:'capitalize',
        width:'50%',
        paddingRight:'20px',
        flexWrap:'wrap'
    },
    heading:{
        fontSize:'12px',
        marginBottom:'5px'
    },
    subHeading:{
        fontSize:'11px',
        color:'#1D1D1D',
        lineHeight:'1.5px'
    },
    bold:{
        fontWeight:'bold'
    },
    tableSection:{
        marginHorizontal:'20px'
    },
    tableHeading:{
        fontSize:'11px',
        color:'#000000',
        textAlign:'center',
        display:'flex',
        alignItems:'center'
    },
    tableSubHeading:{
        fontSize:'10px',
        color:'#1D1D1D',
        textAlign:'center',
        display:'flex',
        alignItems:'center',
        padding:'5px'
    }
});
// Create Document Component
const Pdf = () => {
    const [settingData, setSettiongData] = useState({})
    
    useEffect(() => {
        apiCall({method:'get', url:Constant?.apis?.settingsFetch}).then((res) => {
            if(res?.status) setSettiongData({...res?.data})
        })    
    }, [])

    return (
    <PDFViewer style={{width:'100%', height:'100vh'}}>
            <Document style={{width:'100%'}} title='ordr-invoice-AG20230RD7532683'>
                <Page size="A4">
                    <View style={styles?.topSection}>
                        <Text style={styles?.topHeading}>Invoice</Text>
                        <Text style={styles?.topSubHeading}>AstroGuide PVT. LTD.</Text>
                        <Text style={styles?.topSubHeading}>Supplier GST:07GHTFF56GG7HHH, Website:www.astroguide.com</Text>
                        <Text style={styles?.topSubHeading}>Email:{settingData?.email?? 'support@astroguide.com'}</Text>
                        <Text style={styles?.topSubHeading}>Address:{settingData?.address?? '336, 3RD FLOOR AVADH ARENA, NEAR CROMA, VESU-395018'}</Text>
                    </View>
                    <View style={styles?.addressSection}>
                        <View style={styles?.customerSection}>
                            <Text style={{...styles?.heading, ...styles?.bold}}>Customer Address</Text>
                            <Text style={styles?.subHeading}>{userInfo?.name}</Text>
                            <Text style={styles?.subHeading}>surat, gujarat</Text>
                            <Text style={{...styles?.subHeading, ...styles?.bold}}>Place og supply</Text>
                            <Text style={styles?.subHeading}>GJ</Text>
                        </View>
                        <View style={styles?.billingSection}>
                            <Text style={styles?.heading}>Transaction ID:</Text>
                            <Text wrap={true} style={styles?.subHeading}>cs_test_a1RCeVjZjDG7gxGpAb5WH1nbF217HXF1Ydcv5qjP2753uvR3fi50npb03k</Text>
                            <Text style={styles?.heading}>Order ID:<Text style={styles?.subHeading}>AG20230RD7532683</Text></Text>
                            <Text style={styles?.heading}>Invoice No:<Text style={styles?.subHeading}>AG2023INV7532698</Text></Text>
                            <Text style={styles?.heading}>Invoice Date:<Text style={styles?.subHeading}>{moment()?.format('D, MMM YYYY')}</Text></Text>
                        </View>
                    </View>
                    <View style={{...styles?.tableSection, border:'1px solid black'}}>
                        <View style={{display:'flex', flexDirection:'row', borderBottom:'1px solid black'}}>
                            <View style={{width:'28%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>Description</Text>
                            </View>
                            <View style={{width:'15%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>Total</Text>
                            </View>
                            <View style={{width:'17%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>Offer Additional Amount</Text>
                            </View>
                            <View style={{width:'13%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>Taxable Value</Text>
                            </View>
                            <View style={{width:'27%', borderRight:'1px solid black'}}>
                                <View style={{display:'flex', alignItems:'center'}}>
                                    <View style={{borderBottom:'1px solid black', width:'100%'}}>
                                        <Text style={styles?.tableHeading}>GST</Text>
                                    </View>
                                    <View style={{display:'flex',flexDirection:'row',width:'100%'}}>
                                        <Text style={{...styles?.tableHeading,width:'50%',borderRight:'1px solid black'}}>Rate</Text>
                                        <Text style={{...styles?.tableHeading,width:'50%'}}>Amount</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row', borderBottom:'1px solid black'}}>
                            <View style={{width:'28%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableSubHeading}>Money added to your wallet on package recharge of Rs. 6000</Text>
                            </View>
                            <View style={{width:'15%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableSubHeading}>6000.00</Text>
                            </View>
                            <View style={{width:'17%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>20.00</Text>
                            </View>
                            <View style={{width:'13%', borderRight:'1px solid black'}}>
                                <Text style={styles?.tableHeading}>0.0</Text>
                            </View>
                            <View style={{width:'13.5%', borderRight:'1px solid black'}}>
                                <Text style={{...styles?.tableHeading}}>18%</Text>
                            </View>
                            <View style={{width:'13.5%', borderRight:'1px solid black'}}>
                                <Text style={{...styles?.tableHeading}}>1080.00</Text>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row', borderBottom:'1px solid black'}}>
                            <View style={{width:'60%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>Total</Text>
                            </View>
                            <View style={{width:'13%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>0.0</Text>
                            </View>
                            <View style={{width:'27%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>1080.00</Text>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row', borderBottom:'1px solid black'}}>
                            <View style={{width:'60%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>Total GST</Text>
                            </View>
                            <View style={{width:'40%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>1080.00</Text>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row'}}>
                            <View style={{width:'60%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>Total Amount</Text>
                            </View>
                            <View style={{width:'40%', borderRight:'1px solid black'}}>
                                <Text style={{textAlign:'right', fontSize:'11px'}}>7080.00</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{marginTop:'15px', display:'flex', marginHorizontal:'20px'}}>
                        <Text style={{fontWeight:'bold', fontSize:'12px'}}>Other Details</Text>
                        <Text style={{fontSize:'11px'}}>HSN/SAC993883</Text>
                        <Text style={{fontSize:'11px'}}>Whether tax is payable on this transaction NO PAN Number 24ADDC6GGFF7</Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}
export default Pdf