import React, { useState } from "react";
import { Button, Col, Collapse, Container, Image, Row } from 'react-bootstrap';
import Constant from "../../config/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import dotIcon from "../../assets/images/dot_icon.png";
import { useEffect } from "react";
import { apiCall } from "../Helper/ApiHelper";
import HTMLRenderer from 'react-html-renderer'
import LangTrans from "../Helper/LangHelper";

const Faq = () => {
    const [faqData, setFaqData] = useState([])

    const getFaqData = () => {
        apiCall({method:'get',url:Constant?.apis?.faqAll}).then((res) => {
            if (res?.status) {
                const data = res?.data?.map((e, idx) => ({label:e?.question, value:e?.answer, status:idx < 1}))
                setFaqData(data)
            }
        })
    }

    useEffect(() => {
        getFaqData()
    }, [])

    return (
        <Container className="faq-section py-5">
            <Row>
                <Col lg='12' className="text-center">
                    <h3 className="ff-playfair fw-bold">
                        <LangTrans val="FAQs" />
                    </h3>
                </Col>
            </Row>
            <div className="d-flex flex-column py-4 gap-3">
            {
                faqData?.map((row, index) => {
                    return (
                        <div className="p-1 faq-div">
                            <div className="d-flex justify-content-between align-items-center p-1 px-2">
                                <div>
                                    <Image 
                                        src={dotIcon}
                                        width={17}
                                    />
                                    <label className="fs-6 fw-bold text-black ff-playfair pl-2">{row?.label}</label>
                                </div>
                                <div>
                                    <Button
                                        onClick={() => {
                                            row.status = !row?.status
                                            setFaqData([...faqData])
                                        }}
                                        aria-controls={`faq-row-${index}`}
                                        aria-expanded={row?.status}
                                        className="border-0 bg-transparent fs-15 text-black"
                                    >
                                        <FontAwesomeIcon icon={!row?.status ? faPlus : faMinus}/>
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <Collapse in={row?.status} className="pb-2">
                                    <div className="text-start px-2 ff-manrope fs-13 text-black" id={`faq-row-${index}`}>
                                        <HTMLRenderer html={row?.value} />
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </Container>
    )
}

export default Faq