import React, { useState } from "react";
import { Button, Card, Container, Image, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import online from "../../assets/images/online_icon.png";
import verified from "../../assets/images/verified_icon.png";
import language from "../../assets/images/language_icon.png";
import callIcon from "../../assets/images/call_icon.png";
import chatIcon from "../../assets/images/chat_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { Rating } from 'react-simple-star-rating'
import { manageWishList } from "../Helper/ApiHelper";
import LangTrans from "../Helper/LangHelper";
import { storage, userInfo } from "../../config/Constant";

// REDUX FILES
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";
import { toast } from "react-toastify";

const AstroDetailBox = ({ item = '' }) => {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [fav, setFav] = useState(item?.fav)
    const redirectToDetail = (id = 0, name = '') => {
        console.log('id :: ', id)
        navigate('/astrologer-detail', {
            state: { id, name }
        })
    }

    const communication = (item) => {

        const amount = item?.clicked == 'call' ? item?.p_call : item?.p_chat
        
        if (item?.clicked == 'call' && item?.conline != 1) {
            toast.warn(`${item?.name} is not available for ${item?.clicked}.`)
            return
        }

        if (!userInfo?.token) {
            toast.warn(`for ${item?.clicked} you must have login`)
            dispatch(dispatch(userLoginModel(true)))
            return
        }

        if (item?.online != '1')
        {
            toast.warning(`${item?.name} is offline`)
            return
        }

        if (item?.free == 0 || userInfo?.free == 1) {
            if ((Number(item?.p_call) * 5) > Number(userInfo?.amount)) {
                return toast.warning(`Minimum ${Number(item?.p_call) * 5} rs. is required to ${item?.clicked} with ${item?.name}`)
            }
        }
        const perSecond = Number((amount/60).toFixed(2))
        const myAvailableSec = Number((Number(userInfo?.amount) / perSecond).toFixed(0))

        localStorage?.removeItem(storage?.callingClock)
        localStorage?.removeItem(storage?.callStatus)

        navigate('/communicate', {
            state: {
                ...item,
                availableSecond:myAvailableSec,
                perSecond,
                // free:(item?.free == 0 || userInfo?.free == 1) ? 1 : 0
                free:item?.free
            }
        })
    }

    const [showModal, setShowModal] = useState(false);

    return (
        <Card
            className="astro-detail-box cursor"
        >
            <Card.Body>
                <div className="d-flex justify-content-between h-100">
                    <div
                        className="profile-image cursor-pointer" style={{ backgroundImage: `url(${item?.imageFullUrl})` }}
                        onClick={() => redirectToDetail(item?.id, item?.name)}
                    >
                        <div className={`verified ${item?.verified !== 1 && 'd-none'}`}>
                            <Image src={verified} />
                        </div>
                        <div className="ratting w-100 d-flex align-items-center align-self-stretch flex-column">
                            <div className="star d-flex justify-content-center">
                                <Rating readonly={true} initialValue={item?.rating} SVGstyle={{ width: '17px', height: '17px' }} />
                            </div>
                            <div className="review">
                                <label className="fs-12">{item?.reviews ? `Review ${item?.reviews}` : ''}</label>
                            </div>
                        </div>
                    </div>
                    <div className="w-72 px-2 astro-information d-flex flex-column justify-content-between align-items-start">
                        <div className="d-flex justify-content-between w-100">
                            <div
                                className=" d-flex flex-column gap-1"
                                onClick={() => redirectToDetail(item?.id, item?.name)}
                            >
                                <div className="d-flex align-items-center">
                                    <label className="name ff-playfair fs-16 fw-bold pointer">{item?.name}</label>
                                    <div className={`online d-flex align-items-center ${item?.online != '1' && 'd-none'}`}>
                                        <Image src={online} />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <label className="ff-manrope fs-12 fw-500">{item?.types}</label>
                                </div>
                            </div>
                            <div className="heart d-flex justify-content-center align-items-center  bg-light">
                                {/* <Image src={heart} /> */}
                                <FontAwesomeIcon
                                    className="cursor"
                                    icon={faHeart} color={fav == 1 ? 'red' : 'white'}
                                    onClick={() => {
                                        manageWishList({ status: fav == 1, astro_id: item?.id })?.then((res) => {
                                            if (res?.status) setFav(fav == 1 ? 0 : 1)
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-2 language py-1">
                            <div className="icon">
                                <Image src={language} />
                            </div>
                            <div>
                                <label className="ff-manrope fs-12 fw-500">{item?.languages ? item?.languages : 'N/A'}</label>
                            </div>
                        </div>
                        <div
                            className="button-group px-2 d-flex justify-content-between py-1"
                        >
                            <Button
                                className="d-flex justify-content-between call"
                                onClick={() => communication({ ...item, clicked: 'call' })}
                            // onClick={() =>handleVideoCall({...item, clicked:'call'})}
                            // onClick={()=>handleVideocall()}
                            // onClick={() => navigate('/videocall')}
                            >
                                <div className="icon">
                                    <Image
                                        src={callIcon}
                                    />
                                    <label className="pl-1"><LangTrans val="Call" /></label>
                                </div>
                                {/* <div className="charge">{(item?.free == 1 && userInfo?.free == 0) ? 'Free' : `₹ ${item?.p_call}/min`}</div> */}
                                <div className="charge">{(item?.free == 1) ? 'Free' : `₹ ${item?.p_call}/min`}</div>
                            </Button>
                            <Button
                                className="d-flex justify-content-between chat"
                                // onClick={() => communication({...item, clicked:'chat'})}
                                onClick={() => communication({ ...item, clicked: 'chat' })}
                            >
                                <div className="icon">
                                    <Image
                                        src={chatIcon}
                                    />
                                    <label className="pl-1"><LangTrans val="Chat" /></label>
                                </div>
                                {/* <div className="charge">{(item?.free == 1 && userInfo?.free == 0) ? 'Free' : `₹ ${item?.p_chat}/min`}</div> */}
                                <div className="charge">{(item?.free == 1) ? 'Free' : `₹ ${item?.p_chat}/min`}</div>
                            </Button>
                            <Modal show={showModal} className="d-flex jutify-content-center align-items-center">
                                <Container >
                                    <h5>Your amount is too much</h5>
                                    <button onClick={() => setShowModal(!showModal)}>close</button>
                                </Container>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AstroDetailBox