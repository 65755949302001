import { React } from "react";
import { Card, Col, Image, Nav, Row, Tab } from "react-bootstrap";
import walletImg from "../../assets/images/wallet_icon.png";
import calendarImg from "../../assets/images/calendar_icon.png";
import timerImg from "../../assets/images/time_icon.png";
import copyImg from "../../assets/images/copy_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Constant, { userInfo } from "../../config/Constant";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LangTrans from "../../Components/Helper/LangHelper";

const WalletHistory = ({walletData = [], paymentData = []}) => {
    const navigate = useNavigate()
    return (
        <div className="wallet-section pt-3 d-flex flex-column gap-4">
            <Card className="information">
                <Card.Body className="d-flex flex-column rounded-bottom-0">
                    <div className="heading text-start p-3">
                        <label className="ff-manrope fs-16 fw-600 text-grey-900">
                            <LangTrans val="Wallet" />
                        </label>
                    </div>
                    <div className="amount d-flex justify-content-between p-3">
                        <div className="image d-flex align-items-center justify-content-center">
                            <Image
                                src={walletImg}
                                width={35}
                                height={35}
                            />
                        </div>
                        <div className="balance d-flex flex-column justify-content-end">
                            <h3 className="ff-manrope fw-600 text-grey-400 text-end mb-0">₹ {userInfo?.amount > 0 ? Number(userInfo?.amount)?.toFixed(2) : '0.00'}</h3>
                            <label className="ff-manrope fs-18 fw-bold text-grey-900"><LangTrans val="Available Balance" /></label>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="transaction">
                <Card.Body className="d-flex flex-column rounded-bottom-0">
                    <Tab.Container defaultActiveKey="transaction">
                        <Nav variant="pills" className="tab w-100">
                            <Nav.Item className="text-center">
                                <Nav.Link eventKey="transaction">
                                    <LangTrans val="Wallet Transactions" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link eventKey="paymentlog">
                                    <LangTrans val="Payment Logs" />
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="content">
                            <Tab.Pane eventKey="transaction" className="transaction">
                                <Row>
                                {
                                    walletData?.map((row, index) => {
                                        return (
                                            <Col lg='4' md='6' sm='12' xs='12' className="my-2" key={index}>
                                                <Card className="h-100">
                                                    <Card.Body className="d-flex flex-column">
                                                        <div className="d-flex justify-content-between h-100">
                                                            <label className="ff-manrope fs-16 fw-600 text-grey-900 w-80">{row?.description}</label>
                                                            <label className={`ff-manrope fs-16 fw-600 w-20 text-end ${row?.t_type == 'CREDIT' ? 'text-green-500' : 'text-red-500'}`}>₹ {row?.amount}</label>
                                                        </div>
                                                        <div className="d-flex gap-3 py-1">
                                                            <div className="d-flex align-items-center">
                                                                <Image
                                                                    src={calendarImg}
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                                <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600">{moment(row?.created_at)?.format('D, MMM YY')}</label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Image
                                                                    src={timerImg}
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                                <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600">{moment(row?.created_at)?.format('hh:MM A')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-1">
                                                            <label className="ff-manrope fs-14 text-grey-500 fw-600">{row?.order_id}</label>
                                                            <div className="d-flex">
                                                                <CopyToClipboard onCopy={() => console.log('hello :: ', row?.order_id)} text={row?.order_id}>
                                                                    <div className="cursor">
                                                                        <Image
                                                                            src={copyImg}
                                                                            width={20}
                                                                            height={20}
                                                                        />
                                                                        <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600 cursor">
                                                                            <LangTrans val="Copy" />
                                                                        </label>
                                                                    </div>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <button
                                                                className="download-button"
                                                                onClick={() => {
                                                                    window?.open(`${Constant?.baseURL}invoice`, '_blank')
                                                                    // navigate('/invoice',{
                                                                    //     state:{invoiceData:row}
                                                                    // })
                                                                }}
                                                            >
                                                                <LangTrans val="Download Invoice" />
                                                            </button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="paymentlog" className="paymentlog">
                                <Row>
                                {
                                    paymentData?.map((row, index) => {
                                        return (
                                            <Col lg='4' md='6' sm='12' xs='12' className="my-2">
                                                <Card className="h-100">
                                                    <Card.Body className="d-flex flex-column">
                                                        <div className="d-flex justify-content-between">
                                                            <label className="ff-manrope fs-16 fw-600 text-grey-900 w-80">{row?.description}</label>
                                                            <label className={`ff-manrope fs-16 fw-600 w-20 text-end ${row?.t_type == 'CREDIT' ? 'text-green-500' : 'text-red-500'}`}>₹ {row?.amount}</label>
                                                        </div>
                                                        <div className="d-flex gap-3 py-1">
                                                            <div className="d-flex align-items-center">
                                                                <Image
                                                                    src={calendarImg}
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                                <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600">{moment(row?.created_at)?.format('D, MMM YY')}</label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Image
                                                                    src={timerImg}
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                                <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600">{moment(row?.created_at)?.format('hh:MM A')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-1">
                                                            <label className="ff-manrope fs-14 text-grey-500 fw-600">{row?.order_id}</label>
                                                            <div className="d-flex cursor-pointer">
                                                                <CopyToClipboard onCopy={() => console.log('hello :: ', row?.order_id)} text={row?.order_id}>
                                                                    <div className="cursor">
                                                                        <Image
                                                                            src={copyImg}
                                                                            width={20}
                                                                            height={20}
                                                                        />
                                                                        <label className="pl-1 ff-manrope fs-14 text-grey-500 fw-600 cursor">
                                                                            <LangTrans val="Copy" />
                                                                        </label>
                                                                    </div>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <button
                                                                className={`payment-button ${row?.status == 1 ? 'bg-p-orange-700' : 'bg-red-500'}`}
                                                                onClick={() => {
                                                                    window?.open(`${Constant?.baseURL}invoice`, '_blank')
                                                                    // navigate('/invoice',{
                                                                    //     state:{invoiceData:row}
                                                                    // })
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={row?.status == 1 ? faCheckCircle : faInfoCircle} size={'lg'} className="cursor" />
                                                                <label className="pl-1 cursor">
                                                                    <LangTrans val={row?.status == 1 ? 'Success' : 'Failed'} />
                                                                </label>
                                                            </button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Card.Body>
            </Card>
        </div>
    )    
}
export default WalletHistory;