import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style/base/default/style.scss'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Header from './Components/Navigation/Header';
import Footer from './Components/Navigation/Footer';
// import { store } from "./app/store";
import Store from './Redux/Store';
import { Provider } from "react-redux";

import './i18n';

import '../node_modules/react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
const hideNav = ['/pdf', '/invoice']?.indexOf(window?.location?.pathname) < 0

const LazyApp = lazy(() => import('./App'))
root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
      <Provider store={Store}>
        <div>
          {hideNav && (<Header />)}
          <App />
          {hideNav && (<Footer />)}
        </div>
      </Provider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
