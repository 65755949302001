import { useState, React, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";

import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { apiBaseURL, storage, userInfo } from "../../config/Constant";
import moment from "moment";

import Loader from "../../Components/Loader/Loader";

import { useDispatch } from "react-redux";
import { communicateModule } from "../../Redux/Slices/Communicate/CommunicateModuleSlice";

// chat related
import io from 'socket.io-client';
import { toast } from "react-toastify";

const Notification = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const param = useParams()

    const notifiData = JSON.parse(atob(param?.data))
    const [loading, setLoading] = useState(true)
    const [astroDetail, setAstroDetail] = useState({})

    // function for get astro detail start -----
    const getAstroDetail = () => {
        if (notifiData?.data?.title == 'RECONNECT') {
            apiCall({url:Constant?.apis?.astrologerById, body:{id:notifiData?.data?.astro_id}})?.then((res) => {
                if (res?.status) {
                    setAstroDetail({...res})
                }
            })
        }
    }
    // function for get astro detail close -----

    // Chat Module related Section Start -----
    
    // Chat/Call Reject Time
    const manageActionEvent = async (event = 'R') => {

        if (notifiData?.data?.path == '/chat') {
            const socket = io(apiBaseURL, {
                transports: ['websocket'],
                query: {
                    username: userInfo?.token,
                    ch_id: notifiData?.data?.ch_id?.toString(),
                    sender: "U",
                    user_id: parseInt(notifiData?.data?.astro_id),
                }
            });
        
            socket.on('connect', () => {
                console.log('Connection established');
            });
        
            socket.on('connect_error', (error) => {
                console.log('Connect Error: ', error);
            });
        
            socket.on('disconnect', () => {
                console.log('Socket.IO server disconnected');
            });
        
            if (event == 'A') {
                const data = {
                    username: userInfo?.token,
                    ch_id: notifiData?.data?.ch_id?.toString(),
                    sender: "U"
                };
                socket.emit('initiate', data);
        
                localStorage.removeItem(storage?.chatStartSrv);
                localStorage?.setItem(storage?.chatStart, moment()?.subtract(1, 'second')?.format('YYYY-MM-DD HH:mm:ss'))
                localStorage.setItem(storage?.activeAction, 'active');
                localStorage?.setItem(storage?.communicate, JSON.stringify({
                    ...astroDetail?.astrologer,
                    ...notifiData?.data,
                    type: "RECONNECT",
                    action: "ACCEPT",
                    category: "CHAT",
                    fromNotifi:1
                }))
                navigate('/chat')
            } else {
                const data = {
                    username: userInfo?.token,
                    ch_id: notifiData?.data?.ch_id?.toString(),
                    sender: "U",
                    reason: "Chat was rejected by user",
                };
                socket.emit('reject', data);
                toast.warn(data?.reason)
                dispatch(communicateModule())
                navigate('/history', {
                    state:{
                        tab:'Chat'
                    }
                })
            }
        }
        if (notifiData?.data?.path == '/call') {
            console.log('this is demo :: ', notifiData?.data, event);
            if (event == 'R') {
                const body = {
                    ch_id:notifiData?.data?.ch_id,
                    sender:"U",
                    reason:"Call was rejected by user"
                }
                apiCall({url:Constant?.apis?.callReject, body})?.then((res) => {
                    if (res?.status) {
                        dispatch(communicateModule())
                        toast.warn(body?.reason)
                        navigate('/history', {
                            state:{
                                tab:'Call'
                            }
                        })
                    }
                })
            }
            if (event == 'A') {
                const body = {
                    ch_id:notifiData?.data?.ch_id,
                    meetingID:notifiData?.data?.meeting_id,
                    type:'ACTIVE'
                }
                apiCall({url:Constant?.apis?.callValidate, body})?.then((res) => {
                    console.log('call Accept Response :: ', res)
                    const communi = JSON.parse(notifiData?.data?.session_history);
                    if (res?.status) {
                        const communicate = {
                            ...notifiData?.data,
                            type: "RECONNECT",
                            action: "ACCEPT",
                            category: "CALL",
                            session_history:communi,
                            ...userInfo,
                            token:res?.token,
                            fromNotifi:1,
                            id:communi?.id
                        }

                        localStorage.setItem(storage?.communicate, JSON.stringify(communicate))
                        localStorage?.setItem(storage?.callingClock, moment());
                        localStorage?.setItem(storage?.callStart, moment()?.format('YYYY-MM-DD HH:mm:ss'))
                        localStorage?.setItem(storage?.callStatus, 'JOINED')

                        setTimeout(() => {
                            navigate("/videocall");
                        }, 1000);
                    }
                })
            }
        }
    };
    // Chat Module related Section Close -----

    useEffect(() => {

        if (notifiData?.data?.title?.toUpperCase() != 'RECONNECT') {

            dispatch(communicateModule())
            toast.warn(notifiData?.data?.body)
            navigate('/history', {
                state:{
                    tab:notifiData?.data?.path == '/call' ? 'Call' : 'Chat',
                    childTab:notifiData?.data?.title == 'Call Ended' ? 'COMPLETED' : notifiData?.data?.title?.toUpperCase()
                }
            })
            return
        }
        
        setLoading(false)
        getAstroDetail()

    }, [])

    return (
        <>
            <Container className="py-3">
            {
                loading ? (
                    <>
                        <Row className="justify-content-center bg-white">
                            <Col lg={5} sm={8} xs={12}>
                                <div>
                                    <Loader />
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row className="justify-content-center">
                            <Col lg={5} sm={8} xs={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex flex-column gap-3 align-items-center py-3">
                                            <h5 className="ff-nunito fw-500 text-capitalize">{notifiData?.data?.body}</h5>
                                            <div className="d-flex gap-3">
                                                <Button className="btn-success border-0 px-4" onClick={() => manageActionEvent('A')}>Accept</Button>
                                                <Button className="btn-danger border-0 px-4" onClick={() => manageActionEvent('R')}>Reject</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }
            </Container>
        </>
    )
}
export default Notification;