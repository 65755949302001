import { createSlice } from "@reduxjs/toolkit";
import { storage } from "../../../config/Constant";

const CommunicateModuleSlice = createSlice({
    name:'CommunicateModule',
    initialState:{
        showAlertButton:false,
    },
    reducers:{
        communicateModule : (state, action) => {
            localStorage?.removeItem(storage?.communicate)
            localStorage?.removeItem(storage?.callingClock)
            localStorage?.removeItem(storage?.callStart)
            localStorage?.removeItem(storage?.callStatus)
            localStorage?.removeItem(storage?.chatStartSrv)
            localStorage?.removeItem(storage?.chatStart)
            localStorage?.removeItem(storage?.activeAction)
        }
    },
})

export const { communicateModule } = CommunicateModuleSlice.actions;
export default CommunicateModuleSlice;