import { React, useEffect, useState } from "react";
import noDataImg from "../../assets/images/no_data.png";
import { Row, Col, Image, Card } from "react-bootstrap";
import calendarImg from "../../assets/images/calendar_icon.png";
import timerImg from "../../assets/images/time_icon.png";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import Constant from "../../config/Constant";
import LangTrans from "../../Components/Helper/LangHelper";

const CallHistory = ({ callData = [], childTab = 'ALL'}) => {
    const statusData = ['ALL', 'WAITLISTED', 'COMPLETED', 'ACTIVE', 'REQUESTED', 'RECONNECT','MISSED', 'REJECTED', 'CANCELLED'];
    const [currentStatus, setCurrentStatus] = useState(childTab)
    const [statusCallData, setStatusCallData] = useState([])
    
    const getCallData = () => {
        if (currentStatus?.toLowerCase() == 'all') {
            setStatusCallData([...callData])
        } else {
            setStatusCallData([...callData?.filter((e) => e?.status == currentStatus)])
        }
    }

    useEffect(() => {
        getCallData()
    }, [currentStatus, callData])

    return (
        <div className="call-section">
            <Row className="mt-2">
                <Col lg='12'>
                    <div className="d-flex gap-2 overflow-auto">
                    {
                        statusData?.map((val, index) => {
                            return (
                                <div
                                    className={`cursor rounded-5 px-3 py-1 ${currentStatus == val ? 'primary-bg-color text-black fw-600' : 'border'} ff-manrope fs-15 fw-500 d-flex align-items-center`}
                                    key={index}
                                    onClick={() => setCurrentStatus(val)}
                                >
                                    {val}
                                </div>
                            )
                        })
                    }
                    </div>
                </Col>
                <Col lg='12'>
                {
                    statusCallData?.length > 0 ? (
                        <Row>
                        {
                            statusCallData?.map((row) => {
                                return (
                                    <Col lg='4' md='6' sm='12' xs='12' className="my-2">
                                        <Card className="h-100">
                                            <Card.Body className="d-flex flex-column gap-1">
                                                <div className="h-100 d-flex flex-column gap-1">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="ff-poppins fs-16 fw-600 text-grey-900 w-75">{row?.astrologer}</label>
                                                        <label className={`ff-manrope fs-15 fw-600 w-25 text-end text-red-500`}>-INR {row?.amount ? (row?.amount)?.toFixed(2) : '0.00'}</label>
                                                    </div>
                                                    <div className="fs-14 fw-500 text-grey-500 ff-manrope d-flex justify-content-between">
                                                        <label className=""><LangTrans val="Session Type" /> : <label className="text-primary f-600">{row?.type}</label></label>
                                                        <label className={`text-end`}><LangTrans val="Rate" />:{row?.rate}/min</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between gap-3 py-1 ff-roboto fs-14 text-grey-500 fw-500">
                                                        <div className="w-50">
                                                            <div className="d-flex justify-content-start gap-1 align-items-center">
                                                                <Image
                                                                    src={calendarImg}
                                                                    width={18}
                                                                    height={18}
                                                                />
                                                                <label>{moment(row?.created_at)?.format('D MMM, YYYY')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="w-50">
                                                            <div className="d-flex justify-content-end gap-1 align-items-center">
                                                                <Image
                                                                    src={timerImg}
                                                                    width={18}
                                                                    height={18}
                                                                />
                                                                <label>{moment(row?.created_at)?.format('hh:MM A')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between ff-roboto fs-14 text-grey-500">
                                                        <label className="w-75">{row?.reason}</label>
                                                        <label className="w-25"></label>
                                                    </div>
                                                </div>
                                                <div>
                                                {
                                                    row?.amount <= 0 && (
                                                        <div className="mt-2">
                                                            <button
                                                                className={`bg-p-orange-700 border-0 w-100 rounded-5 py-2 text-white text-uppercase fw-600`}
                                                                onClick={() => {
                                                                    window?.open(`${Constant?.baseURL}invoice`, '_blank')
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faFileInvoice} size={'sm'} className="cursor" />
                                                                <label className="pl-1 cursor">
                                                                    <LangTrans val="SHOW DETAILS" />
                                                                </label>
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                        </Row>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center p-3 flex-column">
                            <div className="p-2 no-data">
                                <Image src={noDataImg} />
                            </div>
                            <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
                                <h3 className="text-grey-900 ff-playfair fw-bold mt-2">Uh-oh!</h3>
                                <label className=""><LangTrans val="You've not taken any call consultations yet!" /></label>
                            </div>
                        </div>
                    ) 
                }
                </Col>
            </Row>
        </div>
    )    
}
export default CallHistory;