import { Button, Card, Col, Container, Form, Image, InputGroup, Modal, ProgressBar, Row } from "react-bootstrap";
import online from "../../assets/images/online_icon.png";
import verified from "../../assets/images/verified_icon.png";
import coverImage from "../../assets/images/CoverImage.png";
import callIcon from "../../assets/images/call_icon.png";
import chatIcon from "../../assets/images/chat_icon.png";
import whiteCallIcon from "../../assets/images/w_call_icon.png";
import whiteChatIcon from "../../assets/images/w_chat_icon.png";
import locationIcon from "../../assets/images/location_icon.png";
import whyIcon1 from "../../assets/images/w_icon_1.png";
import whyIcon2 from "../../assets/images/w_icon_2.png";
import whyIcon3 from "../../assets/images/w_icon_3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faHeart, faHome, faListDots, faUser } from "@fortawesome/free-solid-svg-icons";
import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from 'react-simple-star-rating'

import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.scss"
import "../../../node_modules/slick-carousel/slick/slick-theme.scss"

import Constant, { NO_DATA_FOUND, userInfo } from "../../config/Constant";
import { apiCall, manageWishList } from "../../Components/Helper/ApiHelper";
import moment from "moment";
import LangTrans from "../../Components/Helper/LangHelper";

// REDUX FILES
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";
import { toast } from "react-toastify";

const AstrologerDetail = ({ item = '' }) => {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation();
    const [astroDetail, setAstroDetail] = useState({})
    const [fav, setFav] = useState()
    const [id, setId] = useState(location?.state?.id)
    const [name, setName] = useState(location?.state?.name)
    const [loadMore, setLoadMore] = useState(false)
    const getAstroDetail = (id) => {
        apiCall({ method: 'post', url: Constant?.apis?.astrologerById, body: { id } })?.then((res) => {
            if (res?.status) {
                const data = res
                setFav(data?.astrologer?.fav)
                data.astro_types = data?.types?.length > 0 ? data?.types?.map(e => e?.type).reduce((a, b) => `${a}, ${b}`) : []
                data.rating_data = Object?.values(data?.rating)?.reverse()
                data.total_rating = Object?.values(data?.rating)?.reduce((a, b) => a + b)
                data.sum_of_reviews = data?.reviews?.length > 0 ? data?.reviews?.map(e => Math.round(Number(e.rating))).reduce((a, b) => a + b) : 0
                data.rating_avg = data?.sum_of_reviews > 0 ? (Number(data?.sum_of_reviews) / data?.total_rating).toFixed(2) : '0.00'
                data.experience = moment().diff(data?.astrologer?.experience, 'years')
                data.similar = data?.similar?.filter((e, index) => index <= 3)
                // return
                setAstroDetail({ ...data })
            }
        })
    }

    const redirectToDetail = (id = 0, name = '') => {
        setId(id)
        navigate('/astrologer-detail', {
            state: { id, name }
        })
    }

    useEffect(() => {
        getAstroDetail(id)
        document.title = 'Astrologer Detail : Astroguide';
    }, [id])

    const communication = (item) => {

        const amount = item?.clicked == 'call' ? item?.p_call : item?.p_chat

        if (item?.clicked == 'call' && item?.conline != 1) {
            toast.warn(`${item?.name} is not available for ${item?.clicked}.`)
            return
        }

        if (!userInfo?.token) {
            toast.warn(`for ${item?.clicked} you must have login`)
            dispatch(dispatch(userLoginModel(true)))
            return
        }

        if (item?.online != '1')
        {
            toast.warning(`${item?.name} is offline`)
            return
        }

        if (item?.free == 0 || userInfo?.free == 1) {
            if ((Number(item?.p_call) * 5) > Number(userInfo?.amount)) {
                return toast.warning(`Minimum ${Number(item?.p_call) * 5} rs. is required to ${item?.clicked} with ${item?.name}`)
            }
        }
        const perSecond = Number((amount/60).toFixed(2))
        const myAvailableSec = Number((Number(userInfo?.amount) / perSecond).toFixed(0))
        console.log('amount per second :: ', perSecond, myAvailableSec)

        navigate('/communicate', {
            state: {
                ...item,
                availableSecond:myAvailableSec,
                perSecond,
                // free:(item?.free == 0 || userInfo?.free == 1) ? 1 : 0
                free:item?.free == 1
            }
        })
    }

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
        console.log(showModal)
    };

    const [showModal1, setShowModal1] = useState(false);

    const openModal1 = () => {
        setShowModal1(true);
        console.log(showModal1)
    };


    return (
        <div className="astro-detail-page text-capitalize">
            <div className="bg-white p-2">
                {
                    location?.state?.id > 0 ? (
                        <div>
                            <Container className="py-2">
                                <Row className="justify-content-between align-items-center">
                                    <Col lg='12'>
                                        <div className="d-flex gap-1 align-items-center p-1">
                                            <FontAwesomeIcon  onClick={() => navigate('/home')} className="primary-color" icon={faHome} />
                                            <FontAwesomeIcon className="text-black" icon={faAngleRight} />
                                            <label className="pl-1 ff-manrope fs-13 fw-bold">
                                                <LangTrans val="Chat with Astrologer" />
                                            </label>
                                            <FontAwesomeIcon className="text-black" icon={faAngleRight} />
                                            <label className="pl-1 ff-manrope fs-13 fw-bold">{astroDetail?.astrologer?.name}</label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="profile-section py-3">
                                <Row>
                                    <Col lg='4' sm='5' xs='12'>
                                        <Card className="personal"
                                        >
                                            <Card.Body className="personal-section">
                                                <div className="detail d-flex flex-column">
                                                    <div className="cover-image d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${coverImage})` }}>
                                                        <div className="heart d-flex justify-content-center align-items-center position-absolute">
                                                            <FontAwesomeIcon
                                                                className="cursor"
                                                                icon={faHeart}
                                                                color={fav == 1 ? 'red' : 'white'}
                                                                onClick={() => {
                                                                    manageWishList({ status: fav == 1, astro_id: astroDetail?.astrologer?.id })?.then((res) => {
                                                                        if (res?.status) setFav(fav == 1 ? 0 : 1)
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="online d-flex align-items-center position-absolute">
                                                            {
                                                                astroDetail?.astrologer?.online > 0 && (
                                                                    <Image
                                                                        src={online}
                                                                    />
                                                                )
                                                            }
                                                            <label className="ff-manrope fs-10 fw-500">
                                                                <LangTrans val={astroDetail?.astrologer?.online > 0 ? 'Online' : 'Offline'} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="personal-contain d-flex flex-column align-items-center">
                                                        <div className="detail d-flex flex-column align-items-center">
                                                            <div className="image" style={{ backgroundImage: `url(${astroDetail?.astrologer?.imageFullUrl})` }}></div>
                                                            <div className="info d-flex flex-column align-items-center w-100">
                                                                <div className="name d-flex align-items-center gap-1">
                                                                    <label className="fs-5 ff-playfair fw-bold">{astroDetail?.astrologer?.name}</label>
                                                                    <Image
                                                                        className={`${astroDetail?.astrologer?.verified !== 1 && 'd-none'}`}
                                                                        src={verified}
                                                                        width={24}
                                                                        height={24}
                                                                    />
                                                                </div>
                                                                <div className="designation pb-3">{astroDetail?.astro_types}{astroDetail?.experience > 0 && ` with ${astroDetail?.experience} year of experience`}</div>
                                                                <button className="bg-transparent w-100 follow-button ff-manrope fs-16">
                                                                    <LangTrans val="Follow" />
                                                                </button>
                                                            </div>
                                                            <div className="contact-with d-flex justify-content-between">
                                                                <Button
                                                                    className="d-flex justify-content-center chat"
                                                                    onClick={() => communication({ ...astroDetail?.astrologer, clicked: 'chat' })}
                                                                    // onClick={() => {
                                                                    //     if ((astroDetail?.astrologer?.free == 1 && userInfo?.free == 0) && astroDetail?.astrologer?.p_chat) {
                                                                    //         communication({ ...astroDetail?.astrologer, clicked: 'chat' })
                                                                    //     } else {
                                                                    //         // navigate('/home')
                                                                    //         openModal();
                                                                    //     }
                                                                    // }}
                                                                >
                                                                    <div className="icon d-flex align-items-center">
                                                                        <Image
                                                                            src={whiteChatIcon}
                                                                        />
                                                                        <label className="pl-1">
                                                                            <LangTrans val="Chat" />
                                                                        </label>
                                                                    </div>
                                                                    {/* <div className="charge pl-2">₹ {astroDetail?.astrologer?.p_chat ? astroDetail?.astrologer?.p_chat : 'N/A'}/min</div> */}
                                                                    <div className="charge pl-2">{(astroDetail?.astrologer?.free == 1 && userInfo?.free == 0) ? 'Free' : `₹ ${astroDetail?.astrologer?.p_chat}/min`}</div>
                                                                </Button>
                                                                <Modal show={showModal} className="d-flex jutify-content-center align-items-center">
                                                                    <Container className="text-center p-2">
                                                                        <p className="fs-5 font-monospace fw-bold">Your amount should be above {`₹${astroDetail?.astrologer?.p_chat}`}</p>
                                                                        <button onClick={() => setShowModal(!showModal)} className="btn btn-outline-warning">close</button>
                                                                    </Container>
                                                                </Modal>
                                                                <Button
                                                                    className="d-flex justify-content-center call"

                                                                    onClick={() => communication({ ...astroDetail?.astrologer, clicked: 'call' })}
                                                                    // onClick={() => {
                                                                    //     if ((astroDetail?.astrologer?.free == 1 && userInfo?.free == 0) && astroDetail?.astrologer?.p_call) {
                                                                    //         communication({ ...astroDetail?.astrologer, clicked: 'call' })
                                                                    //     } else {
                                                                    //         // navigate('/home')
                                                                    //         openModal1();
                                                                    //     }
                                                                    // }}
                                                                >
                                                                    <div className="icon d-flex align-items-center">
                                                                        <Image
                                                                            src={whiteCallIcon}
                                                                        />
                                                                        <label className="pl-1">
                                                                            <LangTrans val="Call" />
                                                                        </label>
                                                                    </div>
                                                                    {/* <div className="charge pl-2">₹ {astroDetail?.astrologer?.p_call ? astroDetail?.astrologer?.p_call : 'N/A'}/min</div> */}
                                                                    <div className="charge pl-2">{(astroDetail?.astrologer?.free == 1 && userInfo?.free == 0) ? 'Free' : `₹ ${astroDetail?.astrologer?.p_call}/min`}</div>
                                                                </Button>
                                                                <Modal show={showModal1} className="d-flex jutify-content-center align-items-center">
                                                                    <Container className="text-center p-2">
                                                                        <p className="fs-5 font-monospace fw-bold">Your amount should be above {`₹${astroDetail?.astrologer?.p_call}`}</p>
                                                                        <button onClick={() => setShowModal1(!showModal1)} className="btn btn-outline-warning">close</button>
                                                                    </Container>
                                                                </Modal>
                                                            </div>
                                                            <div className="contact-with d-flex justify-content-between">
                                                                <Button
                                                                    className="d-flex justify-content-center bg-transparent border text-grey-900 ff-manrope fs-15 fw-600"
                                                                >
                                                                    <div className="icon d-flex align-items-center">
                                                                        <LangTrans val="Total min chat" /> :
                                                                    </div>
                                                                    <div className="charge pl-2">{Math.round(Number(astroDetail?.astrologer?.total_chat_sec) / 60)} min</div>
                                                                </Button>
                                                                <Button
                                                                    className="d-flex justify-content-center bg-transparent border text-grey-900 ff-manrope fs-15 fw-600"
                                                                >
                                                                    <div className="icon d-flex align-items-center">
                                                                        <LangTrans val="Total min call" /> :
                                                                    </div>
                                                                    <div className="charge pl-2">{Math.round(Number(astroDetail?.astrologer?.total_call_sec) / 60)} min</div>
                                                                </Button>
                                                            </div>
                                                            <div className="language-spoken d-flex flex-column gap-1 w-100">
                                                                <div>
                                                                    <h1 className="ff-manrope fs-18 fw-600 text-grey-900 text-start">
                                                                        <LangTrans val="Languages Spoken" />
                                                                    </h1>
                                                                </div>
                                                                <div className="language-div">
                                                                    {
                                                                        astroDetail?.languages?.map((row) => {
                                                                            return (
                                                                                <div className="language text-grey-900 ff-manrope fs-15 fw-400 text-center">{row?.lang}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="expertise-section d-flex flex-column gap-1 w-100">
                                                                <div>
                                                                    <h1 className="ff-manrope fs-18 fw-600 text-grey-900 text-start">
                                                                        <LangTrans val="Expertise" />
                                                                    </h1>
                                                                </div>
                                                                <div className="expertise-div">
                                                                    {
                                                                        astroDetail?.specifications?.map((row) => {
                                                                            return (
                                                                                <div className="expertise text-grey-900 ff-manrope fs-15 fw-500 text-center d-flex align-items-center justify-content-center">{row?.spec}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="location d-flex flex-column gap-1 w-100">
                                                                <div>
                                                                    <h1 className="ff-manrope fs-18 fw-600 text-grey-900 text-start">
                                                                        <LangTrans val="Location" />
                                                                    </h1>
                                                                </div>
                                                                <div className="location-div">
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <Image
                                                                            src={locationIcon}
                                                                            width={20}
                                                                            height={20}
                                                                        />
                                                                        <label className="fs-16 fw-400 fs-manrope text-grey-900">{`${astroDetail?.astrologer?.city}, ${astroDetail?.astrologer?.state}, ${astroDetail?.astrologer?.country}`}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                            <Card.Body className="why-astro-section mt-4 d-flex flex-column gap-2">
                                                <div className="whyastro-div d-flex w-100 align-items-center">
                                                    <div className="whyastro-image">
                                                        <Image
                                                            src={whyIcon1}
                                                        />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column text-start">
                                                            <label className="fs-17 fw-bold ff-playfair lh-2">
                                                                <LangTrans val="Easily connect with Astro" />
                                                            </label>
                                                            <p className="ff-manrope fs-14 fw-400 mb-0">
                                                                <LangTrans val="You can easily connect with astrologer with the help of AstroGuide." />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="whyastro-div d-flex w-100 align-items-center">
                                                    <div className="whyastro-image">
                                                        <Image
                                                            src={whyIcon2}
                                                        />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column text-start">
                                                            <label className="fs-17 fw-bold ff-playfair lh-2">
                                                                <LangTrans val="Privacy guarantee 100%" />
                                                            </label>
                                                            <p className="ff-manrope fs-14 fw-400 mb-0">
                                                                <LangTrans val="AstroGuide provide 100% privacy guarantee to your personal data." />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="whyastro-div d-flex w-100 align-items-center border-bottom-0 pb-0">
                                                    <div className="whyastro-image">
                                                        <Image
                                                            src={whyIcon3}
                                                        />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column text-start">
                                                            <label className="fs-17 fw-bold ff-playfair lh-2">
                                                                <LangTrans val="Approved Astrologer" />
                                                            </label>
                                                            <p className="ff-manrope fs-14 fw-400 mb-0">
                                                                <LangTrans val="You can talk with 100% verified and approved Astrologer." />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg='8' sm='7' xs='12'>
                                        <Card className="about-section">
                                            <Card.Body className="px-0">
                                                <div className="text-start">
                                                    <h2 className="ff-playfair text-grey-900n fw-bold">
                                                        <LangTrans val="About Astrologer" />
                                                    </h2>
                                                </div>
                                                <div className="description">
                                                    <p className="text-justify fs-17 ff-manrope fw-400 text-grey-500 tt-initial">{astroDetail?.astrologer?.about}</p>
                                                </div>
                                                <div className="images">
                                                    <label className="text-grey-900 fs-20 ff-manrope text-start fw-600">
                                                        <LangTrans val="Astro Gallery" />
                                                    </label>
                                                    <Slider
                                                        infinite={true}
                                                        speed={1000}
                                                        slidesToShow={5}
                                                        slidesToScroll={1}
                                                        dots={false}
                                                    >
                                                        {
                                                            astroDetail?.galleries?.map((row) => {
                                                                return (
                                                                    <div className="w-100 p-1 astro-gallery-image">
                                                                        <div>
                                                                            <Image
                                                                                src={row?.imageFullUrl}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                                <div className="rating d-flex flex-column">
                                                    <label className="text-grey-900 fs-18 ff-manrope text-start fw-600">
                                                        <LangTrans val="Ratings" />
                                                    </label>
                                                    <div className="">
                                                        <Row className="justify-content-center">
                                                            <Col lg='3' sm='5' xs='12' className="mb-1">
                                                                <Card className="counting-box h-100">
                                                                    <Card.Body className="d-flex align-items-center justify-content-center py-3">
                                                                        <h1 className="mb-0 fw-600 ff-manrope">{astroDetail?.rating_avg}</h1>
                                                                        <Rating readonly={true} allowFraction={true} initialValue={astroDetail?.rating_avg} SVGstyle={{ width: '30px', height: '30px' }} />
                                                                        <div className="px-2 d-flex align-items-center gap-2">
                                                                            <FontAwesomeIcon icon={faUser} className="primary-color" />
                                                                            <label className="text-grey-500 ff-manrope fw-600 fs-16">
                                                                                {astroDetail?.total_rating}
                                                                                <LangTrans val="Reviews" />
                                                                            </label>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                            <Col lg='9' sm='7' xs='12' className="mb-1">
                                                                <Card className="counting-chart">
                                                                    <Card.Body>
                                                                        <div className="d-flex flex-column gap-2 ff-manrope fw-600 fs-16">
                                                                            {
                                                                                astroDetail?.rating_data?.map((val, index) => {
                                                                                    const className = (5 - index) > 1 ? ([5, 4]?.includes(5 - index) ? 'success' : 'warning') : 'danger'
                                                                                    return (
                                                                                        <div className="d-flex w-100 gap-2 align-items-center">
                                                                                            <label className="w-5">{5 - index}</label>
                                                                                            <ProgressBar className="w-85" variant={className} now={val} max={astroDetail?.total_rating} />
                                                                                            <label className="w-15">{val}</label>
                                                                                        </div>)
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className="similar d-flex flex-column">
                                                    <div className="heading d-flex justify-content-between align-items-center">
                                                        <label className="fs-17 ff-manrope fw-600">
                                                            <LangTrans val="Check Similar Consultants" />
                                                        </label>
                                                        <label className="fs-16 ff-manrope fw-600 text-blue-600">
                                                            <div className="pointer" onClick={() => navigate("/astrologers")}>
                                                                <LangTrans val="View all" />
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {/* <div className="d-flex justify-content-start align-items-center w-100"> */}
                                                    <div className="">
                                                        <Row>
                                                            {/* <Slider
                                                            responsive={[
                                                                {
                                                                    breakpoint:1024,
                                                                    settings:{
                                                                        slidesToShow:2,
                                                                        slidesToScroll:1,
                                                                        infinite:true
                                                                    }
                                                                },
                                                                {
                                                                    breakpoint:784,
                                                                    settings:{
                                                                        slidesToShow:1,
                                                                        slidesToScroll:1,
                                                                        infinite:true
                                                                    }
                                                                },
                                                                {
                                                                    breakpoint:600,
                                                                    settings:{
                                                                        slidesToShow:1,
                                                                        slidesToScroll:1,
                                                                        infinite:true
                                                                    }
                                                                }
                                                            ]}
                                                            infinite={true}
                                                            speed={1000}
                                                            slidesToShow={2}
                                                            slidesToScroll={1}
                                                            dots={false}
                                                        > */}
                                                            {
                                                                astroDetail?.similar?.map((row) => {
                                                                    return (
                                                                        <Col lg={3} md={3} sm={4} xs={6}>
                                                                            <div className="astro-card">
                                                                                <Card className="h-100">
                                                                                    <Card.Body className="d-flex flex-column">
                                                                                        <div className="image-division">
                                                                                            <div className="image h-100 cursor" onClick={() => redirectToDetail(row?.id, row?.name)} style={{ backgroundImage: `url(${row?.imageFullUrl})` }}>
                                                                                                <div className="rating-box py-1">
                                                                                                    <div className="rating d-flex justify-content-center align-items-center">
                                                                                                        <Rating readonly={true} initialValue={row?.rating} />
                                                                                                        {/* {
                                                                                                [1, 2, 3, 4, 5]?.map(() => {
                                                                                                    return (
                                                                                                        <Image
                                                                                                            src={starIcon}
                                                                                                            width={18}
                                                                                                            height={18}
                                                                                                        />
                                                                                                    )
                                                                                                })
                                                                                            } */}
                                                                                                    </div>
                                                                                                    <div className="review fs-12 ff-manrope fw-500 text-white">Reviews 6052</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="fs-16 ff-manrope fw-600 text-grey-900 cursor" onClick={() => redirectToDetail(row?.id)}>{row?.name}</div>
                                                                                        <div className="button-group d-flex justify-content-between">
                                                                                            <Button
                                                                                                className="d-flex justify-content-center call"
                                                                                                onClick={() => communication({ ...row, clicked: 'call' })}
                                                                                            >
                                                                                                <Image
                                                                                                    src={callIcon}
                                                                                                />
                                                                                                {/* <label className="pl-1">{(row?.free == 1 || userInfo?.free == 0) ? 'free' : `₹${row?.p_call}/min`}</label> */}
                                                                                                <label className="pl-1">{(row?.free == 1) ? 'free' : `₹${row?.p_call}/min`}</label>
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="d-flex justify-content-center chat"
                                                                                                onClick={() => communication({ ...row, clicked: 'chat' })}
                                                                                            >
                                                                                                <Image
                                                                                                    src={chatIcon}
                                                                                                />
                                                                                                {/* <label className="pl-1">{(row?.free == 1 || userInfo?.free == 0) ? 'free' : `₹${row?.p_chat}/min`}</label> */}
                                                                                                <label className="pl-1">{(row?.free == 1) ? 'free' : `₹${row?.p_chat}/min`}</label>
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                            {/* </Slider> */}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className="user-review d-flex flex-column gap-1 mt-2">
                                                    <div className="heading d-flex justify-content-between align-items-center">
                                                        <label className="fs-17 ff-manrope fw-600">
                                                            <LangTrans val="User Reviews" />
                                                        </label>
                                                    </div>
                                                    <div className="d-flex flex-column gap-2">
                                                        {
                                                            astroDetail?.reviews?.map((row, index) => {
                                                                return (
                                                                    <div className={`review-box align-self-stretch ${(!loadMore && index > 2) && 'd-none'}`}>
                                                                        <div className="w-100 d-flex gap-1">
                                                                            <div className="user-image" style={{ backgroundImage: `url(${row?.imageUserFullUrl})` }}></div>
                                                                            <div className="review-info d-flex flex-column gap-1 w-100 px-1">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="d-flex flex-column gap-0">
                                                                                        <h5 className="ff-manrope fs-18 fw-600 text-grey-900 mb-0 pb-1">{row?.user}</h5>
                                                                                        <div className="d-flex gap-1">
                                                                                            <Rating readonly={true} initialValue={row?.rating} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="icon">
                                                                                        <FontAwesomeIcon icon={faListDots} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="review-msg text-start ff-manrope fs-13 fw-400 text-grey-500">{row?.review}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="load-more d-flex justify-content-center">
                                                            <Button
                                                                className="text-grey-900 ff-manrope fw-600 fs-16"
                                                                onClick={() => setLoadMore(!loadMore)}
                                                            >
                                                                <LangTrans val={loadMore ? 'Hide Some' : 'Load More'} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ) : (
                        <div>
                            <Container className="py-2">
                                <Row>
                                    <Col lg='12' className="p-5">
                                        <div className="d-flex justify-content-center align-items-center p-4 flex-column gap-3">
                                            <Image src={NO_DATA_FOUND} height={200} />
                                            <div className="text-capitalize">
                                                <h3 className="text-grey-500 fw-bold ff-manrope">
                                                    <LangTrans val="No Data Found" />
                                                </h3>
                                            </div>
                                            <div>
                                                <Button
                                                    className="primary-bg-color border-0 fw-600 text-dark"
                                                    onClick={() => navigate('/home')}
                                                >
                                                    Back To Home
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default AstrologerDetail;