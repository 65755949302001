import { faEnvelope, faLocationPin, faMobilePhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant from "../../config/Constant";
import LangTrans from "../../Components/Helper/LangHelper";

const ContactUs = () => {
    const [contactData, setContactData] = useState({})

    const getInformativeData = () => {
        apiCall({method:'get', url:Constant?.apis?.settingsFetch}).then((res) => {
            if(res?.status) setContactData({...res?.data})
        })
    }

    useEffect(() => {
        getInformativeData();
    }, [])

    return (
        <div className="contact-page">
            <div className="bg-white">
                <Container className="py-5 contact-section">
                    <Row>
                        <Col lg={4} md={6} xs={12} className="p-1">
                            <Card className="h-100 my-2">
                                <Card.Body>
                                    <div className="d-flex flex-column gap-2 align-items-center justify-content-between py-2 primary-border h-100">
                                        <div>
                                            <FontAwesomeIcon className="icon" icon={faMobilePhone} size={'3x'} />
                                        </div>
                                        <div className="label text-uppercase fs-18 fw-bold">help center</div>
                                        <div className="fs-17 ff-manrope">+91&nbsp;{contactData?.mobile}</div>
                                        {/* <div className="content">got question? we are here to answer</div> */}
                                        <a className="button" href={`tel:${contactData?.mobile}`}>call now</a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} xs={12} className="p-1">
                            <Card className="h-100 my-2">
                                <Card.Body>
                                    <div className="d-flex flex-column gap-2 align-items-center justify-content-between py-2 primary-border h-100">
                                        <div>
                                            <FontAwesomeIcon className="icon" icon={faEnvelope} size={'3x'} />
                                        </div>
                                        <div className="label text-uppercase fs-18 fw-bold">email</div>
                                        <div className="fs-17 ff-manrope">{contactData?.email}</div>
                                        {/* <div className="content">typical reply time with in a day or two</div> */}
                                        <a className="button" href={`mailto:${contactData?.email?.toLowerCase()}`}>email us</a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} xs={12} className="p-1">
                            <Card className="h-100 my-2">
                                <Card.Body>
                                    <div className="d-flex flex-column gap-1 align-items-center justify-content-between py-2 primary-border h-100">
                                        <div>
                                            <FontAwesomeIcon className="icon" icon={faLocationPin} size={'3x'} />
                                        </div>
                                        <div className="label text-uppercase fs-18 fw-bold">address</div>
                                         <div className="footer-heading">
                                            <label className="fs-5 ff-playfair fw-500">
                                                <LangTrans val="BRYD ASTROGUIDE PRIVATE LIMITED"/>
                                            </label>
                                            <hr className="wd-50"/>
                                        </div>
                                        <div className="content">{contactData?.address}</div>
                                        {/* <a className="button" href={`tel:${contactData?.mobile}`}>location</a> */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default ContactUs;