import { React, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Constant, { storage, userInfo } from "../../config/Constant";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import { useForm } from "react-hook-form";
import { apiCall } from "../../Components/Helper/ApiHelper";
import { toast } from "react-toastify";
import LangTrans from "../../Components/Helper/LangHelper";
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";
import { genderData, maritalStatusData } from "../../data/staticData";

const Communicate = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();
  const [formRelatedData, setFormRelatedData] = useState({});
  const [initiateFormData, setInitiateFormData] = useState({
    existing: "0",
    r_id: -1,
    name: "",
    gender: "",
    dtob: "",
    dob: "",
    tob: "",
    ci_id: "",
    pob: "",
    marital_status: "",
    astro_id: location?.state?.id,
    astro_name: location?.state?.name,
    cstatus: "",
    chat_type: "",
    category: "",
  });

  const getFormRelatedData = async () => {
    let config = {
      method: "POST",
      url: Constant?.apis?.chatCheck,
      maxBodyLength: Infinity,
      headers: Constant?.header,
      data: {
        astro_id: location?.state?.id,
      },
    };
    await axios
      ?.request(config)
      .then((response) => {
        formRelatedData.relations = [
          { label: "SELF", value: -1 },
          ...response?.data?.relations?.map((e) => ({
            label: e?.name,
            value: e?.id,
          })),
        ];
        formRelatedData.cities = response?.data?.cities?.map((e) => ({
          label: `${e?.name}, ${e?.state}, ${e?.country}`,
          value: e?.id,
        }));
        formRelatedData.kundlis = response?.data?.kundlis?.map((e) => ({
          ...e,
          label: e?.name,
          value: e?.id,
        }));
        formRelatedData.sess_id = response?.data?.sess_id;
        if (response?.data?.sess_id > 0) {
          initiateFormData.sess_id = response?.data?.sess_id;
          setInitiateFormData({ ...initiateFormData });
        }
        if (formRelatedData.kundlis?.length > 0) intakFormExstingChange(1);
        setFormRelatedData({ ...response?.data, ...formRelatedData });
      })
      .catch((error) => console.log({ status: false, data: "faild" }));
  };

  // function for change the existing status start -----
  const intakFormExstingChange = (existing = 0) => {
    initiateFormData.existing = parseInt(existing);
    if (initiateFormData?.existing > 0 && formRelatedData.kundlis?.length > 0) {
      const kundliData = formRelatedData.kundlis[0];
      setInitiateFormData({
        existing: 1,
        r_id: kundliData?.type == "SELF" ? -1 : kundliData?.r_id,
        k_id: kundliData?.id,
        name: kundliData?.name,
        gender: kundliData?.gender,
        dtob: moment(kundliData?.dob)?.format("YYYY-MM-DD HH:mm:ss"),
        dob: moment(kundliData?.dob)?.format("YYYY-MM-DD"),
        tob: moment(kundliData?.dob)?.format("HH:mm"),
        ci_id: kundliData?.ci_id,
        marital_status: kundliData?.marital_status,
        astro_id: location?.state?.id,
        astro_name: location?.state?.name,
        astro_image: location?.state?.imageFullUrl,
        cstatus: "",
        chat_type: location?.state?.free == 1 ? "PAID" : "FREE",
        category: location?.state?.clicked == "chat" ? "CHAT" : "CALL",
      });
    } else {
      setInitiateFormData((prev) => ({
        existing: initiateFormData?.existing,
        r_id: -1,
        name: "",
        gender: "",
        dtob: "",
        dob: "",
        tob: "",
        ci_id: "",
        pob: "",
        marital_status: "",
        astro_id: location?.state?.id,
        astro_name: location?.state?.name,
        astro_image: location?.state?.imageFullUrl,
        cstatus: "",
        chat_type: location?.state?.free == 1 ? "PAID" : "FREE",
        category: location?.state?.clicked == "chat" ? "CHAT" : "CALL",
      }));
    }
  };
  // function for change the existing status close -----

  // function for set value in form data start
  const changeFormValue = ({ key = "", value = "" }) => {
    if (key?.length > 0) {
      initiateFormData[key] = value;
      setInitiateFormData({ ...initiateFormData });
    }
  };
  // function for set value in form data close

  // function for submit for data start -----
  const initiateFormSubmit = () => {
    const body = {
      ...initiateFormData,
      dtob: `${initiateFormData?.dob} ${initiateFormData?.tob}`,
      dob: moment(initiateFormData?.dob)?.format("DD MMM, YYYY"),
      tob: moment(`${initiateFormData?.dob} ${initiateFormData?.tob}`)?.format(
        "hh:mm A"
      ),
      pob: formRelatedData?.cities?.filter(
        (e) => e?.value == initiateFormData?.ci_id
      )[0]?.label,
      chat_type: location?.state?.free ? "FREE" : "PAID",
      cstatus: formRelatedData?.sess_id > 0 ? "old" : "fresh",
      category: location?.state?.clicked == "chat" ? "CHAT" : "CALL",
    };
    delete body?.astro_image;

    body.existing = Number(body?.existing);
    if (formRelatedData?.sess_id > 0) {
      body.sess_id = formRelatedData?.sess_id;
    }
    apiCall({
      url:
        body?.category == "CHAT"
          ? Constant?.apis?.chatInitiate
          : Constant?.apis?.callInitiate,
      body,
    })?.then((res) => {
      if (body?.chat_type == "FREE" && userInfo?.free == "0") {
        location.state.availableSecond = 600;
      }
      if (!res?.status) toast?.warn(res?.message ?? "Something is wrong")

      console.log('Response of communicate :: ', {
        ...location?.state,
        ...initiateFormData,
        ...res,
        type: "REQUESTED",
        action: "REQUESTING",
        chat_type: body?.chat_type,
        cstatus: body?.cstatus,
        category: "CHAT",
      })
      localStorage.removeItem(storage?.communicate)
      // return false;
      if (res?.status) {
        if (body?.category === "CHAT") {
          localStorage.removeItem(storage?.activeAction);
          localStorage.removeItem(storage?.chatStartSrv);
          localStorage.removeItem(storage?.chatStart);
          localStorage.setItem(storage?.communicate, JSON.stringify({
            ...location?.state,
            ...initiateFormData,
            ...res,
            type: "REQUESTED",
            action: "REQUESTING",
            chat_type: body?.chat_type,
            cstatus: body?.cstatus,
            category: "CHAT",
            fromNotification:1
          }))
          navigate("/chat");
        }

        if (body?.category === "CALL") {
          localStorage?.removeItem(storage?.callStart)
          localStorage?.removeItem(storage?.callStatus)
          localStorage?.setItem(storage?.callStart, moment()?.add("1", "second"));
          localStorage?.setItem(storage?.callingClock, moment()?.add("1", "second"));
          console.log('my side response :: ', {
            ...location?.state,
            ...initiateFormData,
            ...res,
            type: res?.session_history?.status,
            action: res?.session_history?.status,
            chat_type: body?.chat_type,
            cstatus: body?.cstatus,
            category: "CALL",
          })
          localStorage.setItem(storage?.communicate, JSON.stringify({
            ...location?.state,
            ...initiateFormData,
            ...res,
            type: res?.session_history?.status,
            action: res?.session_history?.status,
            chat_type: body?.chat_type,
            cstatus: body?.cstatus,
            category: "CALL",
          }))

          setTimeout(() => {
            navigate("/videocall");
          }, 1000);
        }
      }
    });
  };
  // function for submit for data close -----

  useEffect(() => {
    if (userInfo?.token?.length <= 0) {
      toast.warn('login required for communicate')
      navigate('/home')
    } else {
      getFormRelatedData();
    }
  }, []);
  
  return (
    <div>
      <Container className="py-4">
          <Row className="justify-content-center">
            <Col lg="8">
              <Card className="text-capitalize">
                {
                    userInfo?.name?.length > 0 ?
                    (
                        <>
                            <Card.Header className="ff-manrope fw-600 text-dark fs-16 primary-bg-color">
                                chat initiate form
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(initiateFormSubmit)}>
                                    <Row>
                                    <Col lg="12">
                                        <div className="d-flex justify-content-start gap-3 my-2">
                                        {["existing", "non-existing"]?.map((row, index) => {
                                            return (
                                            <Form.Check
                                                type="radio"
                                                id={`type-${index}`}
                                                checked={
                                                initiateFormData?.existing ==
                                                (index < 1 ? 1 : 0)
                                                }
                                                label={row}
                                                value={index < 1 ? 1 : 0}
                                                onChange={(e) =>
                                                intakFormExstingChange(index < 1 ? 1 : 0)
                                                }
                                                className="d-flex align-items-center justify-content-center gap-1"
                                            />
                                            );
                                        })}
                                        </div>
                                    </Col>
                                    {initiateFormData?.existing > 0 && (
                                        <>
                                        <Col lg="6">
                                            <Form.Group className="mb-3">
                                            <Form.Label className="fs-14 mb-0">
                                                kundli
                                            </Form.Label>
                                            <Select
                                                value={formRelatedData?.kundlis?.filter(
                                                (e) => e?.value == initiateFormData?.k_id
                                                )}
                                                options={formRelatedData?.kundlis}
                                                required
                                                onChange={(e) =>
                                                changeFormValue({
                                                    key: "k_id",
                                                    value: e?.value,
                                                })
                                                }
                                                className="rounded fs-15"
                                            />
                                            </Form.Group>
                                        </Col>
                                        </>
                                    )}
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">
                                            relation
                                        </Form.Label>
                                        <Select
                                            value={formRelatedData?.relations?.filter(
                                            (e) => e?.value == initiateFormData?.r_id
                                            )}
                                            options={formRelatedData?.relations}
                                            required
                                            onChange={(e) =>
                                            changeFormValue({ key: "r_id", value: e?.value })
                                            }
                                            className="rounded fs-15"
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">name</Form.Label>
                                        <Form.Control
                                            className="rounded-1 ff-manrope"
                                            type="text"
                                            required
                                            value={initiateFormData?.name}
                                            onChange={(e) =>
                                            changeFormValue({
                                                key: "name",
                                                value: e?.target?.value,
                                            })
                                            }
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">
                                            marital status
                                        </Form.Label>
                                        <Select
                                            value={maritalStatusData?.filter(
                                            (e) =>
                                                e?.value == initiateFormData?.marital_status
                                            )}
                                            options={maritalStatusData}
                                            required
                                            onChange={(e) =>
                                            changeFormValue({
                                                key: "marital_status",
                                                value: e?.value,
                                            })
                                            }
                                            className="rounded fs-15"
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">gender</Form.Label>
                                        <Select
                                            value={genderData?.filter(
                                            (e) => e?.value == initiateFormData?.gender
                                            )}
                                            options={genderData}
                                            required
                                            onChange={(e) =>
                                            changeFormValue({
                                                key: "gender",
                                                value: e?.value,
                                            })
                                            }
                                            className="rounded fs-15"
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">
                                            date of birth
                                        </Form.Label>
                                        <Form.Control
                                            className="rounded-1"
                                            type="date"
                                            name="dob"
                                            required
                                            value={moment(initiateFormData?.dob)?.format(
                                            "YYYY-MM-DD"
                                            )}
                                            onChange={(e) =>
                                            changeFormValue({
                                                key: "dob",
                                                value: moment(e?.target?.value)?.format(
                                                "YYYY-MM-DD"
                                                ),
                                            })
                                            }
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">
                                            time of birth
                                        </Form.Label>
                                        <Form.Control
                                            className="rounded-1"
                                            type="time"
                                            required
                                            value={moment(
                                            `1991-01-01 ${initiateFormData?.tob}`
                                            )?.format("HH:mm")}
                                            onChange={(e) =>
                                            changeFormValue({
                                                key: "tob",
                                                value: e?.target?.value,
                                            })
                                            }
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                        <Form.Group className="mb-3">
                                        <Form.Label className="fs-14 mb-0">
                                            city of birth
                                        </Form.Label>
                                        <Select
                                            value={formRelatedData?.cities?.filter(
                                            (e) => e?.value == initiateFormData?.ci_id
                                            )}
                                            options={formRelatedData?.cities}
                                            required
                                            onChange={(e) =>
                                            changeFormValue({ key: "ci_id", value: e?.value })
                                            }
                                            className="rounded fs-15"
                                        />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="d-flex justify-content-center py-2">
                                        <Button
                                            className="primary-bg-color rounded-5 border-0 text-dark fw-600 w-40 text-capitalize py-2"
                                            type="submit"
                                        >
                                            Start {initiateFormData?.category} with{" "}
                                            {initiateFormData?.astro_name}
                                        </Button>
                                        </div>
                                    </Col>
                                    </Row>
                                </Form>    
                            </Card.Body>
                        </>
                    ) : (
                        <Row className="d-flex justify-content-center align-items-center p-5">
                            <Col lg="12" className="text-center p-5" >
                            <Button
                                className="rounded-5 px-5 primary-bg-color primary-border-color text-dark ff-manrope fw-18"
                                onClick={() => dispatch(userLoginModel(true))}
                            >
                                <LangTrans val="Login" />
                            </Button>
                            </Col>
                        </Row>
                    )
                }
              </Card>
            </Col>
          </Row>
      </Container>
    </div>
  );
};
export default Communicate;
