import { Route, Routes } from 'react-router';
import './App.css';
import { routes } from './routes';
import { useEffect } from 'react';
import ScrollToTop from './Components/Navigation/ScrollToTop';

// import { Provider } from 'react-redux';
// import store from './redux/store/store';


function App() {

	useEffect(() => {
		document.title = 'Astroguide';
	}, [])
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route>
				{ routes?.map(({ path, element }) => <Route path={path} element={element} />) }
				</Route>
			</Routes>
		</>
		// // <Provider store={store} >
		// 	<div className="App">
		// 		<BrowserRouter basename='/'>
		// 			<Routes>
		// 				<Route path='/' element={<Home />}/>
		// 				<Route path='/home' element={<Home />}/>
		// 				<Route path='/astrologers' element={<Astrologers />}/>
		// 				<Route path='/astrologer-detail' element={<AstrologerDetail />}/>
		// 				<Route path='/history' element={<History />}/>
		// 				<Route path='/recharge' element={<Recharge />}/>
		// 			</Routes>
		// 			{/* <Header /> */}
		// 		</BrowserRouter>
		// 	</div>
		// // </Provider>
	);
}

export default App;
