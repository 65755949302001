import { Card, Container, Row, Col, Button, Image, Table, Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant from "../../config/Constant";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const FreeKundliReport = () => {
    const location = useLocation();
    const kundliId = location?.state?.id;    
    const [currentTopTab, setCurrentTopTab] = useState('basic')
    const topTabData = ['basic', 'charts', 'kp', 'dasha']
    const chartData = {
        birth:['D1-birth'],
        navamsa:['D9-navmasa'],
        divisional:['chalit-Chalit', 'SUN-Sun', 'MOON-Moon', 'D2-Hora', 'D3-Drekkana', 'D4-Chaturthamsa', 'D7-Saptamsa', 'D10-Dasamsa', 'D12-Dwadasamsa', 'D16-Shodasamsa', 'D20-Vimsamsa', 'D24-Chaturvisamsa', 'D30-Trimsamsa', 'D40-Khavedamsa', 'D45-Akshavedamsa', 'D60-Shastiamsa']
    }
    const [chartImageData, setChartImageData] = useState({})
    const [kundliReportData, setKundliReportData] = useState({})
    const [currentChart, setCurrentChart] = useState('birth')
    const [currentChartName, setCurrentChartName] = useState('D1')
    const [loader, setLoader] = useState(true);

    // function for get the kundli basic report data start -----
    const getKundliBasicReportData = () => {
        const body = {
            timezone:'5.5',
            id:kundliId
        }
        console.log('body :: ', body, Constant?.header)
        apiCall({url:Constant?.apis?.horoscopeBasicDetails, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                kundliReportData.basic = res?.data
                console.log('basic :: ', kundliReportData?.basic)
                setKundliReportData({...kundliReportData})
            }
        })

        apiCall({url:Constant?.apis?.horoscopePlanet, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                kundliReportData.planets = res?.data?.planets
                console.log('planets :: ', kundliReportData?.planets)
                setKundliReportData({...kundliReportData})
            }
        })

        apiCall({url:Constant?.apis?.horoscopeKpPlanet, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                kundliReportData.kp = res?.data?.table_data
                console.log('kp :: ', kundliReportData?.kp)
                setKundliReportData({...kundliReportData})
            }
        })
        apiCall({url:Constant?.apis?.horoscopeVimdasha, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                kundliReportData.vimdasha = res?.data?.maha_dasha
                console.log('basic :: ', kundliReportData?.vimdasha)
                setKundliReportData({...kundliReportData})
            }
        })
        apiCall({url:Constant?.apis?.horoscopeYogini, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                kundliReportData.yogini = res?.data?.maha_dasha
                console.log('basic :: ', kundliReportData?.yogini)
                setKundliReportData({...kundliReportData})
            }
        })
    }
    // function for get the kundli basic report data close -----

    // function for get the kundli chart report data start -----
    const getKundliChartReportData = () => {
        const body = {
            timezone:'5.5',
            id:kundliId
        }
        Object?.keys(chartData)?.map((val) => {
            chartData[val]?.map((row) => {
                const rSplit = row?.split('-')
                console.log('chart body :: ', {...body, chart:rSplit[0]})
                apiCall({url:Constant?.apis?.horoscopeCharts, body:{...body, chart:rSplit[0]}, isLogin:true})?.then((res) => {
                    console.log(`-->${rSplit[0]}   ::  `, res)
                    if (res?.status) {
                        chartImageData[rSplit[0]] = res?.data?.base64_image
                        console.log(`${rSplit[0]}   ::  `, chartImageData[rSplit[0]])
                        setChartImageData({...chartImageData})
                    }
                })
            })
        })
    }
    // function for get the kundli chart report data close -----

    // function for change the chart image start -----
    const changeChartData = (row = 'birth', index = 0) => {
        const chartId = chartData[row][index]?.split('-')
        setCurrentChart(row)
        setCurrentChartName(chartId[0])
    }
    // function for change the chart image close -----
    
    useEffect(() => {
        getKundliBasicReportData()
        getKundliChartReportData()

        setTimeout(() => {
            setLoader(!loader)
        }, 2000)
    }, [])

    console.log('chartImageData     ::  ', chartImageData)
    console.log('kundliReportData   ::  ', kundliReportData)

    return (
        <div className="freekundlireport-page py-4">
            <Container>
                <Row className="gap-2">
                    <Col lg='12'>
                        <div className="d-flex gap-2 align-items-center justify-content-center top-tabs">
                        {
                            topTabData?.map((row) => {
                                return (
                                    <Button
                                        className={`tab-button ${currentTopTab == row ? 'clicked' : ''}`}
                                        onClick={() => {
                                            if(!loader) setCurrentTopTab(row)
                                        }}
                                    >
                                        {row}
                                    </Button>
                                )
                            })
                        }
                        </div>
                    </Col>
                {
                    !loader ? (
                        <Col lg='12'>
                        {
                            (currentTopTab == 'basic') && (
                                <Row>
                                    <Col lg='6'>
                                        <Card>
                                            <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">Basic Details</Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="d-flex flex-column">
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">name</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.full_name}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">date</span>
                                                        <span className="w-50 px-1">{moment(kundliReportData?.basic?.date)?.format('DD, MMM YYYY')}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">time</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.hour}:{kundliReportData?.basic?.minute}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">place</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.place}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">latitude</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.latitude}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">longitude</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.longitude}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">timezone</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.timezone}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">sunrise</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.sunrise}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">sunset</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.sunset}</span>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg='6'>
                                        <Card className="mb-2">
                                            <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">Panchange Details</Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="d-flex flex-column">
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">tithi</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.tithi}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">nakshatra</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.nakshatra}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">sunrise</span>
                                                        <span className="w-50 px-1">{moment(kundliReportData?.basic?.sunrise)?.format('HH:mm:ss A')}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">sunset</span>
                                                        <span className="w-50 px-1">{moment(kundliReportData?.basic?.sunset)?.format('HH:mm:ss A')}</span>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">Avakhada Details</Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="d-flex flex-column">
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">varna</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.varna}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">vashya</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.vashya}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">yoni</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.yoni}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">gan</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.gana}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">nadi</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.nadi}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">sunsign</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.sunsign}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">moonsign</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.moonsign}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">tithi</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.tithi}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">tatva</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.tatva}</span>
                                                    </div>
                                                    <div className="list-div px-3 py-1 w-100 d-flex align-items-center fs-14">
                                                        <span className="fw-500 text-capitalize w-50">name alphabet</span>
                                                        <span className="w-50 px-1">{kundliReportData?.basic?.rashi_akshar}</span>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            (currentTopTab == 'charts') && (
                                <>
                                    <Row className="mb-2 chart-section">
                                        <Col lg='12'>
                                            <Nav className="w-100 justify-content-center ff-manrope fs-15 text-capitalize top-nav">
                                            {
                                                Object?.keys(chartData).map((row, key) => {
                                                    return (
                                                        <Nav.Item className="border-bottom px-3" onClick={() => changeChartData(row)}>
                                                            <Nav.Link eventKey={key} active={row === currentChart} className="text-grey-500">{row}</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                })
                                            }
                                            </Nav>
                                        </Col>
                                    </Row>
                                    {
                                        currentChart === 'divisional' && (
                                            <Row className="mb-3 bottom-nav">
                                                <Col lg='12'>
                                                    <div className="w-100 ff-manrope fs-14 text-capitalize top-nav d-flex overflow-auto gap-3">
                                                    {
                                                        chartData?.divisional?.map((row, key) => {
                                                            const val = row?.split('-')
                                                            return (
                                                                <div onClick={() => changeChartData('divisional', key)} className={`px-3 py-1 border rounded-5 cursor ${val[0] == currentChartName ? 'primary-bg-color' : ''}`} key={key}>
                                                                    <div className="text-grey-500">{val[1]}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    <Row>
                                        <Col lg='4'>
                                            <Card>
                                                <Card.Body className="text-center">
                                                    <Image
                                                        src={chartImageData[currentChartName]}
                                                        style={{width:'100%'}}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg='8'>
                                            <Row>
                                                <Col lg='6' sm='12'>
                                                    <Card>
                                                        <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">Sign</Card.Header>
                                                        <Card.Body className="px-0 p-0">
                                                            <Table className="fs-13 ff-manrope">
                                                                <thead className="text-capitalize">
                                                                    <tr>
                                                                        <th>planet</th>
                                                                        <th>sign</th>
                                                                        <th>sign lord</th>
                                                                        <th>degree</th>
                                                                        <th>house</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    kundliReportData?.planets?.map((row, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{row?.name}</td>
                                                                                <td>{row?.sign}</td>
                                                                                <td>{row?.rashi_lord}</td>
                                                                                <td>{row?.full_degree}</td>
                                                                                <td>{row?.house}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                </tbody>
                                                            </Table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg='6' sm='12'>
                                                    <Card>
                                                        <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">Nakshatra</Card.Header>
                                                        <Card.Body className="px-0 p-0">
                                                            <Table className="fs-13 ff-manrope">
                                                                <thead className="text-capitalize">
                                                                    <tr>
                                                                        <th>planet</th>
                                                                        <th>nakshatra</th>
                                                                        <th>naksh lord</th>
                                                                        <th>house</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    kundliReportData?.planets?.map((row, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{row?.name}</td>
                                                                                <td>{row?.nakshatra}</td>
                                                                                <td>{row?.nakshatra_lord}</td>
                                                                                <td>{row?.house}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                </tbody>
                                                            </Table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                        {
                            (currentTopTab == 'kp') && (
                                <Row className="justify-content-center">
                                    <Col lg='6' sm='12'>
                                        <Card>
                                            <Card.Body className="px-0 p-0">
                                                <Table className="fs-13 ff-manrope">
                                                    <thead className="text-capitalize">
                                                        <tr>
                                                            <th className="bg-primary-600">planet</th>
                                                            <th className="bg-primary-600">cups</th>
                                                            <th className="bg-primary-600">sign</th>
                                                            <th className="bg-primary-600">sign lord</th>
                                                            <th className="bg-primary-600">star lord</th>
                                                            <th className="bg-primary-600">sub lord</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        kundliReportData?.kp?.map((row, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{row?.planet}</td>
                                                                    <td>{row?.planet_in}</td>
                                                                    <td></td>
                                                                    <td>{row?.nakshatra_lord?.substring(0,2)}</td>
                                                                    <td>{row?.sub_sub_lord?.substring(0,2)}</td>
                                                                    <td>{row?.sub_lord?.substring(0,2)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            (currentTopTab == 'dasha') && (
                                <Row className="text-capitalize">
                                    <Col lg='6' sm='12'>
                                        <Card>
                                            <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">vimshottari(mahadasha)</Card.Header>
                                            <Card.Body className="px-0 p-0">
                                                <Table className="fs-13 ff-manrope">
                                                    <thead className="text-capitalize">
                                                        <tr>
                                                            <th>planet</th>
                                                            <th>start date</th>
                                                            <th>end date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (Object?.keys(kundliReportData?.vimdasha)?.length > 0) && Object?.keys(kundliReportData?.vimdasha)?.map((key) => {
                                                            return (
                                                                <tr>
                                                                    <td>{key}</td>
                                                                    <td>{kundliReportData?.vimdasha[key]?.start_date}</td>
                                                                    <td>{kundliReportData?.vimdasha[key]?.end_date}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg='6' sm='12'>
                                        <Card>
                                            <Card.Header className="bg-primary-600 ff-poppins fs-15 fw-600">yogini</Card.Header>
                                            <Card.Body className="px-0 p-0">
                                                <Table className="fs-13 ff-manrope">
                                                    <thead className="text-capitalize">
                                                        <tr>
                                                            <th>planet</th>
                                                            <th>start date</th>
                                                            <th>end date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (Object?.keys(kundliReportData?.yogini)?.length > 0) && Object?.keys(kundliReportData?.yogini)?.map((key) => {
                                                            return (
                                                                <tr>
                                                                    <td>{key}</td>
                                                                    <td>{kundliReportData?.yogini[key]?.start_date}</td>
                                                                    <td>{kundliReportData?.yogini[key]?.end_date}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                        </Col>
                    ) : (
                        <Col lg='12'>
                            <Card className="w-100 p-5 border-0">
                                <div className="m-5">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <TailSpin
                                            visible={true}
                                            height="90"
                                            width="90"
                                            color="#F7D947"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    )
                }
                </Row>
            </Container>
        </div>
    )
}

export default FreeKundliReport;