import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { userInfo } from "../../config/Constant";
import moment from "moment";
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";
import { useNavigate } from "react-router";
import "./matchkundlireport.css";

const MatchKundli = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [birthDateData, setBirthDateData] = useState([]);
  const [birthMonthData, setBirthMonthData] = useState([]);
  const [birthYearData, setBirthYearData] = useState([]);
  const [birthHourData, setBirthHourData] = useState([]);
  const [birthMinuteData, setBirthMinuteData] = useState([
    {
      label: "00",
      value: "00",
    },
  ]);
  const [birthSecondData, setBirthSecondData] = useState([
    {
      label: "00",
      value: "00",
    },
  ]);

  const [kundliFormData, setKundliFormData] = useState();
  const [relationData, setRelationData] = useState([
    {
      label: "SELF",
      value: -1,
    },
  ]);
  const [cityData, setCityData] = useState([]);
  const [kundliData, setKundliData] = useState([]);
  const [selectedFemaleKundli, setSelectedFemaleKundli] = useState(null);
  const [selectedMaleKundli, setSelectedMaleKundli] = useState(null);

  const createDropDownData = () => {
    for (let index = 1; index <= 60; index++) {
      birthYearData[index - 1] = {
        label: moment()
          ?.subtract(index - 1, "year")
          ?.format("YYYY"),
        value: moment()
          ?.subtract(index - 1, "year")
          ?.format("YYYY"),
      };
      setBirthYearData([...birthYearData]);
      if (index <= 12) {
        birthMonthData[index - 1] = {
          label: moment()
            .month(index - 1)
            .format("MMM"),
          value: index > 9 ? `${index}` : `0${index}`,
        };
        setBirthMonthData([...birthMonthData]);
      }
      if (index <= 31) {
        birthDateData[index - 1] = {
          label: index > 9 ? `${index}` : `0${index}`,
          value: index > 9 ? `${index}` : `0${index}`,
        };
        setBirthDateData([...birthDateData]);
      }
      if (index <= 24) {
        birthHourData[index - 1] = {
          label: index > 9 ? `${index}` : `0${index}`,
          value: index > 9 ? `${index}` : `0${index}`,
        };
        setBirthHourData([...birthHourData]);
      }
      birthMinuteData[index] = {
        label: index > 9 ? `${index}` : `0${index}`,
        value: index > 9 ? `${index}` : `0${index}`,
      };
      setBirthMinuteData([...birthMinuteData]);
      birthSecondData[index] = {
        label: index > 9 ? `${index}` : `0${index}`,
        value: index > 9 ? `${index}` : `0${index}`,
      };
      setBirthSecondData([...birthSecondData]);
    }
  };

  const getKundliData = () =>
    apiCall({ url: Constant?.apis?.userKundli, method: "get" })?.then((res) =>
      setKundliData([...res?.data])
    );

  const getKundliValuesData = () => {
    apiCall({ url: Constant?.apis?.userKundliValues, method: "get" })?.then(
      (res) => {
        if (res?.status) {
          res?.relations?.map((row, index) => {
            relationData[index + 1] = {
              label: row?.name,
              value: row?.id,
            };
          });
          setRelationData([...relationData]);
        }
      }
    );
  };

  const getCityData = () => {
    apiCall({ url: Constant?.apis?.cityAll, method: "get" })?.then((res) => {
      if (res?.status) {
        setCityData([
          ...res?.data?.map((e) => ({
            label: `${e?.name}, ${e?.state}, ${e?.country}`,
            value: e?.id,
          })),
        ]);
      }
    });
  };

  useEffect(() => {
    createDropDownData();
    getCityData();
    setKundliFormData({
      id: 0,
      r_id: -1,
      name: "",
      gender: "",
      date: "",
      month: "",
      year: "",
      hour: "",
      minute: "",
      second: "",
      dob: "",
      ci_id: "",
      marital_status: "",
    });
    if (userInfo?.token) {
      getKundliValuesData();
      getKundliData();
    }
  }, [load]);

  const handleSelectKundli = (row, gender) => {
    if (gender === "FEMALE") {
      setSelectedFemaleKundli(row);
    } else if (gender === "MALE") {
      setSelectedMaleKundli(row);
    }
  };
  const handleMatchKundli = () => {
    if (!selectedFemaleKundli && !selectedMaleKundli) {
      alert("Please select both a female and a male kundli to match.");
      return;
    }
    if (!selectedFemaleKundli) {
      alert("Please select a female kundli.");
      return;
    }
    if (!selectedMaleKundli) {
      alert("Please select a male kundli.");
      return;
    }
    console.log("Matching Kundlis:", selectedFemaleKundli, selectedMaleKundli);
    navigate("/match-kundli-report", {
      state: {
        selectedFemaleKundli,
        selectedMaleKundli,
      },
    });
  };

  const renderKundliCards = (gender) => {
    const genderKundliData = kundliData.filter((row) => row.gender === gender);
    return (
      <div className="h-100 d-flex justify-content-center">
        <Card className="m-2 w-100">
          {userInfo?.name?.length > 0 ? (
            <>
              <Card.Header className="border-0 py-2 primary-bg-color card-header">
                <span className="fs-19 ff-manrope text-capitalize">
                  My Kundlis {gender.toLowerCase()}
                </span>
              </Card.Header>
              <Card.Body>
                <div className="d-flex flex-column gap-2 p-1">
                  {genderKundliData.length > 0 ? (
                    genderKundliData.map((row, index) => (
                      <div className="d-flex mb-3" key={index}>
                        <Card
                          className={`border w-100 rounded text-capitalize ${
                            selectedFemaleKundli?.id === row.id ||
                            selectedMaleKundli?.id === row.id
                              ? "bg-warning-subtle"
                              : ""
                          }`}
                          onClick={() => handleSelectKundli(row, gender)}
                        >
                          <Card.Header className="bg-transparent border-0 d-flex justify-content-between">
                            <div className="ff-playfair fw-600 fs-13">
                              {row?.name} ({row?.type})
                            </div>
                          </Card.Header>
                          <Card.Body className="py-1 ff-manrope fs-12 textsize">
                            <Row>
                              <Col xs={6} md={6} className="d-flex gap-1">
                                <span className="text-dark">Gender:</span>
                                <span className="fw-bold">{row?.gender}</span>
                              </Col>
                              <Col xs={6} md={6} className="d-flex gap-1">
                                <span className="text-dark">DOB:</span>
                                <span className="fw-bold">
                                  {moment(row?.dob)?.format("DD, MMM YYYY H:m")}
                                </span>
                              </Col>
                              <Col xs={6} md={6} className="d-flex gap-1">
                                <span className="text-dark">City:</span>
                                <span className="fw-bold">{row?.city}</span>
                              </Col>
                              <Col xs={6} md={6} className="d-flex gap-1">
                                <span className="text-dark">Mobile:</span>
                                <span className="fw-bold">{row?.mobile}</span>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <>
                      <p className="d-flex justify-content-center fs-5 fw-bold">
                        No Kundlis here
                      </p>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="border fs-12 bg-transparent px-5 py-2 rounded-3 ff-manrope text-primary text-capitalize text-center"
                          style={{ width: "fit-content" }}
                          onClick={() => {
                            navigate("/free-kundli");
                          }}
                        >
                          Add new kundli
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Card.Body>
            </>
          ) : null}
        </Card>
      </div>
    );
  };

  const isKundliAvailable =
    kundliData.some((row) => row.gender === "FEMALE") &&
    kundliData.some((row) => row.gender === "MALE");

  return (
    <Container className="my-3 d-flex flex-column">
      <Row className="mb-5">
        <Col xs={12} md={6}>
          {renderKundliCards("FEMALE")}
        </Col>
        <Col xs={12} md={6}>
          {renderKundliCards("MALE")}
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        {isKundliAvailable && (
          <Button
            className="text-center primary-bg-color border-0 text-dark fw-bold align-items-center justify-content-center px-5 py-2 mb-3"
            onClick={handleMatchKundli}
          >
            Match Kundali
          </Button>
        )}
      </div>
    </Container>
  );
};

export default MatchKundli;
