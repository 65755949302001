import React from "react";
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import LangTrans from "../Helper/LangHelper";

import { astroServiceData } from "../../data/staticData";

const AstroService = ({heading = ''}) => {

    return (
        <div className="py-5 bg-p-orange-50 astrology-services">
            <Container>
                <Row>
                    <Col lg='12'>
                        <h3 className="text-center fw-600 ff-playfair text-black">
                            <LangTrans val={heading} />
                        </h3>
                    </Col>
                    <Col lg='12'>
                        <Row className="justify-content-center mt-4">
                        {
                            astroServiceData?.map((row, index) => {
                                return (
                                <Col lg='4' md='6' sm='12' xs='12' className="p-3" key={`astro-service-${index}`}>
                                    <Card className="h-100">
                                        <Card.Body className="p-0">
                                            <div className="astro-service-division h-100">
                                                <div className="top-design">
                                                    <div className="top-design-child"></div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="icon-div">
                                                        <Image
                                                            src={require(`../../assets/images/icon_${index + 1}.png`)}
                                                            width={85}
                                                            height={85}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-section mt-5">
                                                    <Row>
                                                        <Col lg='12' className="text-center">
                                                            <h5 className="ff-playfair fw-bold">
                                                                <LangTrans val={row?.name} />
                                                            </h5>
                                                        </Col>
                                                        <Col lg='12' className="text-center">
                                                            <div className="desc">
                                                                <LangTrans val={row?.description} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                )
                            })
                        }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AstroService