import { React, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant from "../../config/Constant";
import HTMLRenderer from 'react-html-renderer'

const Page = () => {
    const [pageData, setPageData] = useState({})
    const path = window?.location?.pathname?.replace('/','');
    console.log('hello', window?.location?.pathname?.replace('/',''));

    const getInformativeData = () => {
        apiCall({method:'get', url:Constant?.apis?.settingsFetch}).then((res) => {
            if(res?.status) setPageData({...res?.data})
        })
    }
    console.log('ss a :: ', pageData)

    useEffect(() => {
        getInformativeData();
        document.title = `${path} : Astroguide`;
    }, [])

    return (
        <div className="success-page">
            <div className="bg-white">
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="page-content pt-3">
                            {
                                (path === 'aboutus') && <HTMLRenderer html={pageData?.about} />
                            }
                            {
                                (path === 'privacy-policy') && <HTMLRenderer html={pageData?.privacy_policy} />
                            }
                            {
                                (path === 'terms-condition') && <HTMLRenderer html={pageData?.tc} />
                            }
                            {
                                (path === 'refund-policy') && <HTMLRenderer html={pageData?.cr_policy} />
                            }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default Page;