import React, { useEffect } from "react";
import successCheck from '../../assets/gif/success_check.gif';
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { storage } from "../../config/Constant";
import { useDispatch } from "react-redux";
import { updateWalletAmount } from "../../Redux/Slices/User/LoginModuleSlice";

const Success = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

useEffect(() => {
    const paymentData = location.state;

    if (paymentData) {
        const id = localStorage.getItem(storage.id);
        const receipt = localStorage.getItem(storage.receipt);
        const payment_id = localStorage.getItem(storage.payment_id);
        
        const body = {
            id: id,
            status: 1, 
            method: "RAZORPAY",
            status_type: "Success", 
            payment_id: payment_id,
            receipt: receipt,
            // wallet: paymentData.wallet_amount
        };

        apiCall({ method: 'post', url: Constant.apis.walletTransactionStatus, body })
            .then((res) => {
                console.log("Wallet status update response:", res);
                if (res?.status) {
                    // Update wallet amount in Redux store
                    if (res.wallet_amount) {
                        dispatch(updateWalletAmount(res.wallet_amount));
                        // Remove items from localStorage
                        localStorage.removeItem(storage.id);
                        localStorage.removeItem(storage.receipt);
                        localStorage.removeItem(storage.payment_id);
                    }
                }
            })
            .catch((error) => {
                console.error("API call failed:", error);
                localStorage.removeItem(storage.id);
                localStorage.removeItem(storage.receipt);
                localStorage.removeItem(storage.payment_id);
            });
    } else {
        console.error("No payment data available");
        // Optionally redirect to an error page or back to recharge page
        // navigate('/recharge');
    }
}, [location, dispatch]);

    return (
        <div className="success-page">
            <div className="bg-white">
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="d-flex align-items-center justify-content-center flex-column p-5 text-capitalize gap-4 m-5">
                                <div>
                                    <Image src={successCheck} width={100} />
                                </div>
                                <div>
                                    <h2 className="text-grey-500 ff-poppins fw-600">recharge successful</h2>
                                </div>
                                <div>
                                    <Button
                                        className="primary-bg-color border-0 fw-600 text-dark"
                                        onClick={() => navigate('/recharge')}
                                    >
                                        Back To Wallet
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Success;