import { createSlice } from "@reduxjs/toolkit";

const LoginModuleSlice = createSlice({
    name:'LoginModule',
    initialState:{
        showLoginModel:false,
        userDetail:{},
        walletAmount:0
    },
    reducers:{
        userLoginModel : (state, action) => {
            console.log('action  ::', state, action)
            state.showLoginModel = action?.payload
        },
        updateWalletAmount: (state, action) => {
            state.walletAmount = action.payload
        }
    },
})

export const { userLoginModel, updateWalletAmount } = LoginModuleSlice.actions;
export default LoginModuleSlice;