import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showLoginModel:false,
    userDetail:{},
    walletAmount:0
};


export const userSlice = createSlice({
    name:"login",
    initialState,
    reducers:{
        userLoginModel : (state, action) => {
            console.log('action  ::', state, action)
            state.showLoginModel = action?.payload
        }
    }
})

export const { userLoginModel } = userSlice.actions;

export default userSlice.reducer;