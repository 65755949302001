import { React } from "react";
import cancelCross from '../../assets/gif/cancel_cross.gif';
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { storage } from "../../config/Constant";
import { useEffect } from "react";

const Failure = () => {
    const navigate = useNavigate()

    useEffect(() => {
        // const id = localStorage?.getItem(storage?.stripeId)
        const id = localStorage.getItem(storage.id);
        const receipt = localStorage.getItem(storage.receipt);
        const payment_id = localStorage.getItem(storage.payment_id);
        const body = {
            status:2,
            id,
            method: "RAZORPAY",
            status_type: "Failure", 
            payment_id: payment_id,
            receipt: receipt,
        }
        if (id > 0) {
            apiCall({method:'post', url:Constant?.apis?.walletTransactionStatus , body})?.then((res) => {
                if (res?.status)  localStorage?.setItem(storage?.stripeId, 0)
            })
            localStorage.removeItem(storage.id);
            localStorage.removeItem(storage.receipt);
            localStorage.removeItem(storage.payment_id);
        }
    }, [])

    return (
        <div className="success-page">
            <div className="bg-white">
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="d-flex align-items-center justify-content-center flex-column p-5 text-capitalize gap-4 m-5">
                                <div>
                                    <Image src={cancelCross} width={100} />
                                </div>
                                <div>
                                    <h2 className="text-grey-500 ff-poppins fw-600">recharge failed</h2>
                                </div>
                                <div>
                                    <Button
                                        className="btn-danger border-0 fw-600 text-dark"
                                        onClick={() => navigate('/recharge')}
                                    >
                                        Try Again
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default Failure;