import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant from "../../config/Constant";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactSpeedometer from "react-d3-speedometer/slim";
import { useLocation } from "react-router-dom";
import "./matchkundlireport.css";
import Loader from "../../Components/Loader/Loader"

const MatchKundliReport = () => {
  const location = useLocation();
  const [kundliData, setKundliData] = useState([]);
  const [compatibilityResult, setCompatibilityResult] = useState(null);
  const [loading, setLoading] = useState(true);
  // const { selectedFemaleKundli, selectedMaleKundli } = location.state || {};

  const getKundliMatchReport = () => {
    const body = {
      p1Name: "raj",
      p1Dob: "1998-07-16",
      p1Gender: "male",
      p1Place: "SURAT",
      p2Name: "riya",
      p2Dob: "1999-03-24",
      p2Gender: "female",
      p2Place: "SURAT",
    };
    apiCall({ url: Constant?.apis?.matchhoroscope, body, isLogin: true })
      .then((res) => {
        if (res?.status) {
          console.log("API Response:", res.data);
          setKundliData(res.data.ashtakoot_milan);
          setCompatibilityResult(res.data.ashtakoot_milan_result);
        }
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  useEffect(() => {
    getKundliMatchReport();
  }, []);

  const generateCardData = () => {
    const colors = ["#F8D7DA", "#CFE2FF", "#D1E7DD", "#FFF3CD"];
    const border = ["danger", "primary", "success", "warning"];
    const titleColors = ["#DC4C64", "#3B71CA", "#14A44D", "#E4A11B"];
    const discription = [
      "Varna refers to the mental compatibility of the two persons involved. It holds nominal effect in the matters of marriage compatibility.",
      "Vashya indicates the bride and the groom's tendency to dominate or influence each other in a marriage.",
      "Tara is the indicator of the birth star compatibility of the bride and the groom. It also indicates the fortune of the couple.",
      "Yoni is the indicator of the sexual or physical compatibility between the bride and the groom in question.",
      "Graha Maitri is the indicator of the intellectual and mental connection between the prospective couple",
      "Gana is the indicator of the behavior, character, and temperament of the potential bride and groom towards each other.",
      "Bhakoota is related to the couple's joys and sorrows together and assesses the wealth and health after their wedding.",
      "Nadi is related to the health compatibility of the couple. Matters of childbirth and progeny are also determined with this Guna.",
    ];
    const cardData = [];
    Object.keys(kundliData).forEach((key, index) => {
      const element = kundliData[key];

      const percentage = element.points_obtained / element.max_ponits;

      const strokeDasharray = `${percentage * 100}, 100`;
      const strokeDashoffset = 100 - percentage * 100;

      cardData.push({
        title: key,
        text: `${element.area_of_life}`,
        bgColor: colors[index % colors.length],
        borderColor: border[index % border.length],
        discription: discription[index % discription.length],
        percentage: `${element.points_obtained}/${element.max_ponits}`,
        titleColor: titleColors[index % titleColors.length],
        obtainedPercentage: percentage,
        strokeDasharray,
        strokeDashoffset,
      });
    });
    return cardData;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <div className="d-flex justify-content-center mt-5 h-100">
            {compatibilityResult && (
              <div className="speedmeter-container">
                <ReactSpeedometer
                  className="speedmeter h-100"
                  value={compatibilityResult.points_obtained}
                  maxValue={compatibilityResult.max_ponits}
                  width={360}
                  height={230}
                  needleColor="black"
                  startColor="red"
                  segments={8}
                  endColor="green"
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            {compatibilityResult && (
              <p className="fw-bold fs-1">
                {compatibilityResult.points_obtained}/
                {compatibilityResult.max_ponits}
              </p>
            )}
          </div>
          <Row className="mt-3">
            {generateCardData().map((card, index) => (
              <Col lg="6" key={index} className="mb-4">
                <Card
                  style={{ backgroundColor: card.bgColor }}
                  className={`h-100 border border-${card.borderColor}`}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-between textkarmic">
                      <Card.Title
                        style={{ color: card.titleColor }}
                        className="text-capitalize"
                      >
                        {card.text} ({card.title.replace(/_/g, " ")})
                      </Card.Title>
                      <div className="col-7">
                        <Card.Text
                          style={{ textAlign: "justify", fontSize: "15px" }}
                          className="textkarmic"
                        >
                          {card.discription}
                        </Card.Text>
                      </div>

                      <div
                        className="col-3 circularprogressbar"
                        style={{ width: 130 }}
                      >
                        <CircularProgressbar
                          value={card.obtainedPercentage}
                          maxValue={1}
                          text={card.percentage}
                          styles={buildStyles({
                            textSize: "16px",
                            textColor: "#000000",
                            pathColor: card.borderColor,
                            trailColor: "#d6d6d6",
                          })}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default MatchKundliReport;
