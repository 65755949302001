import { auth } from "../Firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export const onCaptchVerify = (obj = {}) => {
    if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
        obj?.id,
        {
            size: "invisible",
            callback: (response) => { sentOtp() },
            "expired-callback": () => {},
        },
        auth)
    }
}

export const sentOtp = (obj = {}) => {
    onCaptchVerify(obj)
    const appVerifier = window.recaptchaVerifier;
    const formatPh = obj?.phone ? obj?.phone : '';

    signInWithPhoneNumber(auth, formatPh, appVerifier)
    .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log('confirmationResult :: ', confirmationResult)
    })
    .catch((error) => console.log(error))
}

export const verifyOtp = (obj = {}) => {
    return window?.confirmationResult.confirm(obj?.otp).then(async (res) => {
        return {status:true, data:res}
    }).catch((err) => { return {status:false,data:{}} })
}