import { CSSProperties } from "react";
import { BeatLoader, ClipLoader, RingLoader } from "react-spinners"

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const Loader = () => {

    return (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column bg-white" style={{height:'500px'}}>
            <div>
                <BeatLoader
                    color={'#000000'}
                    loading={true}
                    cssOverride={override}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    )
}

export default Loader