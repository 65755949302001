import { createSlice } from "@reduxjs/toolkit";
import AstrologersActions from "../../Actions/Astrologer/AstrologersActions";

const AstrologersSlice = createSlice({
    name:'Astrologers',
    initialState:{
        astrologersData:null,
        loading:false
    },
    extraReducers: (builder) => {        
        builder.addCase(AstrologersActions.pending,(state, action) => {
            state.loading = true
        }),
        builder.addCase(AstrologersActions.fulfilled,(state, action) => {
            state.astrologersData = action.payload
            state.loading = false
        }),
        builder.addCase(AstrologersActions.rejected,(state, action) => {
            state.loading = false
        })
    }
})

export default AstrologersSlice;