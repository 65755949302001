import { configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "./Slices/Dashboard/DashboardSlice";
import AstrologersSlice from "./Slices/Astrologer/AstrologersSlice";
import LoginModuleSlice from "./Slices/User/LoginModuleSlice";
import CommunicateModuleSlice from "./Slices/Communicate/CommunicateModuleSlice";

const Store = configureStore({
    reducer:{
        LoginReducer:LoginModuleSlice.reducer,
        dashboardReducer:DashboardSlice.reducer,
        astrologersReducer:AstrologersSlice.reducer,
        communicateReducer:CommunicateModuleSlice.reducer
    }
})

export default Store;