import { toast } from "react-toastify"
import Constant, { storage, userInfo } from "../../config/Constant"

// FUNCTION FOR CALL API START
export const apiCall = ({method = 'post', url = '', body = Constant?.body, isLogin = false}) => {

    let config = {
        method,
        url,
        maxBodyLength: Infinity,
        headers: Constant?.header,
        data : body
    }
    return new Promise(async function(resolve, reject) {
        if (isLogin && userInfo?.token === undefined) {
            toast.error('Login Required for this action')
            resolve({status:false, data:'faild'});
            return
        }
        
        await Constant?.axios?.request(config).then((response) => {
            resolve({...response?.data, status:[true, 'Success']?.includes(response?.data?.status)})
        }).catch((error) => {
            if ([403, 401]?.includes(error?.response?.status)) {
                toast.error('session expired')
                localStorage?.removeItem(storage?.login)
                window.location?.reload();
            }
            resolve({status:false, data:'faild'})
        })
    })
}
// FUNCTION FOR CALL API CLOSE

// export const manageWishList = ({status = false, astro_id = 0}) => {
//     apiCall({url:!status ? Constant?.apis?.addWishlist : Constant?.apis?.removeWishlist, body:{astro_id}})?.then((res) => {
//         console?.log('wishlist response :: ', res, {astro_id}, !status ? Constant?.apis?.addWishlist : Constant?.apis?.removeWishlist)
//         if (res?.status) window?.location?.reload();
//     })
// }

export const manageWishList = ({status = false, astro_id = 0}) => {
    console.log('userInfo?.name :: ', userInfo?.name)
    if (typeof(userInfo?.name) == "undefined") {
        alert('Login Required');
        return
    }

    let config = {
        method:'post',
        url:!status ? Constant?.apis?.addWishlist : Constant?.apis?.removeWishlist,
        maxBodyLength: Infinity,
        headers: Constant?.header,
        data : {astro_id}
    }

    return new Promise(async function(resolve, reject) {
        await Constant?.axios?.request(config).then((res) => {
            resolve({...res?.data, status:[true, 'Success']?.includes(res?.data?.status)})
        }).catch((error) => resolve({status:false, data:['ss']}))
    })
}