import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import Constant from "../../config/Constant";
import { faBars, faEarth, faLocation, faMobilePhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiCall } from "../Helper/ApiHelper";
import { useNavigate } from "react-router";
import LangTrans from "../Helper/LangHelper";
import { findBestAstroStaticData } from "../../data/staticData";

const FindAstro = ({heading = '', findData = {}}) => {
    const navigate = useNavigate();
    const [findBestAstroData, setFindBestAstroData] = useState([...findBestAstroStaticData])
    useEffect(() => {
        findBestAstroData[1].data = findData?.city?.map(e => ({label:`Astrologers in ${e?.name}`, value:e?.id}))
        findBestAstroData[2].data = findData?.country?.map(e => ({label:`Astrologers in ${e?.name}`, value:e?.id}))
        findBestAstroData[3].data = findData?.type?.map(e => ({label:e?.type, value:e?.id}))
        setFindBestAstroData([...findBestAstroData])
    }, [findData])
    
    return (
        <div className="py-5 find-astro-section">
            <Container>
                <div className="mb-4">
                    <h3 className="ff-playfair fw-bold">
                        <LangTrans val="Find Best Astrologers" />
                    </h3>
                </div>
                <Row>
                {
                    findBestAstroData?.map((row, index) => {
                        return <Col  lg='3' sm='6' xs='12' key={`find-astro-${index}`}>
                            <div className="p-2 h-100">
                                <Card className="h-100">
                                    <Card.Header>
                                        <label className="d-flex justify-content-start align-items-center mb-0 p-1 fw-500">
                                            <FontAwesomeIcon icon={row?.icon} size={'sm'}/>
                                            <label>
                                                <LangTrans val={row?.label} />
                                            </label>
                                        </label>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <ul className="p-0 mb-0">
                                        {
                                            row?.data?.map((child, c_index) => {
                                                return (
                                                    <li
                                                        className={`text-start p-2 px-3 ${c_index !== (row?.data?.length -1) ? ' border-bottom' :''}`}
                                                        onClick={() => {
                                                            navigate('/astrologers', {
                                                                state:{
                                                                    [row?.name]:child?.value,
                                                                    obj:{ type:index > 0 ? 'checkbox' : 'radio', name:row?.name, value:[child?.value] }
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <label className="fs-14 cursor">{child?.label}</label>
                                                    </li>
                                                )
                                            })   
                                        }
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    })
                }
                </Row>
            </Container>
        </div>
    )
}

export default FindAstro