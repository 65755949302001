import { useState, React, useEffect } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.scss";
import "../../../node_modules/slick-carousel/slick/slick-theme.scss";

import AstroService from "../../Components/Content/AstroService";
import FindAstro from "../../Components/Content/FindAstro";
import WhyAstro from "../../Components/Content/WhyAstro";
import Blogs from "../../Components/Content/Blogs";
import Faq from "../../Components/Content/Faq";

import { useNavigate } from "react-router-dom";
import LangTrans from "../../Components/Helper/LangHelper";
import YouTube from "react-youtube";
import { useDispatch, useSelector } from "react-redux";
import DashboardActions from "../../Redux/Actions/Dashboard/DashboardActions";
import Loader from "../../Components/Loader/Loader";

import astroBarcode from "../../assets/images/astrobarcode.png";
import playStore from "../../assets/images/play_store.png";
import {
  FIREBASE_WEB_PUSH_TOKEN,
  PLAY_STORE,
  storage,
} from "../../config/Constant";

// import static data
import { chatOptionData, astroCountingData } from "../../data/staticData";
import { toast } from "react-toastify";

import { messaging } from "../../config/FireBase/Firebase.config";
import { getToken } from "firebase/messaging";

const Home = () => {
  const dispatch = useDispatch();
  const { dashboardData, loading } = useSelector(
    (state) => state.dashboardReducer
  );

  const navigate = useNavigate();
  // define state close

  const redirectToDetail = (id = 0) => {
    navigate("/astrologer-detail", {
      state: { id },
    });
  };

  const handleImageClick = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };

  // get notification permission start --
  const requestForNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging, {
        vapidKey: FIREBASE_WEB_PUSH_TOKEN,
      });
      localStorage?.setItem(storage?.systemToken, token);
      console.log("Token Gen", token);
    } else if (permission === "denied") {
      toast.warn("You denied for the notification");
    }
  };
  // get notification permission close --

  useEffect(() => {
    requestForNotificationPermission();
    dispatch(DashboardActions());
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="home-page">
          <Container
            className={`pt-5 ${
              dashboardData?.banners?.length <= 0 && "d-none"
            }`}
          >
            <Slider
              autoplay={true}
              infinite={true}
              speed={1000}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {dashboardData?.banners?.length > 0 &&
                dashboardData?.banners?.map((row) => {
                  return (
                    <Card className="border-0">
                      <Card.Img src={row.imageFullUrl} alt="Card image" />
                      <Card.ImgOverlay className="p-4 row align-items-center d-none">
                        <Row>
                          <Col lg="6" sm="6">
                            <div className="p-3 text-white">
                              <h5 className="px-2 mb-2 ff-manrope">
                                Most of the celebrity recommends Astroguide.
                                Even the Greatactor Amitabji Suggest Astroguide.
                              </h5>
                            </div>
                            <div className="fs-2 fw-bold text-warning ff-manrope">
                              <LangTrans val="Chat with Astrologer" />
                            </div>
                            <div className="p-3">
                              <Button className="bg-transparent rounded-3 text-white text-capitalize px-4 border-white">
                                <LangTrans val="Chat Now" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.ImgOverlay>
                    </Card>
                  );
                })}
            </Slider>
          </Container>
          <Container className="py-5 chat-option-section">
            <Row>
              <Col lg="12">
                <Card className="border-0">
                  <Row>
                    <Col lg="5" md="5" sm="12">
                      <Card className="gradientqr border-0 w-100 p-3">
                        <div className="w-100 d-flex justify-content-center">
                          <Image
                            className="img-thumbnail"
                            src={astroBarcode}
                            style={{ height: "275px" }}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col lg="7" md="7" sm="12">
                      <Card className=" h-100 border-0 p-3">
                        <div className="h-100 flex-column d-flex justify-content-center align-items-center gap-3">
                          <h2 className="ff-playfair">
                            Unlock the Mysteries of the Cosmos: AstroGuide
                            Invites Astrologers to scan QR code and join us!
                          </h2>
                          <div>
                            <a href={PLAY_STORE} target="_blank">
                              <Image src={playStore} width="150" />
                            </a>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container className="py-5 chat-option-section">
            <Row>
              {chatOptionData?.length > 0 &&
                chatOptionData?.map((row, index) => {
                  return (
                    <Col lg="4" key={`chat-option-${index}`}>
                      <div className="p-2">
                        <Card>
                          <Card.Body
                            className="p-4 cursor"
                            onClick={() => {
                              navigate("/astrologers", {
                                state: { from: row?.id == 3 ? "live" : "" },
                              });
                            }}
                          >
                            <Card.Title>
                              <div className="d-flex justify-content-center">
                                <button
                                  className={`bg-${row?.bg_class} border-0 rounded-5 text-center text-white`}
                                  style={{ height: "55px", width: "55px" }}
                                >
                                  <FontAwesomeIcon icon={row?.icon} />
                                </button>
                              </div>
                            </Card.Title>
                            <Card.Subtitle className="mb-2 fs-4 text-black mb-0 mt-3 ff-manrope text-center">
                              <LangTrans val={row?.label} />
                            </Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Container>
          <div>
            <AstroService heading="AstroGuide Astrology Services" />
          </div>
          {/* }
                    <div className="bg-white py-5 astro-count-section">
                        <Container>
                            <Row>
                            {
                                astroCountingData?.length > 0 && astroCountingData?.map((row, index) => {
                                    return <Col lg='4' key={`astro-count-${index}`}>
                                        <div className="p-2 h-100">
                                            <Card className="rounded-2 h-100">
                                                <Card.Body className="p-0 d-flex justify-content-between wd-100">
                                                    <div className="wd-25-half start"></div>
                                                    <div className="wd-75 p-4 d-flex align-items-center flex-column">
                                                        <div>
                                                            <h4 className="ff-manrope fw-bold">{row?.value}</h4>
                                                            <h6 className="ff-manrope">
                                                                <LangTrans val={row?.label} />
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="wd-25-half end"></div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                })
                            }
                            </Row>
                        </Container>
                    </div>
                    { */}
          <div>
            <FindAstro
              findData={{
                type: dashboardData?.types,
                city: dashboardData?.indian,
                country: dashboardData?.international,
              }}
            />
          </div>
          <div
            className={`bg-p-orange-50 py-5 live-astro-section ${
              dashboardData?.live_astrologers?.length <= 0 && "d-none"
            }`}
          >
            <Container>
              <Row>
                <Col lg="12">
                  <h2 className="text-start ff-playfair fw-bold fw-700">
                    <LangTrans val={"Online Astrologers"} />
                  </h2>
                  <h6 className="text-start ff-manrope fw-400 text-grey-500">
                    <LangTrans val="AstroGuide shows the online astrologer whom with you can connect." />
                  </h6>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Slider
                    autoplay={true}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 5,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 784,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                    ]}
                    infinite={false}
                    speed={1000}
                    slidesToShow={6}
                    slidesToScroll={1}
                  >
                    {dashboardData?.live_astrologers?.length > 0 &&
                      dashboardData?.live_astrologers?.map((row) => {
                        return (
                          <Card className="bg-transparent border-0 p-2">
                            <Card.Body
                              onClick={() => redirectToDetail(row?.id)}
                            >
                              <div className="h-75 astro-image p-1">
                                <div
                                  class="d-flex justify-content-center pointer h-100"
                                  style={{
                                    background: `url(${row?.imageFullUrl})`,
                                  }}
                                >
                                  {/* <Image src={row?.imageFullUrl} alt={row?.name} /> */}
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <div className="position-absolute top-60">
                                  {row?.online > 0 && (
                                    <button class="bg-white border-0 border-1 rounded-5 px-3 text-success fs-6">
                                      <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                      <LangTrans val="Online" />
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="h-25 d-flex justify-content-center align-items-center">
                                <div className="astro-info">
                                  <h4 className="pt-2 mb-0 text-center ff-manrope fs-20 fw-600 cursor">
                                    {row?.name}
                                  </h4>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                  </Slider>
                </Col>
                <Col lg="12">
                  <div className="p-4 text-center">
                    <button
                      className="see-all px-4 py-1 fs-5 text-capitalize"
                      onClick={() => {
                        navigate("/astrologers", {
                          state: { from: "live" },
                        });
                      }}
                    >
                      <LangTrans val="View all" />
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className={`py-5 live-astro-section ${
              dashboardData?.new_astrologers?.length <= 0 && "d-none"
            }`}
          >
            <Container>
              <Row>
                <Col lg="12">
                  <h2 className="text-start ff-playfair fw-bold fw-700">
                    <LangTrans val="New Arrival Astrologers" />
                  </h2>
                  <h6 className="text-start ff-manrope fw-400 text-grey-500">
                    <LangTrans val="AstroGuide shows the new astrologer whom with you can connect." />
                  </h6>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Slider
                    autoplay={true}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 5,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 784,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                    ]}
                    infinite={false}
                    speed={1000}
                    slidesToShow={6}
                    slidesToScroll={1}
                  >
                    {dashboardData?.live_astrologers?.length > 0 &&
                      dashboardData?.live_astrologers?.map((row) => {
                        return (
                          <Card className="bg-transparent border-0 p-2">
                            <Card.Body
                              onClick={() => redirectToDetail(row?.id)}
                            >
                              <div className="h-75 astro-image p-1">
                                <div
                                  class="d-flex justify-content-center pointer h-100"
                                  style={{
                                    background: `url(${row?.imageFullUrl})`,
                                  }}
                                >
                                  {/* <Image src={row?.imageFullUrl} alt={row?.name} /> */}
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <div className="position-absolute top-60">
                                  {/* <button class="bg-white border-0 border-1 rounded-5 px-3 text-success fs-6">
                                                                    <FontAwesomeIcon icon={faCheckCircle}/> Live
                                                                </button> */}
                                </div>
                              </div>
                              <div className="h-25 d-flex justify-content-center align-items-center">
                                <div className="astro-info">
                                  <h4 className="pt-2 mb-0 text-center ff-manrope fs-20 fw-600 cursor">
                                    {row?.name}
                                  </h4>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                  </Slider>
                </Col>
                <Col lg="12">
                  <div className="p-4 text-center">
                    <button
                      className="see-all px-4 py-1 fs-5 text-capitalize"
                      onClick={() => {
                        navigate("/astrologers", {
                          state: { from: "new" },
                        });
                      }}
                    >
                      <LangTrans val="View all" />
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className={`bg-p-orange-50 py-5 testimonial-section ${
              dashboardData?.blogs?.length <= 0 && "d-none"
            }`}
          >
            {/* <Blogs data={dashboardData?.blogs} /> */}
            <Blogs data={dashboardData?.blogs} />
          </div>
          {/* <div className={`bg-white py-5 testimonial-section ${dashboardData?.blogs?.length <= 0 && 'd-none'}`}>
                    
                        <Blogs data={dashboardData?.blogs} />
                    </div> */}
          <div
            className={`bg-white py-5 testimonial-section ${
              dashboardData?.testimonials?.length <= 0 && "d-none"
            }`}
          >
            <Container>
              <Row>
                <Col lg="12" className="text-start">
                  <h2 className="ff-playfair fw-bold">
                    <LangTrans val="User Testimonials" />
                  </h2>
                  <label className="text-start ff-manrope fw-400 text-grey-500">
                    <LangTrans val="Here about us from our customers" />
                  </label>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Slider
                    // autoplay={true}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 916,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                      {
                        breakpoint: 786,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                        },
                      },
                    ]}
                    infinite={false}
                    speed={1000}
                    slidesToShow={2.5}
                    slidesToScroll={1}
                  >
                    {dashboardData?.testimonials?.length > 0 &&
                      dashboardData?.testimonials?.map((row) => {
                        return (
                          <Card className="bg-transparent p-2 border-0">
                            <Card.Body className="border-1">
                              <div className="w-100 h-100 d-flex">
                                <div className="w-40 h-100">
                                  <div className="h-100 testimonial-image">
                                    <Image src={row?.imageFullUrl} />
                                  </div>
                                </div>
                                <div className="w-60">
                                  <div className="d-flex flex-column p-2 text-start h-100">
                                    <div className="h-15">
                                      <label className="fs-4 fw-bold ff-manrope text-black">
                                        {row?.name}
                                      </label>
                                    </div>
                                    <div className="h-10">
                                      <label className="fs-14 fw-bold ff-manrope text-muted">{`${row?.state}, ${row?.country}`}</label>
                                    </div>
                                    <div className="h-75">
                                      <div className="mh-100 overflow-auto">
                                        <label className="fs-12 ff-manrope text-muted pt-3 pr-2">
                                          {row?.description}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Faq />
          </div>
          <div className="bg-p-orange-50">
            <Container className="bg-p-orange-50 py-5">
              <Row className="justify-content-center">
                <Col lg="12">
                  <h3 className="ff-playfair fw-bold">
                    <LangTrans val="New Videos" />
                  </h3>
                </Col>
              </Row>
              <div className="video-section">
                <Slider
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                      },
                    },
                    {
                      breakpoint: 916,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                      },
                    },
                    {
                      breakpoint: 786,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                      },
                    },
                  ]}
                  infinite={false}
                  speed={1000}
                  slidesToShow={3}
                  slidesToScroll={1}
                >
                  {dashboardData?.videos?.length > 0 &&
                    dashboardData.videos.map((row, index) => {
                      return (
                        <Card
                          key={index}
                          className="p-2 bg-transparent border-0"
                          style={{ cursor: "pointer" }}
                        >
                          <Card.Body
                            className="p-0"
                            style={{ height: "275px" }}
                          >
                            <Image
                              src={row?.imageFullUrl}
                              alt={row?.title}
                              className="h-100 w-100 rounded-4 border"
                              style={{ objectFit: "cover", cursor: "pointer" }}
                              onClick={() => handleImageClick(row.link)}
                            />
                            <label className="fs-6 fw-bold text-black ff-playfair pl-2 mt-2">{row?.title}</label>
                          </Card.Body>
                        </Card>
                      );
                    })}
                </Slider>
              </div>
            </Container>
          </div>
          <div>
            <WhyAstro />
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
