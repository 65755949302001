import { faBars, faEarth, faEye, faLocation, faMessage, faMobilePhone } from "@fortawesome/free-solid-svg-icons"

import whyIcon1 from "../assets/images/w_icon_1.png";
import whyIcon2 from "../assets/images/w_icon_2.png";
import whyIcon3 from "../assets/images/w_icon_3.png";

// Home/DashBoard Related Data Start --
export const chatOptionData = [
    {
        id:1,
        label:'Chat with Astrologer',
        icon:faMessage,
        bg_class:'primary'
    },
    {
        id:2,
        label:'Talk with Astrologer',
        icon:faMobilePhone,
        bg_class:'success'
    },
    {
        id:3,
        label:'Online Astrologers',
        icon:faEye,
        bg_class:'info'
    }
]

export const astroCountingData = [
    {
        id:1,
        value:'25,987+',
        label:'Total Astrologers'
    },
    {
        id:2,
        value:'240 Million Minutes',
        label:'Total Chat/Call minutes'
    },
    {
        id:3,
        value:'40.3 Million',
        label:'Total Customers'
    }
]
// Home/DashBoard Related Data Close --

// Components Related Data Start --
export const astroServiceData = [
    {
        id:1,
        name:"Free Kundali",
        description:"Generate your free online kundli report from Astroguide. Our Kundli software can help you predict the future for yourself by reading the birth chart."
    },
    {
        id:2,
        name:"Match Kundali",
        description:"Love could be confusing, but only until you haven’t found how compatible you two are for each other."
    },
    {
        id:3,
        name:"Daily Horoscope",
        description:"Get free Aries daily horoscope prediction today online from the best astrologer. Read your Aries Zodiac Sign horoscope today!"
    }
]

export const findBestAstroStaticData = [
    {
        icon:faMobilePhone,
        label:'Consult Astrologer',
        name:'order_by',
        data:[
            {
                label:'Talk to Astrologer',
                value:'created_at DESC'
            },
            {
                label:'Chat with Astrologer',
                value:'created_at DESC'
            }
        ]
    },
    {
        icon:faLocation,
        label:'Indian(City - Wise)',
        name:'city',
        data:[]
    },
    {
        icon:faEarth,
        label:'International',
        name:'country',
        data:[]
    },
    {
        icon:faBars,
        label:'Category Wise',
        name:'type',
        data:[]
    }
]

export const whyAstroData = [
    {
        img:whyIcon1,
        heading:'Easily connect with Astro',
        content:'You can easily connect with astrologer with the help of AstroGuide.'
    },
    {
        img:whyIcon2,
        heading:'Privacy guarantee 100%',
        content:'AstroGuide provide 100% privacy guarantee to your personal data.'
    },
    {
        img:whyIcon3,
        heading:'Approved Astrologer',
        content:'You can talk with 100% verified and approved Astrologer.'
    }
]

export const genderData = [
    {
        label: "Female",
        value: "FEMALE",
    },
    {
        label: "Male",
        value: "MALE",
    },
    {
        label: "Other",
        value: "OTHER",
    },
]

export const maritalStatusData = [
    {
        label: "MARRIED",
        value: "MARRIED",
    },
    {
        label: "SINGLE",
        value: "SINGLE",
    },
]
// Components Related Data Start --