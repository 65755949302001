import React, { useState } from "react";
import { Card, Col, Container, Row, Button, Modal } from 'react-bootstrap';
import Constant from "../../config/Constant";

const convertLinksToAnchors = (text) => {
    const urlRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[-A-Z0-9+&@#\/%=~_|$])|(\b(www\.[\S]+))/gi;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      const [url] = match;
      const { index: start } = match;
      const end = start + url.length;

      // Add text before the URL
      if (start > lastIndex) {
        parts.push(
          <span key={`text-${lastIndex}`} className="description-text">
            {text.substring(lastIndex, start)}
          </span>
        );
      }

      // Add URL as a clickable link
      const formattedUrl = url.startsWith('www.') ? `http://${url}` : url;
      parts.push(
        <a
          key={`link-${start}`}
          href={formattedUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="description-link"
        >
          {formattedUrl}
        </a>
      );

      lastIndex = end;
    }

    // Add remaining text after the last URL
    if (lastIndex < text.length) {
      parts.push(
        <span key={`text-${lastIndex}`} className="description-text">
          {text.substring(lastIndex)}
        </span>
      );
    }

    return parts;
  };

// Modal component
const BlogModal = ({ show, onHide, blog }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {blog?.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{blog?.name}</h4>
      <p>{convertLinksToAnchors(blog?.description)}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
);

const Blogs = ({ data }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  // Function to convert URLs in description to clickable anchors
 

  const handleReadMoreClick = (blog) => {
    setSelectedBlog(blog);
    setModalShow(true);
  };

  return (
    <div className={`bg-p-orange-50 blog-section ${data?.length <= 0 && 'd-none'}`}>
      <Container>
        <Row>
          <Col lg='12'>
            <h2 className="text-start ff-playfair fw-bold">New Updates and Blogs</h2>
            <h6 className="text-start ff-manrope fw-400 text-grey-500">Top Astrologers. 24 * 7 customer support. Happy to help.</h6>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg='12'>
            {data?.length > 0 && data.map((row) => (
              <Card key={row.id} className="bg-p-orange-50 border-0">
                <Card.Body className=" bg-p-orange-50 border-1 d-flex">
                  <div className="blog-image" style={{
                    backgroundImage: `url(${row?.imageFullUrl})`,
                    width: '26%',
                    minHeight: '150px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}>
                  </div>
                  <div className="blog-content" style={{ width: '60%', padding: '0 15px' }}>
                    <h4 className="ff-manrope fw-bold text-warning mt-2">{row?.name}</h4>
                    <h5 className="fs-6 ff-manrope text-black text-start"><b>{row?.title}</b></h5>
                    <p className="fs-12 ff-manrope">
                      {convertLinksToAnchors(row?.description.slice(0, 100) + '...')}
                    </p>
                    <div className="d-flex justify-content-between pt-1">
                      <span className="text-muted fs-13 ff-manrope">
                        {Constant?.moment(row?.created_at)?.format('MMM DD, YYYY')}
                      </span>
                    </div>
                    <Button
                      className="button-87" 
                      style={{ borderColor: "transparent", marginTop: "15%" }}
                      onClick={() => handleReadMoreClick(row)}
                    >
                      Read More
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>

      {/* Modal for displaying full description */}
      {selectedBlog && (
        <BlogModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          blog={selectedBlog}
        />
      )}
    </div>
  );
};

export default Blogs;


// import React, { useState } from "react";
// import { Card, Col, Container, Row, Button, Modal } from 'react-bootstrap';
// import Constant from "../../config/Constant";

// // import Slider from "react-slick";
// import "../../../node_modules/slick-carousel/slick/slick.scss";
// import "../../../node_modules/slick-carousel/slick/slick-theme.scss";

// // Modal component
// const BlogModal = ({ show, onHide, blog }) => (
//   <Modal
//     show={show}
//     onHide={onHide}
//     size="lg"
//     aria-labelledby="contained-modal-title-vcenter"
//     centered
//   >
//     <Modal.Header closeButton>
//       <Modal.Title id="contained-modal-title-vcenter">
//         {blog?.title}
//       </Modal.Title>
//     </Modal.Header>
//     <Modal.Body>
//       <h4>{blog?.name}</h4>
//       <p>{blog?.description}</p>
//     </Modal.Body>
//     <Modal.Footer>
//       <Button variant="secondary" onClick={onHide}>Close</Button>
//     </Modal.Footer>
//   </Modal>
// );

// const Blogs = ({ data }) => {
//   const [modalShow, setModalShow] = useState(false);
//   const [selectedBlog, setSelectedBlog] = useState(null);

//   const convertLinksToAnchors = (text) => {
//     const words = text.split(' ');
//     return words.map((word, index) => {
//       if (word.toLowerCase().startsWith('link:')) {
//         const url = word.slice(5); // Remove 'link:' prefix
//         let href = url;
//         if (url.startsWith('www.')) {
//           href = 'http://' + url;
//         }
//         return (
//           <React.Fragment key={index}>
//             <a 
//               href={href} 
//               target="_blank" 
//               rel="noopener noreferrer"
//               className="fs-12 ff-manrope"
//             >
//               {url}
//             </a>
//             {' '}
//           </React.Fragment>
//         );
//       }
//       return word + ' ';
//     });
//   };

//   const handleReadMoreClick = (blog) => {
//     setSelectedBlog(blog);
//     setModalShow(true);
//   };

//   return (
//     <div className={`bg-white py-4 blog-section ${data?.length <= 0 && 'd-none'}`}>
//       <Container>
//         <Row>
//           <Col lg='12'>
//             <h2 className="text-start ff-playfair fw-bold">New Updates and Blogs</h2>
//             <h6 className="text-start ff-manrope fw-400 text-grey-500">Top Astrologers. 24 * 7 customer support. Happy to help.</h6>
//           </Col>
//         </Row>
//         <Row className="mt-3">
//           <Col lg='12'>
//             {data?.length > 0 && data.map((row) => (
//               <Card key={row.id} className="bg-transparent p-2 border-0">
//                 <Card.Body className="border-1 d-flex">
//                   <div className="blog-image" style={{
//                     backgroundImage: `url(${row?.imageFullUrl})`,
//                     width: '26%',
//                     minHeight: '150px',
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center'
//                   }}>
//                   </div>
//                   <div className="blog-content" style={{ width: '60%', padding: '0 15px' }}>
//                     <h4 className="ff-manrope fw-bold text-warning mt-2">{row?.name}</h4>
//                     <h5 className="fs-6 ff-manrope text-black text-start"><b>{row?.title}</b></h5>
//                     <p className="fs-12 ff-manrope">
//                       {convertLinksToAnchors(row?.description.slice(0, 100) + '...')}
//                     </p>
//                     <div className="d-flex justify-content-between pt-1">
//                       <span className="text-muted fs-13 ff-manrope">
//                         {Constant?.moment(row?.created_at)?.format('MMM DD, YYYY')}
//                       </span>
//                     </div>
//                     <Button
//                     //   className="w-20 mt-3 p-2 fs-14 fw-bold text-capitalize ff-manrope mt-2"
//                       className="button-87" style={{borderColor:"transparent" , marginTop:"15%"}}
//                       onClick={() => handleReadMoreClick(row)}
//                     >
//                       Read More
//                     </Button>
//                   </div>
//                 </Card.Body>
//               </Card>
//             ))}
//           </Col>
//         </Row>
//       </Container>

//       {/* Modal for displaying full description */}
//       {selectedBlog && (
//         <BlogModal
//           show={modalShow}
//           onHide={() => setModalShow(false)}
//           blog={selectedBlog}
//         />
//       )}
//     </div>
//   );
// };

// export default Blogs;


// import React, { useState } from "react";
// import { Card, Col, Container, Row } from 'react-bootstrap';
// import Constant from "../../config/Constant";

// // import Slider from "react-slick";
// import "../../../node_modules/slick-carousel/slick/slick.scss"
// import "../../../node_modules/slick-carousel/slick/slick-theme.scss"

// const Blogs = ({ data }) => {
//     const [expandedPostId, setExpandedPostId] = useState(null);

//     const convertLinksToAnchors = (text) => {
//         const words = text.split(' ');
//         return words.map((word, index) => {
//             if (word.toLowerCase().startsWith('link:')) {
//                 const url = word.slice(5); // Remove 'link:' prefix
//                 let href = url;
//                 if (url.startsWith('www.')) {
//                     href = 'http://' + url;
//                 }
//                 return (
//                     <React.Fragment key={index}>
//                         <a 
//                             href={href} 
//                             target="_blank" 
//                             rel="noopener noreferrer"
//                             className="fs-12 ff-manrope"
//                         >
//                             {url}
//                         </a>
//                         {' '}
//                     </React.Fragment>
//                 );
//             }
//             return word + ' ';
//         });
//     };

//     const handleReadMoreClick = (id) => {
//         setExpandedPostId(expandedPostId === id ? null : id);
//     };

//     return (
//         <div className={`bg-white py-4 blog-section ${data?.length <= 0 && 'd-none'}`}>
//             <Container>
//                 <Row>
//                     <Col lg='12'>
//                         <h2 className="text-start ff-playfair fw-bold">New Updates and Blogs</h2>
//                         <h6 className="text-start ff-manrope fw-400 text-grey-500">Top Astrologers. 24 * 7 customer support. Happy to help.</h6>
//                     </Col>
//                 </Row>
//                 <Row className="mt-3">
//                     <Col lg='12'>
//                         {data?.length > 0 && data?.map((row) => (
//                             <Card key={row.id} className="bg-transparent p-2 border-0">
//                                 <Card.Body className="border-1 d-flex">
//                                     <div className="blog-image" style={{
//                                         backgroundImage: `url(${row?.imageFullUrl})`,
//                                         width: '26%',
//                                         minHeight: '150px',
//                                         backgroundSize: 'cover',
//                                         backgroundPosition: 'center'
//                                     }}>
//                                     </div>
//                                     <div className="blog-content" style={{ width: '60%', padding: '0 15px' }}>
//                                         <h4 className="ff-manrope fw-bold text-warning">{row?.name}</h4>
//                                         <h5 className="fs-6 ff-manrope text-black text-start"><b>{row?.title}</b></h5>
//                                         <p className="fs-12 ff-manrope">
//                                             {convertLinksToAnchors(
//                                                 expandedPostId === row.id ? row?.description : row?.description.slice(0, 100) + '...'
//                                             )}
//                                         </p>
//                                         <div className="d-flex justify-content-between pt-1">
//                                             <span className="text-muted fs-13 ff-manrope">
//                                                 {Constant?.moment(row?.created_at)?.format('MMM DD, YYYY')}
//                                             </span>
//                                         </div>
//                                         <button
//                                             className="w-100 p-2 fs-14 fw-bold text-capitalize ff-manrope mt-2"
//                                             onClick={() => handleReadMoreClick(row.id)}
//                                         >
//                                             {expandedPostId === row.id ? 'Read Less' : 'Read More'}
//                                         </button>
//                                     </div>
//                                 </Card.Body>
//                             </Card>
//                         ))}
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     );
// };

// export default Blogs;


// import React, { useState } from "react";
// import { Card, Col, Container, Image, Row } from 'react-bootstrap';
// import Constant from "../../config/Constant";

// import Slider from "react-slick";
// import "../../../node_modules/slick-carousel/slick/slick.scss"
// import "../../../node_modules/slick-carousel/slick/slick-theme.scss"

// // const Blogs = ({data = []}) => {
// const Blogs = ({data}) => {

//     // const [blogData, setBlogData] = useState([])
//     // console.log("Blog Data :", data);
    
    
//     return (
//         <div className={`bg-white py-4 blog-section ${data?.length <= 0 && 'd-none'}`}>
//             <Container>
//                 <Row>
//                     <Col lg='12'>
//                         <h2 className="text-start ff-playfair fw-bold">New Updates and Blogs</h2>
//                         <h6 className="text-start ff-manrope fw-400 text-grey-500">Top Astrologers. 24 * 7 customer support. Happy to help.</h6>
//                     </Col>
//                 </Row>
//                 <Row className="mt-3">
//                     <Col lg='12'>
//                         <Slider
//                             autoplay={true}
//                             responsive={[
//                                 {
//                                     breakpoint:1024,
//                                     settings:{
//                                         slidesToShow:3,
//                                         slidesToScroll:1,
//                                         infinite:true
//                                     }
//                                 },
//                                 {
//                                     breakpoint:784,
//                                     settings:{
//                                         slidesToShow:2,
//                                         slidesToScroll:1,
//                                         infinite:true
//                                     }
//                                 },
//                                 {
//                                     breakpoint:600,
//                                     settings:{
//                                         slidesToShow:1,
//                                         slidesToScroll:1,
//                                         infinite:true
//                                     }
//                                 }
//                             ]}
//                             infinite={true}
//                             speed={1000}
//                             slidesToShow={3}
//                             slidesToScroll={1}
//                         >
//                         {
//                             data?.length > 0 && data?.map((row) => {
//                                 return (
//                                     <Card className="bg-transparent p-2 border-0">
//                                         <Card.Body className="border-1">
//                                             <div className="h-60 blog-image p-1" style={{backgroundImage:`url(${row?.imageFullUrl})`}}>
//                                                 <Row className="align-items-center">
//                                                     <Col lg='6'>
//                                                         <div className="px-4">
//                                                             <h3 className="ff-manrope fw-bold text-warning">{row?.name}</h3>
//                                                             <label className="fs-12 text-white ff-manrope">{row?.description}</label>
//                                                         </div>
//                                                     </Col>
//                                                 </Row>
//                                             </div>
//                                             <div className="h-40 d-flex justify-content-center">
//                                                 <div className="p-2 pt-0">
//                                                     <div className="blog-info d-flex flex-column">
//                                                         <div className="h-30">
//                                                             <label className="fs-6 ff-manrope text-black text-start"><b>{row?.title}: {row?.description}</b></label>
//                                                         </div>
//                                                         <div className="d-flex justify-content-between pt-1">
//                                                             <label className="text-muted fs-13 ff-manrope">{row?.name}</label>
//                                                             <label className="text-muted fs-13 ff-manrope">{Constant?.moment(row?.created_at)?.format('D, M Y')}</label>
//                                                         </div>
//                                                         <div className="d-flex justify-content-between mt-1">
//                                                             <button className="w-100 p-2 fs-14 fw-bold text-capitalize ff-manrope">
//                                                                 read more
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </Card.Body>
//                                     </Card>
//                                 )
//                             })
//                         }
//                         </Slider>
//                     </Col>
//                     <Col lg='12'>
//                         <div className="p-4 text-center">
//                             <button className="see-all px-4 py-1 fs-5 text-capitalize">
//                                 see all
//                             </button>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     )
// }

// export default Blogs