import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";

import { useEffect, useState } from "react";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { userInfo } from "../../config/Constant";
import moment from "moment";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// REDUX FILES
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";

const FreeKundli = () => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [birthDateData, setBirthDateData] = useState([]);
    const [birthMonthData, setBirthMonthData] = useState([]);
    const [birthYearData, setBirthYearData] = useState([]);
    const [birthHourData, setBirthHourData] = useState([]);
    const [birthMinuteData, setBirthMinuteData] = useState([
        {
            label:'00',
            value:'00'
        }
    ]);
    const [birthSecondData, setBirthSecondData] = useState([
        {
            label:'00',
            value:'00'
        }
    ]);
    const genderData = [
        {
            label:'Female',
            value:'FEMALE'
        },
        {
            label:'Male',
            value:'MALE'
        },
        {
            label:'Other',
            value:'OTHER'
        }
    ];
    const maritalStatusData = [
        {
            label:'MARRIED',
            value:'MARRIED'
        },
        {
            label:'SINGLE',
            value:'SINGLE'
        }
    ];
    const [kundliFormData, setKundliFormData] = useState()
    const [relationData, setRelationData] = useState([
        {
            label:'SELF',
            value:-1
        }
    ])
    const [cityData, setCityData] = useState([])
    const [kundliData, setKundliData] = useState([])
    // function for create dropdown data start
    const createDropDownData = () => {
        for (let index = 1; index <= 60; index++)
        {
            birthYearData[index - 1] = {label:moment()?.subtract(index - 1, 'year')?.format('YYYY'), value:moment()?.subtract(index - 1, 'year')?.format('YYYY')}
            setBirthYearData([...birthYearData])
            if (index <= 12) {
                birthMonthData[index - 1] = {label:moment().month(index - 1).format("MMM"), value:index > 9 ? `${index}` : `0${index}`}
                setBirthMonthData([...birthMonthData])
            }
            if (index <= 31) {
                birthDateData[index - 1] = {label:index > 9 ? `${index}` : `0${index}`, value:index > 9 ? `${index}` : `0${index}`}
                setBirthDateData([...birthDateData])
            }
            if (index <= 24) {
                birthHourData[index - 1] = {label:index > 9 ? `${index}` : `0${index}`, value:index > 9 ? `${index}` : `0${index}`}
                setBirthHourData([...birthHourData])
            }
            birthMinuteData[index] = {label:index > 9 ? `${index}` : `0${index}`, value:index > 9 ? `${index}` : `0${index}`}
            setBirthMinuteData([...birthMinuteData])
            birthSecondData[index] = {label:index > 9 ? `${index}` : `0${index}`, value:index > 9 ? `${index}` : `0${index}`}
            setBirthSecondData([...birthSecondData])
        }
    }
    // function for create dropdown data close

    // get kundli data start
    const getKundliData = () => apiCall({url:Constant?.apis?.userKundli, method:'get'})?.then((res) => setKundliData([...res?.data]))
    // get kundli data close

    // function for get values for show in form start
    const getKundliValuesData = () => {
        apiCall({url:Constant?.apis?.userKundliValues, method:'get'})?.then((res) => {
            if (res?.status) {
                res?.relations?.map((row, index) => {
                    relationData[index + 1] = {
                        label:row?.name,
                        value:row?.id
                    }
                })
                setRelationData([...relationData])
            }
        })
    }
    // function for get values for show in form close

    // function for get cities data start
    const getCityData = () => {
        apiCall({url:Constant?.apis?.cityAll, method:'get'})?.then((res) => {
            if (res?.status) {
                setCityData([...res?.data?.map(e => ({label:`${e?.name}, ${e?.state}, ${e?.country}`, value:e?.id}))])
            }
        })
    }
    // function for get cities data close

    // function for set value in form data start
    const changeFormValue = ({key = '', value = ''}) => {
        if (key?.length > 0) {
            kundliFormData[key] = value
            setKundliFormData({...kundliFormData})
        }
    }
    // function for set value in form data close

    // function for submit form data start
    const kundliFormSubmit = () => {
        const body = {
            k_id:kundliFormData?.id,
            r_id:kundliFormData?.r_id,
            name:kundliFormData?.name,
            gender:kundliFormData?.gender,
            dob:`${kundliFormData?.year}-${kundliFormData?.month}-${kundliFormData?.date} ${kundliFormData?.hour}:${kundliFormData?.minute}:${kundliFormData?.second}`,
            ci_id:kundliFormData?.ci_id,
            marital_status:kundliFormData?.marital_status
        }
        apiCall({url:body?.k_id > 0 ? Constant?.apis?.userKundliUpdate : Constant?.apis?.userKundliAdd, body, isLogin:true})?.then((res) => {
            if (res?.status) {
                if (body?.k_id > 0) {
                    setLoad(!load)
                } else {
                    navigate('/free-kundli-report', {
                        state:{id:res?.kundli_id}
                    })
                }
            }
        })
    }
    // function for submit form data close

    // function for remove the selected kudli start -----
    const deleteKundliData = (id = 0) => {
         if (id > 0) {
            apiCall({method:'GET', url:`${Constant?.apis?.userKundliDelete}${id}`})?.then((res) => {
                if (res?.status) getKundliData()
            })
         }
    }
    // function for remove the selected kudli close -----

    // function for set edit data for update/change start -----
    const editFreeKundliData = (row = {}) => {
        setKundliFormData({
            ...row,
            r_id:row?.type == 'SELF' ? -1 : row?.r_id,
            date:moment(row?.dob)?.format('DD'),
            month:moment(row?.dob)?.format('MM'),
            year:moment(row?.dob)?.format('YYYY'),
            hour:moment(row?.dob)?.format('HH'),
            minute:moment(row?.dob)?.format('MM'),
            second:moment(row?.dob)?.format('ss'),
        })
    }
    // function for set edit data for update/change close -----

    useEffect(() => {
        createDropDownData()
        getCityData()
        setKundliFormData({
            id:0,
            r_id:-1,
            name:"",
            gender:"",
            date:"",
            month:"",
            year:"",
            hour:"",
            minute:"",
            second:"",
            dob:"",
            ci_id:"",
            marital_status:""
        })
        if (userInfo?.token) {
            getKundliValuesData()
            getKundliData()
        }
    }, [load])
    
    return (
        <div className="freekundli-page">
            <Container className="py-5">
                <Row>
                    <Col lg={12}>
                        <Card className="border-0">
                            <Card.Header className="border-0 py-2 primary-bg-color">
                                <span className="fs-17 ff-manrope">Free Kundli Online - Get Your Detailed Birth Chart with Predictions</span>
                            </Card.Header>
                            <Card.Body className="ff-manrope text-grey-500 fs-13 text-justify">
                                <p className="border-bottom pb-2">Looking for your free Kundli from expert astrologers? Then you have come to the right place. The online free kundali available on astroguide is a 100% free and authentic free Kundli that has been prepared after consulting more than 50 expert astrologers on board. The free kundli is such that it can give you a glimpse into various aspects of your life such as your career, love life, marriage, business and much more. The online kundli prepared by the free Kundali software here is no less than any traditional Kundli and can also be used for purposes like matching making, kundali matching for marriage or simply making future predictions.</p>
                                <p>Talking about future predictions, the kundali catered by astroguide to you is such that it considers the movement of all the planets in the native's life from the beginning of his or her life till as far as 100 years. Doing so helps you understand the reasons behind not only the ongoing circumstances but also what's to come for you in the future. So if in any way you are confused about life, the online kundli can be your saviour. Having said that, make sure you try the free online kundli, and let us know what you feel about it.</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col lg={8} sm={12} xs={12}>
                        <Card className="h-100 border-0 m-2">
                            <Card.Header className="border-0 py-2 primary-bg-color">
                                <span className="fs-19 ff-manrope text-capitalize">new kundli</span>
                            </Card.Header>
                            <Card.Body>
                                <Form className="text-capitalize ff-manrope" onSubmit={handleSubmit(kundliFormSubmit)}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">name</Form.Label>
                                                <Form.Control
                                                    className="rounded-1 ff-manrope"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    required
                                                    value={kundliFormData?.name}
                                                    onChange={(e) => changeFormValue({key:'name', value:e?.target?.value})}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">relation</Form.Label>
                                                <Select
                                                    value={relationData?.filter((e) => e?.value == kundliFormData?.r_id)}
                                                    options={relationData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'r_id', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">gender</Form.Label>
                                                <Select
                                                    value={genderData?.filter((e) => e?.value == kundliFormData?.gender)}
                                                    options={genderData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'gender', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">marital status</Form.Label>
                                                <Select
                                                    value={maritalStatusData?.filter((e) => e?.value == kundliFormData?.marital_status)}
                                                    options={maritalStatusData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'marital_status', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth date</Form.Label>
                                                <Select
                                                    value={birthDateData?.filter((e) => e?.value == kundliFormData?.date)}
                                                    options={birthDateData}
                                                    required
                                                    onChange={(e) => {
                                                        console.log('sss : ', e)
                                                        changeFormValue({key:'date', value:e?.value})
                                                    }}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth month</Form.Label>
                                                <Select
                                                    value={birthMonthData?.filter((e) => e?.value == kundliFormData?.month)}
                                                    options={birthMonthData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'month', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth year</Form.Label>
                                                <Select
                                                    value={birthYearData?.filter((e) => e?.value == kundliFormData?.year)}
                                                    options={birthYearData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'year', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth hour</Form.Label>
                                                <Select
                                                    value={birthHourData?.filter((e) => e?.value == kundliFormData?.hour)}
                                                    options={birthHourData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'hour', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth minute</Form.Label>
                                                <Select
                                                    value={birthMinuteData?.filter((e) => e?.value == kundliFormData?.minute)}
                                                    options={birthMinuteData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'minute', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth second</Form.Label>
                                                <Select
                                                    value={birthSecondData?.filter((e) => e?.value == kundliFormData?.second)}
                                                    options={birthSecondData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'second', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fs-14 mb-0">birth place</Form.Label>
                                                <Select
                                                    value={cityData?.filter((e) => e?.value == kundliFormData?.ci_id)}
                                                    options={cityData}
                                                    required
                                                    onChange={(e) => changeFormValue({key:'ci_id', value:e?.value})}
                                                    className="rounded fs-15"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Button
                                                className="primary-bg-color rounded-5 border-0 text-dark w-100 text-capitalize py-2"
                                                type="submit"
                                            >
                                                generate horoscope
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="h-100 border-0 m-2 ">
                        {
                            userInfo?.name?.length > 0 ? (
                                <>
                                    <Card.Header className="border-0 py-2 primary-bg-color card-header">
                                        <span className="fs-19 ff-manrope text-capitalize">my kundlis</span>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex flex-column gap-2 p-1">
                                        {
                                            kundliData?.length > 0 ? 
                                            <>
                                            {
                                                kundliData?.map((row, index) => {
                                                    return (
                                                        <Card className="border rounded text-capitalize" key={index}>
                                                            <Card.Header className="bg-transparent border-0 d-flex justify-content-between">
                                                                <div className="ff-playfair fw-600 fs-13">{row?.name}({row?.type})</div>
                                                                <div>
                                                                    <div className="d-flex justify-content-end gap-2">
                                                                        <span
                                                                            className="fs-12 text-success d-flex align-items-center cursor"
                                                                            onClick={() => {
                                                                                navigate('/free-kundli-report', {
                                                                                    state:{id:row?.id}
                                                                                })
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faEye} />
                                                                        </span>
                                                                        <span
                                                                            className="fs-12 text-info d-flex align-items-center cursor"
                                                                            onClick={() => editFreeKundliData(row)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </span>
                                                                        <span
                                                                            className="fs-12 text-danger d-flex align-items-center cursor"
                                                                            onClick={() => {
                                                                                if (window.confirm('Are you sure for remove this kundli detail')) {
                                                                                    deleteKundliData(row?.id)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Card.Header>
                                                            <Card.Body className="py-1 ff-manrope fs-12">
                                                                <div className="d-flex gap-2 justify-content-between">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span>gender : </span>
                                                                        <span className="fw-bold">{row?.gender}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span>marital status : </span>
                                                                        <span className="fw-bold">{row?.marital_status}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex gap-2 justify-content-between">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span>dob : </span>
                                                                        <span className="fw-bold">{moment(row?.dob)?.format('DD, MMM YYYY H:m')}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span>city : </span>
                                                                        <span className="fw-bold">{row?.city}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex gap-2 justify-content-between">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span>mo : </span>
                                                                        <span className="fw-bold">{row?.mobile}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span className="fw-bold">{row?.state}, {row?.country}</span>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })
                                            }
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                        </div>
                                    </Card.Body>
                                </>
                            ) : (
                                <div className="h-100 d-flex justify-content-center align-items-center flex-column gap-1">
                                    <span className="ff-manrope fs-16 mb-1">Please login to check your saved horoscope!</span>
                                    <Button
                                        className="primary-bg-color border-0 text-dark px-4 text-capitalize fw-500"
                                        onClick={() => dispatch(userLoginModel(true))}
                                    >
                                        login
                                    </Button>
                                </div>
                            )
                        }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FreeKundli;