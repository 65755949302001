import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Constant from "../../../config/Constant";


const DashboardActions = createAsyncThunk('Dashboard',
    async () => {
        return await axios.get(Constant.apis.dashboard, {
            headers:Constant.header
        })
        .then((response) => {
            console.log('Dashboard Response :: ', response)
            return response.data
        })
        .catch((err) => {
            console.log('Dashboard Error ::: ', err)
        })
    }
)

export default DashboardActions