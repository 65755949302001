import { Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { React, useEffect, useState } from "react";

import Constant, { storage, userInfo } from "../../config/Constant";

import WalletHistory from "./walletHistory";
import CallHistory from "./callHistory";
import ChatHistory from "./chatHistory";
import { apiCall } from "../../Components/Helper/ApiHelper";
import LangTrans from "../../Components/Helper/LangHelper";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";

const History = () => {
    
    const dispatch = useDispatch()
    const location = useLocation();
    const [historyData, setHistoryData] = useState({})

    const tabsData = ['Wallet', 'Call', 'Chat'];
    const [currentTab, setCurrentTab] = useState(location?.state?.tab?? 'Wallet');

    const getHistoryData = () => {
        apiCall({method:'get', url:Constant?.apis?.transactionUser, isLogin:true}).then((res) => setHistoryData(res))
    }
    console.log('historydata :: ', historyData)
    
    useEffect(() => {

        localStorage.removeItem(storage?.activeAction);
        localStorage.removeItem(storage?.chatStartSrv);
        localStorage.removeItem(storage?.chatStart);
        localStorage?.removeItem(storage?.callStart)
        localStorage?.removeItem(storage?.callStatus)
        localStorage?.removeItem(storage?.callingClock);
        localStorage?.removeItem(storage?.communicate);

        getHistoryData()
        document.title = 'History : Astroguide'
    }, [])
    return (
        <div className="history-page text-capitalize">
            <div className="bg-white p-2">
                <Container className="py-2">
                    <Row className="justify-content-between align-items-center">
                        <Col lg='12'>
                            <div className="d-flex gap-1 align-items-center p-1">
                                <FontAwesomeIcon className="primary-color" icon={faHome}/>
                                <FontAwesomeIcon className="text-black" icon={faAngleRight}/>
                                <label className="pl-1 ff-manrope fs-13 fw-bold">
                                    <LangTrans val="History Details" />
                                </label>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="history-section py-3">
                {
                    userInfo?.token ? (
                        <>
                            <div className="py-2">
                                <Nav variant="pills" className="history-tab">
                                {
                                    tabsData?.map((val) => {
                                        return (
                                        <Nav.Item onClick={() => setCurrentTab(val)}>
                                            <Nav.Link active={currentTab === val} eventKey={val}>
                                                <LangTrans val={val} />
                                            </Nav.Link>
                                        </Nav.Item>
                                        )
                                    })
                                }
                                </Nav>
                            </div>
                            <div className="history-content">
                                <Tab.Content>
                                    <Tab.Pane active={currentTab === 'Wallet'} eventKey='wallet'>
                                        <WalletHistory walletData={historyData?.wallet} paymentData={historyData?.payment} />
                                    </Tab.Pane>
                                    <Tab.Pane active={currentTab === 'Call'} eventKey='call'>
                                        <CallHistory callData={historyData?.call} childTab={location?.state?.childTab} />
                                    </Tab.Pane>
                                    <Tab.Pane active={currentTab === 'Chat'} eventKey='chat'>
                                        <ChatHistory chatData={historyData?.chat} childTab={location?.state?.childTab} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </>
                    ) : (
                        <>
                            <Row className="mt-3">
                                <Col lg='12' sm='7' xs='12'>
                                    <Card className="recharge-section border-0">
                                        <Card.Body className="text-center mh-250-px d-flex justify-content-center align-items-center">
                                            <Button
                                                className="rounded-5 px-5 primary-bg-color primary-border-color text-dark ff-manrope fw-18"
                                                onClick={() => dispatch(userLoginModel(true))}
                                            >
                                                <LangTrans val="Login" />
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )
                }
                </Container>
            </div>
        </div>
    )    
}
export default History;