import React from "react";
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import LangTrans from "../Helper/LangHelper";
import { whyAstroData } from "../../data/staticData";

const WhyAstro = () => {
    
    return (
        <div className="bg-white py-5 why-astro-section text-capitalize">
            <Container>
                <Row className="justify-content-center">
                    <Col lg='12'>
                        <h3 className="ff-playfair fw-bold"><LangTrans val="Why AstroGuide?" /></h3>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                {
                    whyAstroData?.map((row) => {
                        return (
                        <Col lg='4' md='6' sm='12' xs='12'>
                            <div className="p-2 h-100">
                                <Card className="h-100">
                                    <Card.Body className="p-2">
                                        <div className="d-flex gap-1 w-100 align-items-center h-100">
                                            <div className="w-30">
                                                <div className="why-astro-image p-2 pt-0">
                                                    <Image
                                                        src={row?.img}
                                                        className="w-100"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-70 px-2">
                                                <div className="d-flex flex-column text-start">
                                                    <label className="fs-6 fw-bold ff-playfair lh-2"><LangTrans val={row?.heading} /></label>
                                                    <p className="ff-manrope fs-14 pr-3 fw-400"><LangTrans val={row?.content} /></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        )
                    })
                }
                </Row>
            </Container>
        </div>
    )
}

export default WhyAstro