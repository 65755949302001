import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Form,
  Row,
  Dropdown,
  DropdownButton,
  Modal,
  InputGroup,
  Image,
  FormControl,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/HeaderLogo.png";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Constant, { CURR_LANG, storage, userInfo } from "../../config/Constant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

import OTPInput from "react-otp-input";
import { sentOtp, verifyOtp } from "../../config/FireBase/OTP/otpConfig";
import { apiCall } from "../Helper/ApiHelper";

import { useForm, Controller } from "react-hook-form";
// import Select from "react-dropdown-select"
import moment from "moment";
import Select from "react-select";
import LangTrans from "../Helper/LangHelper";

import { useDispatch, useSelector } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";

import { ToastContainer, toast } from "react-toastify";

function generateOTP(length) {
  let otp = "";
  const characters = "0123456789";
  for (let i = 0; i < length; i++) {
    otp += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return otp;
}

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const communicate = JSON.parse(localStorage?.getItem(storage?.communicate));

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    const filtered = countryFlagData.filter((row) =>
      row.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const items = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();

  const { handleSubmit } = useForm();

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const handleSubmit1 = (e) => {
    e.preventDefault();

    // Validate name
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!profileData.name || !nameRegex.test(profileData.name)) {
      setNameError("Only letters and spaces are allowed");
      return;
    }

    // Validate email
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!profileData.email || !emailRegex.test(profileData.email)) {
      setEmailError("Invalid email address");
      return;
    }

    // Validate postal code
    const postalCodeRegex = /^[A-Za-z0-9]*$/;
    if (
      !profileData.postal_code ||
      !postalCodeRegex.test(profileData.postal_code)
    ) {
      setPostalCodeError("Invalid postal code");
      return;
    }

    // All validations passed, proceed with form submission
    onProfileModelSubmit();
  };

  const [nameError1, setNameError1] = useState("");
  const [emailError1, setEmailError1] = useState("");
  const [postalCodeError1, setPostalCodeError1] = useState("");

  const handleSubmit2 = (e) => {
    e.preventDefault();

    // name validation
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!registerFormData.name || !nameRegex.test(registerFormData.name)) {
      setNameError1("Only letters and spaces are allowed");
      return;
    }

    // Validate email
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!registerFormData.email || !emailRegex.test(registerFormData.email)) {
      setEmailError1("Invalid email address");
      return;
    }

    // Validate postal code
    const postalCodeRegex = /^[A-Za-z0-9]*$/;
    if (
      !registerFormData.postal_code ||
      !postalCodeRegex.test(registerFormData.postal_code)
    ) {
      setPostalCodeError1("Invalid postal code");
      return;
    }

    onRegisterFormSubmit();
  };

  const [mobileError, setMobileError] = useState("");
  const [whatsAppOtpAllow, setWhatsAppOtpAllow] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [whatsAppObj, setWhatsAppObj] = useState({});

  const handleGetOTP = () => {
    if (loginFormData.mobile.trim() === "") {
      setMobileError("Please enter your mobile number");
      return; // Stop further execution
    }
    setIsWhatsApp(false);
    setWhatsAppOtpAllow(false);
    apiCall({
      url: Constant?.apis?.userVerify,
      body: {
        mobile: `${loginFormData?.country_code}-${loginFormData?.mobile}`,
      },
    })?.then((res) => {
      if (res?.whatsapp == 1) {
        setWhatsAppOtpAllow(true);
        setWhatsAppObj({ ...res });
      } else {
        setWhatsAppOtpAllow(false);
        setWhatsAppObj({});
      }
    });

    // Proceed with OTP generation
    // Add your code to generate OTP here
  };

  // function for sent OTP on Whatsapp
  const sendWhatsAppOTP = () => {
    console.log("loginFormData?.mobile :: ", loginFormData?.mobile);

    const generatedOTP = generateOTP(6);
    whatsAppObj.otp = generatedOTP;
    setWhatsAppObj({ ...whatsAppObj });
    console.log("sss generatedOTP :: ", generatedOTP);
    setIsWhatsApp(true);
    const text = `Your AstroGuide Application OTP is ${generatedOTP}. Kindly login with this OTP.\nPlease keep it confidential.\n*Thank you*.`;
    const url = `${
      whatsAppObj?.whatsapp_url
    }/api/send?number=${loginFormData?.country_code?.replace("+", "")}${
      loginFormData?.mobile
    }&type=media&message=${encodeURIComponent(text)}&instance_id=${
      whatsAppObj?.refresh_token
    }&access_token=${whatsAppObj?.access_token}`;
    try {
      apiCall({ url, method: "get" })?.then((res) => {
        console.log("res whatsapp :: ", res);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMobileChange = (e) => {
    setLoginFormData((prev) => ({
      ...prev,
      mobile: e.target.value,
    }));

    // Clear mobile error when user starts typing
    if (mobileError && e.target.value.trim() !== "") {
      setMobileError("");
    }
  };

  const navigate = useNavigate();
  const languageData = ["English", "Hindi", "Telugu"]?.filter(
    (e) => e !== CURR_LANG
  );
  const [isLogin, setIsLogin] = useState(
    localStorage?.getItem(storage?.login)?.length
  );
  const [credentialModalVisible, setCredentialModalVisible] = useState(
    items?.showLoginModel
  );

  const [otpStatus, setOtpStatus] = useState(false);
  const [otpCheck, setOtpCheck] = useState("");
  const [registerFormSubmitted, setRegisterFormSubmitted] = useState(false);
  const [registerFormVisible, setRegisterFormVisible] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    mobile: "",
    name: "",
    email: "",
    dob: "",
    gender: "",
    nationality: "",
    ci_id: 0,
    joined_via: "CUSTOM",
    postal_code: "",
    fcm: "duhssjkh",
  });

  const [loginFormData, setLoginFormData] = useState({
    country_code: "",
    country_img: "",
    mobile: "",
    otp: "",
    fcm: "duhssjkh",
  });

  const loginModal = () => {
    setLoginFormData((prev) => ({
      ...prev,
      mobile: "",
      otp: "",
    }));
    setOtpStatus(false);
    setCredentialModalVisible(items?.showLoginModel);
    setRegisterFormVisible(false);
  };

  const [countryFlagData, setCountryFlagData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const getCountryData = () => {
    apiCall({ method: "GET", url: Constant?.apis?.countryAll }).then((res) => {
      if (res?.status) {
        const ind = res?.data?.filter((e) => e?.name?.toLowerCase() == "india");
        loginFormData.country_code = ind?.length > 0 ? ind[0]?.code : "+91";
        loginFormData.country_img =
          ind?.length > 0
            ? ind[0]?.imageFullUrl
            : `${Constant?.baseURL}assets/country/in.png`;
        setLoginFormData({ ...loginFormData });
        setCountryFlagData(
          res?.data?.map((e) => ({ label: e?.nationality, value: e?.id, ...e }))
        );
        setCountryData(
          res?.data?.map((e) => ({ label: e?.nationality, value: e?.id }))
        );
      }
    });
  };
  const getCityData = () => {
    apiCall({ method: "GET", url: Constant?.apis?.cityAll }).then((res) => {
      if (res?.status) {
        setCityData(res?.data?.map((e) => ({ label: e?.name, value: e?.id })));
      }
    });
  };

  useEffect(() => {
    getCountryData();
    getCityData();
    loginModal();
  }, [items?.showLoginModel]);

  // LOGIN RELATED SECTION START
  const manageOtpAction = () => {
    setOtpCheck("");

    if (loginFormData.country_code === "+91") {
      if (loginFormData?.mobile?.length !== 10) {
        return;
      }
    } else {
      setOtpStatus(true);
      return;
    }
    registerFormData.mobile = loginFormData?.mobile;
    setRegisterFormData({ ...registerFormData });

    sentOtp({
      ...loginFormData,
      phone: `${loginFormData?.country_code}${loginFormData?.mobile}`,
      id: "recaptcha-container",
    });
    setOtpStatus(true);
  };

  const checkOtpStatus = () => {
    setOtpCheck("");
    if (loginFormData?.otp?.length <= 0) {
      setOtpCheck("enter OTP");
      return;
    }

    if (isWhatsApp) {
      if (loginFormData.otp != whatsAppObj?.otp) {
        return toast.warn("Invalid OTP, Try Again");
      }

      apiCall({
        url: Constant?.apis?.userVerify,
        body: {
          mobile: `${loginFormData?.country_code}-${loginFormData?.mobile}`,
        },
      }).then((res) => {
        console.log("response user Verify :: ", res);
        if (res?.code == 1) {
          const body = {
            mobile: `${loginFormData?.country_code}-${loginFormData?.mobile}`,
            fcm: loginFormData?.fcm,
          };
          apiCall({ url: Constant?.apis?.userLogin, body }).then((res) => {
            console.log("response user Login :: ", res);
            if (res?.status) {
              localStorage.setItem(
                storage?.login,
                JSON.stringify({
                  ...res,
                  name: res?.name ?? "user",
                  token: res?.access_token,
                  amount: res?.amount ? res?.amount?.toFixed(2) : "0.00",
                })
              );
              setCredentialModalVisible(false);
              window.location.reload();
              return;
            }
          });
        } else {
          registerFormData.mobile = `${loginFormData?.country_code}-${loginFormData?.mobile}`;
          setRegisterFormData({ ...registerFormData });
          setRegisterFormVisible(true);
        }
      });
    } else {
      verifyOtp(loginFormData).then((res) => {
        loginFormData.fcm =
          localStorage?.getItem(storage?.systemToken) ?? res?.data?.user?.uid;
        setLoginFormData({ ...loginFormData });
        registerFormData.fcm =
          localStorage?.getItem(storage?.systemToken) ?? res?.data?.user?.uid;
        setRegisterFormData({ ...registerFormData });
        if (!res?.status) {
          setOtpCheck("invalid OTP");
          return;
        } else {
          apiCall({
            url: Constant?.apis?.userVerify,
            body: {
              mobile: `${loginFormData?.country_code}-${loginFormData?.mobile}`,
            },
          }).then((res) => {
            if (res?.code == 1) {
              const body = {
                mobile: `${loginFormData?.country_code}-${loginFormData?.mobile}`,
                fcm: loginFormData?.fcm,
              };
              apiCall({ url: Constant?.apis?.userLogin, body }).then((res) => {
                if (res?.status) {
                  localStorage.setItem(
                    storage?.login,
                    JSON.stringify({
                      ...res,
                      name: res?.name ?? "user",
                      token: res?.access_token,
                      amount: res?.amount ? res?.amount?.toFixed(2) : "0.00",
                    })
                  );
                  setCredentialModalVisible(false);
                  window.location.reload();
                  return;
                }
              });
            } else {
              registerFormData.mobile = `${loginFormData?.country_code}-${loginFormData?.mobile}`;
              setRegisterFormData({ ...registerFormData });
              setRegisterFormVisible(true);
            }
          });
        }
      });
    }
  };
  // LOGIN RELATED SECTION CLOSE

  const logOut = () => {
    localStorage?.setItem(storage?.login, "");
    setIsLogin(false);
    window.location.reload();
  };

  const onRegisterFormSubmit = () => {
    setRegisterFormSubmitted(!registerFormSubmitted);
    apiCall({ url: Constant?.apis?.userAdd, body: registerFormData }).then(
      (res) => {
        setTimeout(() => {
          setRegisterFormSubmitted(!registerFormSubmitted);
          if (res?.status) {
            localStorage.setItem(
              storage?.login,
              JSON.stringify({
                ...registerFormData,
                ...res,
                name: registerFormData?.name,
                token: res?.access_token,
                free: 0,
              })
            );
            window.location.reload();
            setCredentialModalVisible(false);
            setRegisterFormVisible(false);
          }
        }, 2000);
      }
    );
  };

  const [profileModelVisible, setProfileModelVisible] = useState(false);
  const [profileData, setProfileData] = useState({});

  const profileModel = (val = 0) => {
    if (val == "1") {
      setProfileModelVisible(!profileModelVisible);
      return;
    }
    apiCall({ method: "get", url: Constant?.apis?.myProfile }).then((res) => {
      if (res?.status) {
        const exp = res?.data?.mobile?.split("-");
        setProfileData({
          ...res?.data,
          view_mobile: exp?.length > 1 ? exp[1] : exp[0],
          nationalitydetail: {
            value: res?.data?.nationality,
            label: res?.data?.nationality_name,
          },
          citydetail: {
            value: res?.data?.ci_id,
            label: res?.data?.city,
          },
        });
        setProfileModelVisible(true);
      }
    });
  };

  const onProfileModelSubmit = () => {
    apiCall({ url: Constant?.apis?.userUpdate, body: profileData })?.then(
      (res) => {
        console.log("submitted :: ", res, profileData);
        if (!res?.status) {
          alert(res?.message);
        }
        if (res?.status) {
          const updateProfile = { ...userInfo, ...profileData };
          localStorage?.setItem(storage?.login, JSON?.stringify(updateProfile));
          setProfileModelVisible(false);
          window.location.reload();
        }
      }
    );
  };

  const keyPressEvent = (e = {}) => {
    if (e.key === "Enter" || e?.keyCode == 13) {
      const form = e?.target?.form;
      const index = Array?.prototype?.indexOf.call(form, e?.target);
      if (form?.elements[index + 1]) {
        form?.elements[index + 1]?.focus();
      }
      e?.preventDefault();
    }
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window?.location?.pathname);
  }, [window?.location?.pathname]);
  return (
    <>
      <ToastContainer />
      <Navbar expand="lg">
        <div className="nav-top">
          <Container>
            <Row className="justify-content-between align-items-center">
              <Col md="4">
                <div className="d-flex justify-content-between align-items-center">
                  <Navbar.Brand onClick={() => navigate("/home")}>
                    <img src={Logo} className="d-inline-block align-top" />
                  </Navbar.Brand>
                  <div>
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
                  </div>
                </div>
              </Col>
              <Col md="8" className="text-capitalize py-2">
                <div className="right-menu">
                  {communicate?.id > 0 &&
                    !["/chat", "/videocall"]?.includes(activeLink) && (
                      <div
                        className="alert-button px-3 py-1 ff-manrope fw-500 fs-14 rounded-3 cursor rounded-5 bg-danger text-white"
                        onClick={() => {
                          const communicate = JSON?.parse(
                            localStorage?.getItem(storage?.communicate)
                          );
                          const callStart = localStorage?.getItem(
                            storage?.callStart
                          );
                          localStorage?.setItem("fromContinue", 1);
                          localStorage?.setItem(
                            storage?.communicate,
                            JSON.stringify(communicate)
                          );
                          navigate(
                            communicate?.category == "CHAT"
                              ? "chat"
                              : "videocall",
                            {
                              state: { ...communicate, callStart },
                            }
                          );
                        }}
                      >
                        {communicate?.category}
                      </div>
                    )}
                  <Button
                    onClick={() => navigate("/astrologers")}
                    className="bg-transparent border-secondary rounded-5 text-black fs-12 fw-500"
                  >
                    <LangTrans val={"Consult Now"} />
                  </Button>
                  <Dropdown
                    data-bs-theme="transparent"
                    align="end"
                    className="text-end"
                  >
                    <Dropdown.Toggle
                      id="language-dropdown"
                      variant="transparent"
                      className="bg-white rounded-5 border-warning px-3"
                    >
                      <LangTrans val={CURR_LANG} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {languageData?.map((val) => {
                        return (
                          <Dropdown.Item
                            href="javascript:void(0);"
                            onClick={() => {
                              localStorage?.setItem(storage?.language, val);
                              window.location.reload();
                            }}
                          >
                            <LangTrans val={val} />
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {isLogin ? (
                    <Dropdown
                      data-bs-theme="transparent"
                      align="end"
                      className="text-end"
                    >
                      <Dropdown.Toggle
                        id="profile-dropdown"
                        variant="transparent"
                        className="bg-white rounded-5 border-warning px-3 text-capitalize"
                      >
                        {userInfo?.name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => profileModel()}
                        >
                          <LangTrans val={"My Profile"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => navigate("/history")}
                        >
                          <LangTrans val={"History"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => navigate("/recharge")}
                        >
                          <LangTrans val={"Recharge"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => logOut()}
                        >
                          <LangTrans val={"Logout"} />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Button
                      // onClick={() => loginModal()}
                      onClick={() => dispatch(userLoginModel(true))}
                      className="rounded-5 bg-transparent text-black border-dark fw-500 ff-manrope text-capitalize"
                    >
                      sign in
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="nav-bottom bg-white">
          <Container>
            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-lg"
              aria-labelledby="offcanvasNavbarLabel-expand-lg"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-block">
                <Nav
                  className="d-flex justify-content-between text-capitalize"
                  variant="underline"
                >
                  <Nav.Item>
                    <Nav.Link
                      //   eventKey="Home"
                      className={
                        ["/home", "/"]?.includes(activeLink) ? "active" : " "
                      }
                      onClick={() => navigate("/home")}
                    >
                      <LangTrans val={"Home"} className="bg-danger" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      //   eventKey="Talk with Astrologer"
                      className={activeLink === "/astrologers" ? "active" : ""}
                      onClick={() => navigate("/astrologers")}
                    >
                      <LangTrans val={"Talk with Astrologer"} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      //   eventKey="Free Kundali"
                      className={activeLink === "/free-kundli" ? "active" : ""}
                      onClick={() => navigate("/free-kundli")}
                    >
                      <LangTrans val={"Free Kundali"} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      //   eventKey="Match Kundali"
                      className={activeLink === "/match-kundli" ? "active" : ""}
                      // onClick={() => navigate("/match-kundli")}
                      // className={activeLink === "/free-kundli" ? "active" : ""}
                      onClick={() => navigate("/match-kundli")}
                    >
                      <LangTrans val={"Match Kundali"} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      //   eventKey="Daily Horoscope"
                      className={
                        activeLink === "/daily-horoscope" ? "active" : ""
                      }
                      onClick={() => navigate("/daily-horoscope")}
                    >
                      <LangTrans val={"Daily Horoscope"} />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </div>
      </Navbar>
      <Modal
        show={credentialModalVisible}
        onHide={() => dispatch(userLoginModel(false))}
        backdrop="static"
        // keyboard={false}
        centered
        className={`login-modal ${registerFormVisible && "modal-lg"}`}
      >
        <Modal.Header closeButton className="primary-bg-color">
          {otpStatus && (
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                onClick={() => setOtpStatus(!otpStatus)}
              />
            </div>
          )}
          <Modal.Title className="w-100 text-center">
            <LangTrans
              val={
                registerFormVisible
                  ? "Complete Your Profile"
                  : `${otpStatus ? "verify mobile" : "Continue With Mobile"}`
              }
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {registerFormVisible ? (
            <Form onSubmit={handleSubmit2}>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      mobile
                    </label>
                    <Form.Control
                      placeholder="Enter Mobile No"
                      value={registerFormData?.mobile}
                      disabled
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      name
                    </label>
                    <Form.Control
                      placeholder="Enter name"
                      // required
                      value={registerFormData?.name}
                      onChange={(e) => {
                        registerFormData.name = e?.target?.value;
                        setRegisterFormData({ ...registerFormData });
                        setNameError1("");
                      }}
                      onKeyDown={(e) => keyPressEvent(e)}
                    />
                    {nameError1 && (
                      <span className="text-danger">{nameError1}</span>
                    )}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      email
                    </label>
                    <Form.Control
                      placeholder="Enter email"
                      value={registerFormData?.email}
                      onChange={(e) => {
                        registerFormData.email = e?.target?.value;
                        setRegisterFormData({ ...registerFormData });
                        setEmailError1("");
                      }}
                      onKeyDown={(e) => keyPressEvent(e)}
                    />
                    {emailError1 && (
                      <span className="text-danger">{emailError1}</span>
                    )}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3 d-flex flex-column">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      dob
                    </label>
                    <Form.Control
                      // placeholder="Enter email"
                      type="date"
                      required
                      value={
                        registerFormData.dob
                          ? moment(registerFormData.dob).format("YYYY-MM-DD")
                          : ""
                      }
                      max={moment().format("YYYY-MM-DD")}
                      onChange={(e) => {
                        console.log("ss L:: ", e?.target?.value);
                        registerFormData.dob = e?.target?.value;
                        setRegisterFormData({ ...registerFormData });
                      }}
                      onKeyDown={(e) => keyPressEvent(e)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      country
                    </label>
                    <Select
                      options={countryData}
                      value={registerFormData?.nationalitydetail}
                      required
                      onChange={(values) => {
                        registerFormData.nationality = values?.value;
                        registerFormData.nationalitydetail = values;
                        console.log(
                          "ss ::",
                          values,
                          registerFormData.nationality
                        );
                        setRegisterFormData({ ...registerFormData });
                      }}
                      className="rounded"
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      city
                    </label>
                    <Select
                      options={cityData}
                      value={registerFormData?.citydetail}
                      required
                      onChange={(values) => {
                        registerFormData.ci_id = values?.value;
                        registerFormData.citydetail = values;
                        setRegisterFormData({ ...registerFormData });
                      }}
                      className="rounded"
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3 d-flex flex-column">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      postal code
                    </label>
                    <Form.Control
                      placeholder="Enter postal code"
                      // type='number'
                      value={registerFormData?.postal_code}
                      onChange={(e) => {
                        registerFormData.postal_code = e?.target?.value;
                        setRegisterFormData({ ...registerFormData });
                        setPostalCodeError1("");
                      }}
                      onKeyDown={(e) => keyPressEvent(e)}
                    />
                    {postalCodeError1 && (
                      <span className="text-danger">{postalCodeError1}</span>
                    )}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                      gender
                    </label>
                    <div className="d-flex gap-1 text-capitalize border rounded p-2">
                      {["FEMALE", "MALE", "OTHER"]?.map((row) => {
                        return (
                          <Form.Check
                            type="radio"
                            id={`gender-${row}`}
                            name="gender"
                            label={row}
                            value={row}
                            onChange={(e) => {
                              registerFormData.gender = e?.target?.value;
                              setRegisterFormData({ ...registerFormData });
                            }}
                            onKeyDown={(e) => keyPressEvent(e)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <Button
                    type="submit"
                    className="primary-bg-color w-100 text-black border-0"
                    disabled={registerFormSubmitted}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <>
              {otpStatus ? (
                <Form onSubmit={handleSubmit(checkOtpStatus)}>
                  <Row className="justify-content-center align-items-center otp-box text-capitalize">
                    <Col lg="8" className="text-center px-4">
                      <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                        otp sent to {loginFormData?.country_code}-
                        {loginFormData?.mobile}
                      </label>
                    </Col>
                    <Col lg="12" className="p-4 otp-input">
                      <OTPInput
                        value={loginFormData?.otp}
                        onChange={(e) => {
                          setLoginFormData((prev) => ({
                            ...prev,
                            otp: e,
                          }));
                        }}
                        onKeyDown={(e) => keyPressEvent(e)}
                        numInputs={6}
                        shouldAutoFocus={true}
                        renderInput={(props) => <input {...props} />}
                      />
                      <span className="text-danger">{otpCheck}</span>
                    </Col>
                    <Col lg="12" className="text-center px-4">
                      <Button
                        type="submit"
                        className="credential-submit d-flex align-items-center justify-content-center w-100 text-capitalize"
                      >
                        login
                      </Button>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Form onSubmit={handleSubmit(manageOtpAction)}>
                  <Row className="justify-content-center align-items-center">
                    <Col lg="8" className="text-center px-5">
                      <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                        <LangTrans val="You will receive a 6 digit code for verification" />
                      </label>
                    </Col>
                    <Col lg="12" className="p-4">
                      <label className="mb-2 ff-manrope text-grey-700 text-capitalize">
                        <LangTrans val="Enter Mobile No." />
                      </label>
                      <InputGroup className="country-code-group">
                        <DropdownButton
                          variant="bg-transparent"
                          title={
                            <Image
                              src={loginFormData?.country_img}
                              width={25}
                              height={20}
                            />
                          }
                        >
                          <Dropdown.Item onClick={(e) => stopPropagation(e)}>
                            <FormControl
                              className="border border-2 rounded-3"
                              type="text"
                              placeholder="Search country..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </Dropdown.Item>

                          {filteredData.map((row) => (
                            <Dropdown.Item
                              key={row.code}
                              onClick={() => {
                                setLoginFormData((prev) => ({
                                  ...prev,
                                  country_code: row.code,
                                  country_img: row.imageFullUrl,
                                }));
                              }}
                            >
                              <Image
                                src={row.imageFullUrl}
                                width={25}
                                height={20}
                              />{" "}
                              {row.code}-{row.name}
                            </Dropdown.Item>
                          ))}

                          {searchQuery === "" &&
                            countryFlagData.map((row) => (
                              <Dropdown.Item
                                key={row.code}
                                onClick={() => {
                                  setLoginFormData((prev) => ({
                                    ...prev,
                                    country_code: row.code,
                                    country_img: row.imageFullUrl,
                                  }));
                                }}
                              >
                                <Image
                                  src={row.imageFullUrl}
                                  width={25}
                                  height={20}
                                />{" "}
                                {row.code}-{row.name}
                              </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <Form.Control
                          placeholder="Enter Mobile No"
                          type="text"
                          value={loginFormData?.mobile}
                          onKeyDown={(e) => {
                            const key = e.keyCode || e.which;
                            const input = e.target;
                            const start = input.selectionStart;
                            const end = input.selectionEnd;
                            // key = 8 backspace code
                            if (key === 8) {
                              if (start !== end) {
                                setLoginFormData((prev) => ({
                                  ...prev,
                                  mobile:
                                    prev.mobile.slice(0, start) +
                                    prev.mobile.slice(end),
                                }));
                              } else if (start > 0) {
                                setLoginFormData((prev) => ({
                                  ...prev,
                                  mobile:
                                    prev.mobile.slice(0, start - 1) +
                                    prev.mobile.slice(start),
                                }));
                              }
                            } else if (
                              (key >= 48 && key <= 57) ||
                              (key >= 96 && key <= 105)
                            ) {
                              if (
                                loginFormData.mobile.length < 10 ||
                                start !== end
                              ) {
                                setLoginFormData((prev) => ({
                                  ...prev,
                                  mobile:
                                    prev.mobile.slice(0, start) +
                                    e.key +
                                    prev.mobile.slice(end),
                                }));
                              }
                            } else {
                              e.preventDefault(); // Prevent any other key presses
                            }
                          }}
                        />
                      </InputGroup>
                      <span
                        className={`${
                          loginFormData.country_code === "+91" &&
                          loginFormData.mobile.length > 0 &&
                          loginFormData.mobile.length !== 10
                            ? "text-danger fw-500"
                            : "d-none"
                        }`}
                      >
                        {loginFormData.mobile.length !== 10
                          ? "Mobile pattern not match with India"
                          : " "}
                      </span>
                    </Col>
                    <Col lg="12" className="text-center px-4">
                      <Button
                        onClick={handleGetOTP}
                        type="submit"
                        className="credential-submit d-flex align-items-center justify-content-center w-100 text-capitalize"
                      >
                        <LangTrans val="GET OTP" />{" "}
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={profileModelVisible}
        onHide={() => profileModel(1)}
        backdrop="static"
        centered
        className="login-modal modal-lg"
      >
        <Modal.Header closeButton className="primary-bg-color">
          <Modal.Title className="w-100 text-center">
            <LangTrans val="My Profile" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit1}>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Mobile" />
                  </label>
                  <Form.Control
                    placeholder="Enter Mobile No"
                    value={profileData?.view_mobile}
                    disabled
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Name" />
                  </label>
                  <Form.Control
                    placeholder="Enter name"
                    value={profileData?.name}
                    onChange={(e) => {
                      profileData.name = e.target.value;
                      setProfileData({ ...profileData });
                      setNameError("");
                    }}
                    onKeyDown={(e) => keyPressEvent(e)}
                  />
                  {nameError && (
                    <span className="text-danger">{nameError}</span>
                  )}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Email" />
                  </label>
                  <Form.Control
                    placeholder="Enter email"
                    value={profileData?.email}
                    onChange={(e) => {
                      profileData.email = e.target.value;
                      setProfileData({ ...profileData });
                      setEmailError("");
                    }}
                    onKeyDown={(e) => keyPressEvent(e)}
                  />
                  {emailError && (
                    <span className="text-danger">{emailError}</span>
                  )}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3 d-flex flex-column">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Date of Birth" />
                  </label>
                  <Form.Control
                    type="date"
                    value={
                      profileData.dob
                        ? moment(profileData.dob).format("YYYY-MM-DD")
                        : ""
                    }
                    required
                    max={moment().format("YYYY-MM-DD")}
                    onChange={(e) => {
                      profileData.dob = e.target.value;
                      setProfileData({ ...profileData });
                    }}
                    onKeyDown={(e) => keyPressEvent(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Country" />
                  </label>
                  <Select
                    options={countryData}
                    value={profileData?.nationalitydetail}
                    required
                    onChange={(values) => {
                      profileData.nationality = values.value;
                      profileData.nationalitydetail = values;
                      setProfileData({ ...profileData });
                    }}
                    className="rounded"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="City" />
                  </label>
                  <Select
                    options={cityData}
                    value={profileData?.citydetail}
                    required
                    onChange={(values) => {
                      profileData.ci_id = values.value;
                      profileData.citydetail = values;
                      setProfileData({ ...profileData });
                    }}
                    className="rounded"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3 d-flex flex-column">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Postal Code" />
                  </label>
                  <Form.Control
                    placeholder="Enter postal code"
                    value={profileData?.postal_code}
                    onChange={(e) => {
                      profileData.postal_code = e.target.value;
                      setProfileData({ ...profileData });
                      setPostalCodeError("");
                    }}
                    onKeyDown={(e) => keyPressEvent(e)}
                    className="p-2"
                  />
                  {postalCodeError && (
                    <span className="text-danger">{postalCodeError}</span>
                  )}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <label className="mb-1 ff-manrope text-grey-700 text-capitalize">
                    <LangTrans val="Gender" />
                  </label>
                  <div className="d-flex gap-1 text-capitalize border rounded p-2">
                    {["FEMALE", "MALE", "OTHER"]?.map((row) => (
                      <Form.Check
                        type="radio"
                        id={`gender-${row}`}
                        name="gender"
                        label={<LangTrans val={row} />}
                        value={row}
                        required
                        checked={profileData?.gender === row}
                        onChange={(e) => {
                          profileData.gender = e.target.value;
                          setProfileData({ ...profileData });
                        }}
                        onKeyDown={(e) => keyPressEvent(e)}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg="12">
                <Button
                  type="submit"
                  className="primary-bg-color w-100 text-black border-0"
                >
                  <LangTrans val="Update" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <div id="recaptcha-container"></div>
    </>
  );
};

export default Header;
