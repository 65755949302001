import Astrologers from "../view/astrologer/astrologers";
import AstrologerDetail from "../view/astrologer/astrologerDetail";
import Home from "../view/home/home";
import History from "../view/history/history";
import Pdf from "../view/history/pdf";
import Recharge from "../view/recharge/recharge";
import Success from "../view/recharge/success";
import Failure from "../view/recharge/failure";
import Page from "../view/informative/page";
import FaqPage from "../view/informative/faq";
import DailyHoroscope from "../view/horoscope/dailyhoroscope";
import ContactUs from "../view/informative/contantus";
import FreeKundli from "../view/horoscope/freekundli";
import MatchKundli from "../view/horoscope/matchkundli";
import FreeKundliReport from "../view/horoscope/freekundlireport";
import Communicate from "../view/communicate/communicate";
import Chat from "../view/communicate/chat";
import VideoCall from "../view/communicate/videoCall";
import Notification from "../view/notification";
import MatchKundliReport from "../view/horoscope/matchkundlireport";

export const routes = [
    {
      path: '/',
      element: <Home />,
    },
    {
        path: '/home',
        element: <Home />,
    },
    {
        path:'/astrologers',
        element:<Astrologers />
    },
    {
      path:'/astrologer-detail',
      element:<AstrologerDetail />,
    },
    {
      path:'/history',
      element:<History />,
    },
    {
      path:'/recharge',
      element:<Recharge />,
    },
    {
      path:'/success',
      element:<Success />,
    },
    {
      path:'/failure',
      element:<Failure />,
    },
    {
      path:'/aboutus',
      element:<Page />,
    },
    {
      path:'/contactus',
      element:<ContactUs />,
    },
    {
      path:'/privacy-policy',
      element:<Page />,
    },
    {
      path:'/terms-condition',
      element:<Page />,
    },
    {
      path:'/refund-policy',
      element:<Page />,
    },
    {
      path:'/faq',
      element:<FaqPage />,
    },
    {
      path:'/invoice',
      element:<Pdf />,
    },
    {
      path:'/daily-horoscope',
      element:<DailyHoroscope />,
    },
    {
      path:'/free-kundli',
      element:<FreeKundli />,
    },
    {
      path:'/free-kundli-report',
      element:<FreeKundliReport />,
    },
    {
      path:'/match-kundli',
      element:<MatchKundli />,
    },
    {
      path:'/communicate',
      element:<Communicate />,
    },
    {
      path:'/chat',
      element:<Chat />
    },
    {
      path: '/videocall',
      element: <VideoCall />,
    },
    {
      path: '/notification',
      element: <Notification />,
    },
    {
      path: '/notification/:data',
      element: <Notification />,
    },
    {
      path: '/match-kundli-report',
      element: <MatchKundliReport />,
    }
];