import { React } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Faq from "../../Components/Content/Faq";

const FaqPage = () => {
    
    return (
        <div className="home-page">
            <div>
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="page-content pt-3">
                                <Faq />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default FaqPage;