import { React, useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LangTrans from "../../Components/Helper/LangHelper"
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment/moment";
import { apiCall } from '../../Components/Helper/ApiHelper';
import Constant from "../../config/Constant";
import { useNavigate } from "react-router";
import noDataImg from "../../assets/images/no_data.png"


const DailyHoroscope = () => {

    const navigate = useNavigate()

    const [horoScopeData, setHoroScopeData] = useState({})
    const [horoScopeTypeData, setHoroScopeTypeData] = useState(['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'])
    const horoScopeColor = ['#f8e3b4', '#d4d3f8', '#ffe0c3', '#b6e7f7', '#fed4dc', '#bdeade', '#f8e4b1', '#d3d3f7', '#ffe0c2'];
    const cardBgColorData = ['#fdf4e9', '#fae9eb', '#fefae9', '#e5f6ed'];
    const cardOtherData = ['success', 'warning', 'primary', 'info', 'danger'];
    const [horoScopeFilterData, setHoroScopeFilterData] = useState({
        sign:'ARIES',
        date_num:0,
        date:moment()?.format('YYYY-MM-DD'),
        timezone:moment()?.format('Z') == '+05:30' ? 5.5 : 0.0
    })

    const getHoroScopeData = () => {
        apiCall({url:Constant?.apis?.horoScopeDaily, body:{...horoScopeFilterData}, isLogin:true})?.then((res) => setHoroScopeData({...res?.data?.prediction}))
    }

    console.log('timezone :: ', moment()?.format('Z'), moment().add(1, 'days')?.format('YYYY-MM-DD'), moment()?.parseZone(), horoScopeFilterData)
    
    useEffect(() => {
        document.title = 'Daily Horoscope : Astroguide'
        getHoroScopeData()
    }, [horoScopeFilterData])

    return (
        <div className="horoscope-page">
            <div className="bg-white">
                <Container className="py-2">
                    <Row>
                        <Col lg='12'>
                            <div className="d-flex gap-2 align-items-center p-1">
                                <FontAwesomeIcon  onClick={() => navigate('/home')} className="primary-color" icon={faHome}/>
                                <FontAwesomeIcon className="text-black" icon={faAngleRight}/>
                                <label className="pl-1 ff-manrope fs-13 fw-bold">
                                    <LangTrans val={'Daily Horoscope'} />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 justify-content-center">
                        <Col lg="12">
                            <div className="d-flex gap-5 align-items-center overflow-scroll w-100 zodiac">
                            {
                                horoScopeTypeData?.map((val, index) => {
                                    const color = horoScopeColor[index % horoScopeColor?.length]
                                    return (
                                        <div className={`${horoScopeFilterData?.sign == val?.toUpperCase() ? 'border-2 primary-bg-color rounded-top-5 primary-border-color' : ''}`} style={{padding:horoScopeFilterData?.sign == val?.toUpperCase() ? '2px' : ''}}>
                                            <Button
                                                className={`rounded-5 ${horoScopeFilterData?.sign == val?.toUpperCase() ? 'border primary-border-color' : ''}`}
                                                style={{backgroundColor:horoScopeFilterData?.sign == val?.toUpperCase() ? '#ffffff' : color}}
                                                onClick={() => {
                                                    horoScopeFilterData.sign = val?.toUpperCase()
                                                    setHoroScopeFilterData({...horoScopeFilterData})
                                                }}
                                            >
                                                {val?.slice(0, 1)}                                                
                                            </Button>
                                            <span>{val}</span>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </Col>
                        <Col lg="12">
                            <Row className="justify-content-between py-4">
                                <Col lg='6'>
                                    <div className="d-flex justify-content-start h-100 align-items-center">
                                        <span className="rounded-2 primary-bg-color px-4 py-1 ff-manrope fw-bold">{horoScopeFilterData?.sign}</span>
                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div className="w-100 d-flex justify-content-end align-items-center gap-3 zodiac-day">
                                    {
                                        [-1, 0, 1]?.map((row, index)=> {
                                            return <Button
                                                className={horoScopeFilterData.date_num == row ? 'clicked' : ''}
                                                onClick={() => {
                                                    horoScopeFilterData.date_num = row;
                                                    horoScopeFilterData.date = moment().add(row, 'days')?.format('YYYY-MM-DD');
                                                    setHoroScopeFilterData({...horoScopeFilterData})
                                                }}
                                            >{row > 0 ? 'tomorrow' : (row == -1 ? 'yesterday' : 'today')}</Button>
                                        })
                                    }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        {
                            Object?.keys(horoScopeData)?.map((row, index) => {
                                const color = cardBgColorData[index % cardBgColorData?.length]
                                const colorOther = cardOtherData[index % cardOtherData?.length]
                                return (
                                    <Col lg='6' ms='12' xs='12'>
                                        <div className="p-2 h-100">
                                            <Card style={{backgroundColor:color}} className={`h-100 border-${colorOther}`}>
                                                <Card.Header
                                                    className={`border-0 bg-transparent text-capitalize fw-bold ff-poppins text-${colorOther}`}
                                                >{row}</Card.Header>
                                                <Card.Body>
                                                {
                                                    Array?.isArray(horoScopeData[row]) ? (
                                                        <ul class="list-group">
                                                        {
                                                            horoScopeData[row]?.map((val) => <li className="list-group-item bg-transparent border-0 p-1 fs-13 ff-poppins text-justify fw-bold text-grey-500">{val}</li>)
                                                        }
                                                        </ul>
                                                    ) : (
                                                        <p className="fs-13 ff-poppins text-justify fw-bold text-grey-500">{horoScopeData[row]}</p>
                                                    )
                                                }
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                        {
                            Object?.keys(horoScopeData)?.length == 0 ? (
                                <>
                                    <Col lg="12" >
                                        <div className="text-center">
                                            <Image
                                                src={noDataImg}
                                            />
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default DailyHoroscope;