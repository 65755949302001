import { React, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFile,
  faForward,
  faImage,
  faMicrophone,
  faPhone,
  faPlay,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "axios";
import Constant, { apiBaseURL, storage, userInfo  } from "../../config/Constant";

import AudioPlayer from "react-h5-audio-player";
import "../../../node_modules/react-h5-audio-player/lib/styles.css";

// SOCKET CONNECTION
import { io } from "socket.io-client";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import ReviewModal from "./ReviewModal";
import { toast } from "react-toastify";

import Loader from "../../Components/Loader/Loader";
import { reload } from "firebase/auth";

const Chat = () => {
  // useRef start -----
  const containerRef = useRef(null);
  const div = useRef(null);
  const inputRef = useRef(null);
  // useRef close -----

  const navigate = useNavigate();

  const stateData = JSON.parse(localStorage.getItem(storage?.communicate));
  const activeAction = localStorage.getItem(storage?.activeAction);

  const [loading, setLoading] = useState(true);
  const [audioPlayModal, setAudioPlayModal] = useState(false);
  const [audioPlay, setAudioPlay] = useState("");
  const [currentScreen, setCurrentScreen] = useState(
    activeAction?.length > 0 ? activeAction : "calling"
  );
  const [chatCounting, setChatCounting] = useState(["00", "00", "00"]);
  const [mediaShow, setMediaShow] = useState(false);
  const mediaType = {
    img: "image/*",
    doc: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    aud: "audio/*",
  };
  const [currMediaType, setCurrMediaType] = useState("img");
  const [reviewModal, setReviewModal] = useState(false);

  const [chatData, setChatData] = useState([]);
  const username = userInfo?.token || "";
  const [senderData, setSenderData] = useState({
    username,
    astro_id: stateData?.astro_id,
    ch_id: stateData?.ch_id,
    sender: "U",
    m_id: -1,
    type: "M",
    m_type: "T",
    message: "",
  });
  // const socketRef = useRef(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (senderData?.message?.length <= 0) {
        toast.warn("Message required");
        return;
      }
      sendMessage();
      setSenderData({
        ...senderData,
        message: "",
      });
    }
  };
  const [socket1, setSocket1] = useState(null);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   if (socket1.current) {
  //     socket1.current.disconnect();
  //   }
  // };
  // Store socket connection details in sessionStorage
  // sessionStorage.setItem("socketId", socket.id);
  // const savedSocketId = sessionStorage.getItem("socketId");
  // const socket1 = io.connect("https://astroguide4u.com:9000/api/", {
  //   reconnection: true,
  //   reconnectionDelay: 1000,
  //   query: {
  //     token: savedSocketId,
  //   },
  // });

  const data = {
    username: userInfo?.token,
    ch_id: stateData?.ch_id,
    sender: "U",
    user_id: stateData?.astro_id,
  };

  const socket = io(apiBaseURL, {
    transports: ["websocket"],
    query: data,
  });

  socket.on("connect_error", (error) => {
    console.error("Socket connection error:", error);
    window.location.reload();
  });

  socket.on("connect", () => {
    console.log("Socket connected");
    socket.emit("join", userInfo?.id);
  });

  // function for get chat history data start -----
  const getChatHistoryData = async () => {
    let config = {
      method: "POST",
      url: Constant?.apis?.chatHistory,
      maxBodyLength: Infinity,
      headers: Constant?.header,
      data: {
        offset: "0",
        astro_id: senderData?.astro_id,
        ch_id: senderData?.ch_id,
        sender: "U",
      },
    };
    try {
      const response = await axios.request(config);
      setChatData(response?.data?.data?.reverse() || []);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      toast.error("Failed to fetch chat history. Please try again later.");
    }
  };

  useEffect(() => {
    
    setTimeout(() => {
      getChatHistoryData();
    }, 2000);
  }, []);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [containerRef, chatData]);
  // Function for count seconds start -----

  const getTimeCount = (
    status = localStorage?.getItem(storage?.activeAction)
  ) => {
    if (status == "active") {
      var a = moment();
      var b = localStorage?.getItem(storage?.chatStart);
      const totalSeconds = a.diff(b, "seconds");
      const totalMinutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      chatCounting[0] = hours > 9 ? hours : `0${hours}`;
      chatCounting[1] = minutes > 9 ? minutes : `0${minutes}`;
      chatCounting[2] = seconds > 9 ? seconds : `0${seconds}`;
      setChatCounting([...chatCounting]);
      setTimeout(() => {
        if (stateData?.free == 1 && minutes >= 10) {
          socket?.emit("end", {
            ...data,
            reason: "User ended the chat",
          });
          
          changeScreenValue("endUser");
          setReviewModal(true);
        }
        getTimeCount(localStorage?.getItem(storage?.activeAction));
      }, 1000);
    }
  };
  // Function for count seconds close -----

  // Function for count seconds start -----
  const getAudioTimeCount = (time = "") => {
    if (time?.length > 0) {
      var a = moment();
      var b = time;
      const totalSeconds = a.diff(b, "seconds");
      const totalMinutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      audioTime[0] = hours > 9 ? hours : `0${hours}`;
      audioTime[1] = minutes > 9 ? minutes : `0${minutes}`;
      audioTime[2] = seconds > 9 ? seconds : `0${seconds}`;
      setAudioTime([...audioTime]);
      setTimeout(() => {
        getAudioTimeCount(time);
      }, 1000);
    }
  };
  // Function for count seconds close -----

  // ------------------------------ AUDIO RELATED START
  const mimeType = "audio/m4a";
  const [audioTime, setAudioTime] = useState(["00", "00", "00"]);

  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [audioStatus, setAudioStatus] = useState(false);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        toast.warn(err.message);
      }
    } else {
      toast.warn("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setCurrMediaType("aud");
    setMediaShow(false);
    setRecordingStatus("recording");

    const media = new MediaRecorder(stream, { type: mimeType });

    mediaRecorder.current = media;

    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
    setAudioStatus(true);
    setAudioTime(["00", "00", "00"]);
    getAudioTimeCount(
      moment()?.subtract(1, "second")?.format("YYYY-MM-DD HH:mm:ss")
    );
  };

  const stopRecording = () => {
    getAudioTimeCount("");
    setCurrMediaType("");
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });

      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setAudioChunks([]);

      const file = new File(
        [audioBlob],
        `audio_web_${moment()?.format("DDMMYYYYHHMMSS")}.m4a`,
        { type: mimeType }
      );
      setMedia(file, "aud");
      setAudioStatus(false);
    };
  };
  // ------------------------------ AUDIO RELATED CLOSE

  // function for disconnect the chat close -----
  useEffect(() => {
    if (userInfo?.token === undefined || userInfo?.token?.length <= 0) {
      toast.warn("Login Required");
      window.location = Constant?.baseURL;
      return false;
    }
    if (localStorage.getItem(storage?.communicate) == null) {
      toast.warn("Chat communicate session expired... Please try later");
      navigate("/history", {
        state: {
          tab: "Chat",
        },
      });
      return;
    }
    setLoading(false);

    getChatHistoryData();
    getMicrophonePermission();
    if (currentScreen == "active") {
      getTimeCount(currentScreen);
    }
  }, []);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [containerRef, chatData]);

  // =================================================================================
  // WEBSOCKET RELATED START ----

  // Msg From Astro
  socket.on("message", (data) => {
    const res = JSON?.parse(data);
    setChatData([...chatData, ...[res?.data]]);
  });

  socket.on("initiate", (data) => {
    const res = JSON.parse(data);
    localStorage?.setItem(storage?.chatStartSrv, res?.started_at);
    localStorage?.setItem(
      storage?.chatStart,
      moment()?.subtract(1, "second")?.format("YYYY-MM-DD HH:mm:ss")
    );
    getChatHistoryData();
    getTimeCount("active");
    changeScreenValue("active");
  });

  // End By Astro
  socket.on("end", (data) => {
    localStorage.removeItem(storage?.communicate);
    const res = JSON.parse(data);
    changeScreenValue("endAstro");
    getTimeCount("endAstro");
    const leftAmt = parseFloat(userInfo?.amount) - parseFloat(res?.amount);
    const loginData = {
      ...userInfo,
      amount: leftAmt?.toFixed(2),
      free: ["paid", "PAID"]?.includes(res?.chat_type) ? 1 : "0",
    };
    localStorage?.setItem(storage?.login, JSON?.stringify(loginData));
    setReviewModal(true);
  });

  // Reject By Astro
  socket.on("reject", (data) => changeScreenValue("astroReject"));

  socket.on("waitlist", (data) => changeScreenValue("astroWaitList"));

  // const sendMessage = () => {
  //   if (senderData?.message?.length <= 0) {
  //     toast.warn('Message required');
  //     return;
  //   }
  //   socket?.emit("message", senderData);

  //   // Clear message after sending
  //   setSenderData({
  //     ...senderData,
  //     message: ""  // Clear message content
  //   });

  //   setMediaShow(false);
  //   // Optional: Update chat history immediately after sending
  //   getChatHistoryData();
  // };

  // useEffect(() => {
  //   socket?.emit("join", userInfo?.id);
  // }, []);

  useEffect(() => {
    socket?.emit("getChatHistory", userInfo?.id);
  }, []);

  const sendMessage = () => {
    if (senderData?.message?.length <= 0) {
      toast.warn("Message required");
      return;
    }
    socket?.emit("message", senderData);
    setSenderData({
      ...senderData,
      message: "",
    });
    const newMessage = { ...senderData, timestamp: Date.now() };
    setChatData([...chatData, newMessage]);

    setMediaShow(false);
  };

  const changeScreenValue = (val = "") => {
    if (val?.length > 0) {
      setCurrentScreen(val);
      localStorage.setItem(storage?.activeAction, val);
    }
  };

  const endChat = () => {
    if (parseInt(chatCounting[2]) > 0) {
      if (window.confirm("Are sure for close the communication??")) {
        socket?.emit("end", {
          ...data,
          reason: "User ended the chat",
        });
        changeScreenValue("endUser");
        setReviewModal(true);
      }
    } else {
      socket?.emit("cancel", {
        ...data,
        reason: "Chat was cancelled by user",
      });
      changeScreenValue("endUser");

      localStorage.removeItem(storage?.activeAction);
      localStorage.removeItem(storage?.chatStatus);
      localStorage.removeItem(storage?.communicate);
      localStorage.removeItem(storage?.communicatePlatform);

      navigate("/history", {
        state: {
          tab: "Chat",
        },
      });
    }
  };

  const ChatBox = () => {
    return (
      <>
        {chatData?.map((row, key) => (
          <div
            key={key}
            className={`d-flex flex-column w-100 ${
              row?.sender === "A" ? "them" : "me"
            }`}
          >
            {row?.m_type == "V" && (
              <div className="doc" style={{ minWidth: "163px" }}>
                <div className="d-flex h-100 gap-1 cursor pt-1">
                  <div
                    onClick={() => {
                      setAudioPlay(row?.full_message_url);
                      setAudioPlayModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                  <div className="fs-14">{/* row?.message */}</div>
                </div>
                <label className="time ff-manrope fs-12 text-black text-center">
                  {moment(row?.sent_at)?.format("DD, MMM YYYY hh:mm A")}
                </label>
              </div>
            )}
            {row?.m_type == "I" && (
              <div
                className="img cursor"
                onClick={() => window.open(row?.full_message_url, "_blank")}
              >
                <Image src={row?.full_message_url} />
                <label className="time ff-manrope fs-13 text-black">
                  {moment(row?.sent_at)?.format("DD, MMM YYYY hh:mm A")}
                </label>
              </div>
            )}
            {row?.m_type == "D" && (
              <div
                className="doc"
                onClick={() => window.open(row?.full_message_url, "_blank")}
              >
                <div className="d-flex h-100 gap-1 cursor pt-1">
                  <div>
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                  <div className="fs-14">{row?.message}</div>
                </div>
                <label className="time ff-manrope fs-12 text-black text-center">
                  {moment(row?.sent_at)?.format("DD, MMM YYYY hh:mm A")}
                </label>
              </div>
            )}
            {row?.m_type == "T" && (
              <>
                <div className="single p-2">{row?.message}</div>
                <label className="time">
                  {moment(row?.sent_at)?.format("DD, MMM YYYY hh:mm A")}
                </label>
              </>
            )}
          </div>
        ))}
      </>
    );
  };

  const setMedia = (file = {}, type = "img") => {
    if (file?.name?.length > 0) {
      senderData.m_type = type == "img" ? "I" : type == "doc" ? "D" : "V";
      senderData.message = file;
      senderData.filename = file?.name;
      senderData.ext = file?.name?.split(".")[1];

      setSenderData({ ...senderData });
      sendMessage();
    }
  };

  // function for close chat screen start -----
  const closeChat = () => {
    localStorage.removeItem(storage?.activeAction);
    localStorage.removeItem(storage?.chatStatus);
    localStorage.removeItem(storage?.communicate);
    localStorage.removeItem(storage?.communicatePlatform);

    navigate("/history", {
      state: {
        tab: "Chat",
      },
    });
  };
  // function for close chat screen close -----

  const openMedialUploader = (type = "img") => {
    setCurrMediaType(type);
    if (type != "aud") {
      setTimeout(() => {
        inputRef?.current?.click();
      }, 750);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Row className="justify-content-center bg-white">
            <Col lg={5} sm={8} xs={12}>
              <div>
                <Loader />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <div className="chat-page">
          <Container className="py-4">
            <Row className="justify-content-center">
              <Col lg="8">
                <Card className="border-0 chat-card">
                  <Card.Header className="primary-bg-color text-black ff-playfair fw-600 d-flex align-items-center justify-content-between gap-2 astro-header">
                    <div className="d-flex gap-1 align-items-center">
                      <div className="img-div">
                        <Image src={stateData?.imageFullUrl} />
                      </div>
                      <div className="text-capitalize">
                        <div className="d-flex flex-column">
                          <label>{stateData?.astro_name}</label>
                          <label className="fs-14 ff-manrope">
                            {chatCounting?.join(":")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center h-100">
                      <div className="p-1 px-2 border-danger rounded-5 bg-danger cursor">
                        <FontAwesomeIcon
                          onClick={() => endChat()}
                          icon={faPhone}
                          style={{
                            transform: "rotate(135deg)",
                          }}
                          color="#ffffff"
                        />
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column gap-1 p-0">
                    <div
                      className="messages d-flex flex-column gap-1 p-2"
                      ref={containerRef}
                    >
                      {currentScreen == "calling" && (
                        <div className="border h-100 rounded-1 d-flex align-items-center justify-content-center">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div>
                              <Button
                                className="border-0 rounded-5 bg-danger cursor "
                                style={{ width: "40px", height: "40px" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  style={{ transform: "rotate(135deg)" }}
                                  color="#ffffff"
                                />
                              </Button>
                            </div>
                            <div>
                              <span className="fs-17 fw-600 ff-manrope text-grey-700">
                                Please wait...
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentScreen == "astroReject" && (
                        <div className="border h-100 rounded-1 d-flex align-items-center justify-content-center">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div className="text-capitalize ff-manrope">
                              {stateData?.astro_name} rejected your chat request
                            </div>
                            <div>
                              <Button
                                className="border-0 rounded-5 px-4 py-2 primary-bg-color text-dark fw-600"
                                onClick={() => closeChat()}
                              >
                                Ok
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentScreen == "astroWaitList" && (
                        <div className="border h-100 rounded-1 d-flex align-items-center justify-content-center">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div className="text-capitalize ff-manrope">
                              You have been added to waitlist
                            </div>
                            <div>
                              <Button
                                className="border-0 rounded-5 px-4 py-2 primary-bg-color text-dark fw-600"
                                onClick={() => closeChat()}
                              >
                                Ok
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentScreen == "endAstro" && (
                        <div className="border h-100 rounded-1 d-flex align-items-center justify-content-center">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div className="text-capitalize ff-manrope">
                              {stateData?.astro_name} ended the chat
                            </div>
                            <div>
                              <Button
                                className="border-0 rounded-5 px-4 py-2 primary-bg-color text-dark fw-600"
                                onClick={() => closeChat()}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentScreen == "endUser" && (
                        <div className="border h-100 rounded-1 d-flex align-items-center justify-content-center">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div className="text-capitalize ff-manrope">
                              You ended the chat
                            </div>
                            <div>
                              <Button
                                className="border-0 rounded-5 px-4 py-2 primary-bg-color text-dark fw-600"
                                onClick={() => closeChat()}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentScreen == "active" && <ChatBox />}
                    </div>
                    <div className="send-message p-2 border-top d-flex flex-column">
                      {parseInt(chatCounting[2]) > 0 && (
                        <div className="d-flex w-100 align-items-center">
                          <div className="d-flex justify-content-center position-relative me-2">
                            <div
                              className="rounded-1 primary-bg-color py-1 px-2 cursor h-100"
                              onClick={() => setMediaShow(!mediaShow)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                            <div
                              className={`media position-absolute ${
                                !mediaShow ? "d-none" : ""
                              }`}
                            >
                              <ul>
                                <li onClick={() => openMedialUploader("img")}>
                                  <FontAwesomeIcon icon={faImage} />
                                </li>
                                <li onClick={() => openMedialUploader("doc")}>
                                  <FontAwesomeIcon icon={faFile} />
                                </li>
                                <li onClick={startRecording}>
                                  <FontAwesomeIcon icon={faMicrophone} />
                                </li>
                              </ul>
                            </div>
                            <input
                              type="file"
                              ref={inputRef}
                              className="d-none"
                              accept={mediaType[currMediaType]}
                              onChange={(e) =>
                                setMedia(e?.target?.files[0], currMediaType)
                              }
                            />
                          </div>
                          <div className="input w-100">
                            <textarea
                              className="w-100 ff-manrope fs-16 border mt-1"
                              name="message"
                              value={
                                senderData?.m_type == "T"
                                  ? senderData?.message
                                  : ""
                              }
                              placeholder="type here"
                              onChange={(e) => {
                                setSenderData({
                                  ...senderData,
                                  m_type: "T",
                                  message: e.target.value,
                                });
                              }}
                              onKeyDown={handleKeyPress}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* Modal for audio record start */}

          <Modal
            // show={currMediaType == 'aud'}
            show={currMediaType == "aud"}
            onHide={() => setCurrMediaType("")}
            onEscapeKeyDown={() => setCurrMediaType("")}
            backdrop="static"
            keyboard={false}
            centered
            className="modal-sm"
          >
            <ModalBody>
              <div className="d-flex flex-column p-1">
                <div class="audio-box mb-2 border p-3">
                  <div class="audio-circle_ripple-2"></div>
                  <div class="audio-circle">
                    <div class="audio-circle-2">
                      <FontAwesomeIcon icon={faMicrophone} />
                    </div>
                  </div>
                </div>
                <div className="fs-16">{audioTime?.join(":")}</div>
                <div className="my-2 d-flex justify-content-center">
                  <Button
                    className="border-0 bg-primary-600 text-black"
                    onClick={stopRecording}
                  >
                    Send
                  </Button>
                </div>

                <div className="d-none">
                  <div className="audio-controls d-none">
                    {permission && recordingStatus === "inactive" ? (
                      <button onClick={startRecording} type="button">
                        Start Recording
                      </button>
                    ) : null}
                    {recordingStatus === "recording" ? (
                      <button onClick={stopRecording} type="button">
                        Stop Recording
                      </button>
                    ) : null}
                  </div>
                  {audio ? (
                    <div className="audio-container">
                      <audio src={audio} controls></audio>
                      <a download href={audio}>
                        Download Recording
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* Modal for audio record close */}
          <ReviewModal
            astro_name={stateData?.astro_name}
            name={stateData?.name}
            ch_id={stateData?.ch_id}
            imageFullUrl={stateData?.imageFullUrl}
            modalStatus={reviewModal}
            onModalClosed={closeChat}
          />
          <Modal
            show={audioPlayModal}
            onHide={() => setAudioPlayModal(false)}
            onEscapeKeyDown={() => setAudioPlayModal(false)}
            keyboard={false}
            centered
            className="modal-sm"
          >
            <ModalBody>
              <Row>
                <Col lg="12">
                  <div className="d-flex justify-content-center align-items-center">
                    <AudioPlayer
                      autoPlay
                      src={audioPlay}
                      onPlay={(e) => console.log("onPlay")}
                    />
                  </div>
                </Col>
              </Row>
              <Button
                className="bg-danger text-white position-absolute rounded-5 border-0"
                style={{ top: "-20px", padding: "2.5px 8px", right: "-10px" }}
                onClick={() => setAudioPlayModal(false)}
              >
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Chat;
