import { createSlice } from "@reduxjs/toolkit";
import DashboardActions from "../../Actions/Dashboard/DashboardActions";

const DashboardSlice = createSlice({
    name:'Dashboard',
    initialState:{
        dashboardData:null,
        loading:false
    },
    extraReducers: (builder) => {        
        builder.addCase(DashboardActions.pending,(state, action) => {
            state.loading = true
        }),
        builder.addCase(DashboardActions.fulfilled,(state, action) => {
            state.dashboardData = action.payload
            state.loading = false
        }),
        builder.addCase(DashboardActions.rejected,(state, action) => {
            state.loading = false
        })
    }
})

export default DashboardSlice;