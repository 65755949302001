import React, { useEffect, useMemo, useRef, useState } from "react";
import { MeetingProvider, useMeeting, useParticipant, } from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash, faPhone, faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Constant, { storage, userInfo } from "../../config/Constant";
import moment from "moment";
import { apiCall } from "../../Components/Helper/ApiHelper";
import ReviewModal from "./ReviewModal";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { communicateModule } from "../../Redux/Slices/Communicate/CommunicateModuleSlice";

const ParticipantView = (props) => {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal } = useParticipant(props.participantId);
    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);
                micRef.current.srcObject = mediaStream;
                micRef.current
                .play()
                .catch((error) => console.error("videoElem.current.play() failed", error));
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    return (
        <div className={props?.className}>
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {webcamOn ? (
                <ReactPlayer
                    playsinline
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    url={videoStream}
                    onError={(err) => console.log(err, "participant video error")}
                />
            ) : (
                <div className="col-lg-12"></div>
            )}
        </div>
    );
}

const MeetingView = (props) => {

    const { join } = useMeeting({
        //callback for when meeting is joined successfully
        onMeetingJoined: () => {},
        //callback for when meeting is left
        onMeetingLeft:() => {},
        
        onParticipantJoined:(e) => {
            props.onStatusChange({state:'JOINED'})
        },
        onParticipantLeft:(e) => {
            console.log('onParticipantLeft left :: ', e)
            props.onStatusChange(e)
        },
        onMeetingStateChanged:(e) => {
            console.log('onMeetingStateChanged left :: ', e)
            props.onStatusChange(e)
        },
        onMainParticipantChanged:(e) => {}
    });

    useEffect(() => { 
        setTimeout(() => {
            join()
        }, 150)
     }, [])

    return <></>;
}

const Controls = (props) => {

    const { end, toggleMic, toggleWebcam } = useMeeting();

    const [micStatus, setMicStatus] = useState(false)
    const [videoStatus, setVideoStatus] = useState(false)

    const toggleMicrophone = () => {
        toggleMic();
        setMicStatus(!micStatus)
    };

    const toggleWebCamera = () => {
        toggleWebcam();
        setVideoStatus(!videoStatus)
    };

    const endCall = () => {
        const body = {
            sender:'U',
            ch_id:props?.ch_id,
            reason:(props?.lowBalance && props?.chatType == 'PAID') ? 'Call was ended automatically due to low wallet balance' : 'User ended the call',
            seconds:moment().diff(localStorage?.getItem(storage?.callStart), 'seconds') + 1
        }

        apiCall({url:Constant?.apis?.callEnd, body})?.then((res) => {
            if (res?.status) {
                userInfo.amount = res?.wallet?.toFixed(2);
                localStorage.setItem(storage?.login, JSON.stringify(userInfo))
                end()
            }
        })
    }

    useEffect(() => {
        if (props?.lowBalance) {
            if (props.chatType == 'FREE' && userInfo?.free == '1') {
                endCall()
            }
            if (props.chatType != 'FREE') {
                endCall()
            }
        }
    }, [props.lowBalance])

    return (
        <>
            <div className="d-flex justify-content-center gap-4 align-items-center px-5 bg-dark">
                <div
                    className="icon"
                    onClick={toggleMicrophone}
                >
                    <FontAwesomeIcon size="lg" icon={micStatus ? faMicrophone : faMicrophoneSlash}/>
                </div>
                <div
                    className="icon"
                    onClick={toggleWebCamera}
                >
                    <FontAwesomeIcon size="lg" icon={videoStatus ? faVideo : faVideoSlash}/>
                </div>
                <div
                    className="icon bg-danger text-white"
                    onClick={endCall}
                >
                    <FontAwesomeIcon size="lg" icon={faPhone} style={{
                        transform: 'rotate(135deg)'
                    }}/>
                </div>
            </div>
        </>
    );
}

const VideoCall = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    // const stateData = location?.state
    const stateData = localStorage.getItem(storage?.communicate) ? JSON.parse(localStorage.getItem(storage?.communicate)) : (location?.state?.id > 0 ? location?.state : {})
    console.log('stateData :: ', stateData)
    localStorage.setItem(storage?.communicate, JSON.stringify(stateData))
    const [callStatus, setCallStatus] = useState(localStorage?.getItem(storage?.callStatus)?? 'CONNECTING')
    const [meetingId, setMeetingId] = useState(stateData?.meeting_id);
    const [config, setConfig] = useState({
        meetingId,
        micEnabled: true,
        
        webcamEnabled: false,
        name: userInfo.name,
        maxResolution:'hd',
        defaultCamera:'front',
        autoConsume:true,
        multiStream:false,
        participantId:'U'  
    })

    const [reviewModal, setReviewModal] = useState(false)

    //This will set Meeting Id to null when meeting is left or ended
    const onMeetingLeave = () => setMeetingId(null)

    // TIME COUNT SECTION START -----
    const [seconds, setSeconds] = useState(0)
    const [chatCounting, setChatCounting] = useState(['00', '00', '00'])
    
    // Function for count seconds start -----
    const timerBox = () => {
        if(localStorage?.getItem(storage?.callStart)) {
            var a = moment();
            var b = localStorage?.getItem(storage?.callStart)
            const totalSeconds = a.diff(b, 'seconds')
            
            setSeconds(totalSeconds)
            const totalMinutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            chatCounting[0] = hours > 9 ? hours : `0${hours}`;
            chatCounting[1] = minutes > 9 ? minutes : `0${minutes}`;
            chatCounting[2] = seconds > 9 ? seconds : `0${seconds}`;

            setChatCounting([...chatCounting])
            setTimeout(() => { timerBox() }, 1000)
        }
    }
    // Function for count seconds close -----

    // TIME COUNT SECTION CLOSE -----

    const closeAndRedirectToHistory = () => {
        delete location.state
        navigate('/history', {
            state:{
                tab:'Call'
            }
        })
    }

    const saveWaitList = () => {
        const body = {
            sender:'U',
            ch_id:stateData?.ch_id,
            reason:'Astrologer missed the call and it is shifted to WAITLIST'
        }
        apiCall({url:Constant?.apis?.waitList, body})?.then((res) => {
            if (res?.status) {
                dispatch(communicateModule())
                closeAndRedirectToHistory()
            }
        })
    }

    const callTimerFunc = () => {
        console.log('!localStorage?.getItem(storage?.callStatus) :: ', !localStorage?.getItem(storage?.callStatus))
        if (!localStorage?.getItem(storage?.callStatus)) {
            var a = moment();
            var b = localStorage?.getItem(storage?.callStart)
            const totalSeconds = a?.diff(b, 'seconds')
            console.log('totalSeconds > 59 :: ', totalSeconds > 59, totalSeconds, 59)
            if (totalSeconds > 59) {
                saveWaitList()
            } else {
                setTimeout(() => {
                    callTimerFunc()
                }, 1000)
            }
        }
    }

    useEffect(() => {
        console.log(' stateData?.token === undefined || stateData?.token?.length <= 0 :: ', stateData?.token === undefined || stateData?.token?.length <= 0)
        if (stateData?.token === undefined || stateData?.token?.length <= 0) {
            toast.warn('Login Required')
            navigate('/home')
            return false;
        }
        callTimerFunc()
    }, [])

    useEffect(() => {
        if (seconds == (stateData?.availableSecond - 25) && stateData?.chat_type == 'PAID') {
            toast.warn('Lower Balance')
        }

        if (seconds >= stateData?.availableSecond) {
        }
    }, [seconds])

    const OpenModal = (sec = seconds) =>  setReviewModal(true)

    const updateCallingProcess = (e) => {
        console.log('e?.state :: ', e?.state)
        if (e?.state == 'JOINED') {
            setCallStatus(e?.state)
            console.log('localStorage?.getItem(storage?.callStart) :: ', localStorage?.getItem(storage?.callStart), location?.state?.callStart)
            const startTime = localStorage?.getItem(storage?.callStart)?? (location?.state?.callStart?? moment()?.format('YYYY-MM-DD HH:mm:ss'));
            console.log('localStorage?.getItem(storage?.callStart) :: ', localStorage?.getItem(storage?.callStart))
            localStorage?.setItem(storage?.callStart, startTime)
            localStorage?.setItem(storage?.callStatus, 'JOINED')
            timerBox()
        }
        if (e?.state == 'CLOSED') {
            dispatch(communicateModule())
            if (localStorage?.getItem('fromContinue') != 1) {
                OpenModal(seconds)
            }
            localStorage?.setItem('fromContinue', 0)
        }
    }

    return (
        <>
            <MeetingProvider
                config={config}
                token={stateData?.token}
            >
                <div className="call-page p-2">
                    <Container>
                        <Row>
                            <Col lg='12'>
                                <MeetingView meetingId={meetingId} onStatusChange={(e)=> updateCallingProcess(e)} onMeetingLeave={onMeetingLeave} />
                                <div className="w-100 d-flex justify-content-center">
                                    <Card className="call-card position-relative align-items-center w-100">
                                    {
                                        ['CONNECTING', 'CONNECTED']?.includes(callStatus) && (
                                            <>
                                                <div className="connecting h-100 d-flex w-100 justify-content-center align-items-center">
                                                    <div className="text-capitalize d-flex flex-column align-items-center gap-3 text-white">
                                                        <h4 className="mb-0">calling...</h4>
                                                        <h3 className="mb-0">{stateData?.astro_name}</h3>
                                                        <Button className="btn-danger">
                                                            <FontAwesomeIcon
                                                                icon={faPhone}
                                                                style={{
                                                                    transform: 'rotate(135deg)'
                                                                }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        ['CLOSED', 'FAILED', 'DISCONNECT']?.includes(callStatus) && (
                                            <>
                                                <div className="closed">
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        ['JOINED']?.includes(callStatus) && (
                                            <>
                                                <div className="timer position-absolute">
                                                    <span>{chatCounting?.join(':')}</span>
                                                </div>
                                                <div className="video-section position-absolute w-100 h-100">
                                                    <div className="position-relative w-100 h-100">
                                                        <div className="astro w-100">
                                                            <ParticipantView
                                                                participantId={'A'}
                                                                key={'A'}
                                                                className={'astro-screen'}
                                                            />
                                                        </div>
                                                        <div className="user position-absolute rounded-2">
                                                            <ParticipantView
                                                                participantId={'U'}
                                                                key={'U'}
                                                                className={'user-screen'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-menu position-absolute w-100 d-flex justify-content-center align-items-center">
                                                    <Controls chatType={location?.state?.chat_type} lowBalance={seconds >= stateData?.availableSecond} seconds={seconds} ch_id={stateData?.ch_id} />
                                                </div>
                                            </>
                                        )
                                    }
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </MeetingProvider>
            <ReviewModal
                astro_name={stateData?.astro_name}
                name={stateData?.name}
                ch_id={stateData?.ch_id}
                imageFullUrl={stateData?.imageFullUrl}
                modalStatus={reviewModal}
                onModalClosed={closeAndRedirectToHistory}
                className='mt-4'
            />
        </>
    )
}


export default VideoCall;