import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Form, Image, Modal } from "react-bootstrap"
import { Rating } from "react-simple-star-rating"
import LangTrans from "../../Components/Helper/LangHelper"
import { useEffect, useState } from "react"
import { faUserAlt } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import { apiCall } from "../../Components/Helper/ApiHelper"
import Constant from "../../config/Constant"


const ReviewModal = ({modalStatus = false, ch_id = '', astro_name = '', name = '', imageFullUrl = '', onModalClosed, className = ''}) => {

    const [reviewModalVisible, setReviewModalVisible] = useState(false)
    const [reviewData, setReviewData] = useState({
        anonymous: 0,
        ch_id: ch_id,
        rating: '',
        review: ''
    })

    const closeReviewModal = () => {
        setReviewModalVisible(false)
        onModalClosed()
    }

    // function for save review start -----
    const saveReviewData = () => {
        if (reviewData?.review?.length <= 0) {
            toast.warn('Review must be required')
            return
        }

        apiCall({ url: Constant?.apis?.saveRating, body: reviewData })?.then((res) => {
            if (res?.status) {
                toast.success(res?.message)
                closeReviewModal()
            }
        })
    }
    // function for save review close -----

    useEffect(() => {
        setReviewModalVisible(modalStatus)
    }, [modalStatus])

    return (
        <>
            <Modal
                show={reviewModalVisible}
                onHide={closeReviewModal}
                onEscapeKeyDown={closeReviewModal}
                backdrop="static"
                keyboard={false}
                centered
                className={`modal-md ${className}`}
            >
                <Modal.Header closeButton className="primary-bg-color">
                    <Modal.Title className="w-100 text-center">
                        <LangTrans val='Astro Review' />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-1 ff-manrope py-2">
                        <div className="text-center">
                            <h6 className="fw-500">{astro_name}</h6>
                        </div>
                        <div className="text-center">
                            <Image src={imageFullUrl} className="rounded-5" width={55} height={55} />
                        </div>
                        <div className="d-flex gap-2 mt-3 align-items-center">
                            <div>
                                <FontAwesomeIcon icon={faUserAlt} className="border rounded-5 p-2" style={{ borderColor: 'black' }} />
                            </div>
                            <div className="d-flex flex-column">
                                <small className="fs-16 fw-600 text-grey-500 text-capitalize">{name}</small>
                                <small className="fs-13">Reviews are public if you are not anonymous</small>
                            </div>
                        </div>
                        <div className="d-flex gap-2 mt-3 justify-content-center">
                            <Form.Check
                                name="isHide"
                                type="checkbox"
                                checked={reviewData?.anonymous == 1}
                                onChange={(e) => {
                                    reviewData.anonymous = reviewData?.anonymous == 1 ? 0 : 1
                                    setReviewData({ ...reviewData })
                                }}
                            />
                            <small>Hide my name from all public reviews</small>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <Rating
                                onClick={(e) => {
                                    reviewData.review = e?.target?.value
                                    setReviewData({ ...reviewData })
                                }}
                            />
                        </div>
                        <div className="mt-2">
                            <textarea
                                rows={5}
                                className="form-control rounded-4"
                                value={reviewData?.review}
                                onChange={(e) => {
                                    reviewData.review = e?.target?.value
                                    setReviewData({ ...reviewData })
                                }}
                                maxLength={300}
                            />
                        </div>
                        <div className="mt-2 text-center">
                            <Button
                                className="border-0 rounded-5 w-75 primary-bg-color text-black fs-18 text-capitalize ff-manrope"
                                onClick={saveReviewData}
                            >
                                <LangTrans val='submit' />
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReviewModal;